/* eslint-disable string-to-lingui/missing-lingui-transformation */
import Box from '@mui/material/Box/Box'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { PaginateMeta } from '@om1/falcon-api/codegen/client/models/PaginateMeta'
import { ExplorerAnalyticsRefService } from '@om1/falcon-api/codegen/client/services/ExplorerAnalyticsRefService'
import { FC, useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useFetchRefs } from '../filters/useFetchRefs'
import { fetchData } from '../queryUtils'

export const PatientMedicationDialogContents: FC<{ editBlock: FilterDTO_Output | DateAwareFilterDTO_Output }> = ({ editBlock }) => {
    const { refs, fetchRefs } = useFetchRefs()
    const [refData, setRefData] = useState<Array<Record<string, any>>>([])
    const [refResults, setRefResults] = useState<Array<Record<string, any>>>([])

    useEffect(() => {
        fetchRefs(editBlock)
    }, [editBlock, fetchRefs])

    useEffect(() => {
        setRefData(refs)
    }, [refs])

    const [selectedCodingSystem, setSelectedCodingSystem] = useState<'OM1' | 'MEDS' | 'NDC'>('OM1')
    const [query, setQuery] = useState('')
    const [refMeta, setRefMeta] = useState<PaginateMeta>({
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        itemsPerPage: 10
    })
    const [pendingBlockValues, setPendingBlockValues] = useState<Array<string>>(editBlock.values)
    const initialBlockValues = editBlock.values
    const isInitialBlockValues = JSON.stringify(pendingBlockValues) === JSON.stringify(initialBlockValues)

    useEffect(() => {
        if (editBlock.field) {
            if (editBlock.field === 'boc_cui') {
                setSelectedCodingSystem('OM1')
            } else if (editBlock.field === 'level_2_id') {
                setSelectedCodingSystem('MEDS')
            } else if (editBlock.field === 'ndc_code') {
                setSelectedCodingSystem('NDC')
            }
        }
    }, [pendingBlockValues, editBlock])

    useEffect(() => {
        const fetchDataAsync = async () => {
            const data = await fetchData(
                'explorerCohorts',
                {
                    refTableName:
                        selectedCodingSystem === 'OM1'
                            ? 'ref_diagnosis_boc_cui'
                            : selectedCodingSystem === 'MEDS'
                            ? 'ref_medication'
                            : 'ref_medication_ndc',
                    page: 1,
                    limit: 100,
                    query: query,
                    filters: []
                },
                ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet
            )
            setRefData((prevData) => [...prevData, ...data.data])
            setRefResults(data.data)
            setRefMeta(data.meta)
        }

        if (query) {
            fetchDataAsync()
        } else {
            setRefResults([])
            setRefMeta({
                currentPage: 1,
                totalPages: 0,
                totalItems: 0,
                itemsPerPage: 10
            })
        }
    }, [query, selectedCodingSystem])

    const fetchMoreData = useCallback(async () => {
        const data = await fetchData(
            'explorerCohorts',
            {
                refTableName:
                    selectedCodingSystem === 'OM1'
                        ? 'ref_diagnosis_boc_cui'
                        : selectedCodingSystem === 'MEDS'
                        ? 'ref_medication'
                        : 'ref_medication_ndc',
                page: refMeta?.currentPage + 1,
                limit: 100,
                query: query,
                filters: []
            },
            ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet
        )

        setRefData((prevData) => [...prevData, ...data.data])
        setRefResults(data.data)
        setRefMeta(data.meta)
    }, [refMeta?.currentPage, query, selectedCodingSystem])

    const hasMoreData = refMeta?.currentPage && refMeta?.totalPages ? refMeta?.currentPage < refMeta?.totalPages : false

    function handleSelect(diagnosis_concept_id: string): void {
        setPendingBlockValues([diagnosis_concept_id].concat(pendingBlockValues))
    }

    function handleRemove(value: string): void {
        setPendingBlockValues(pendingBlockValues.filter((v) => v !== value))
    }

    return (
        <div style={{ padding: '20px', position: 'relative' }}>
            <input
                type='text'
                placeholder={
                    selectedCodingSystem === 'OM1'
                        ? 'Search OM1 Coding System'
                        : selectedCodingSystem === 'MEDS'
                        ? 'Search Medication Classification'
                        : 'Search National Drug Code Directory'
                }
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                style={{ width: '100%', padding: '10px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: '5px' }}
            />
            <div style={{ display: 'flex', marginBottom: '20px', width: '100dvw' }}>
                <label style={{ marginRight: '20px' }}>
                    <input
                        type='radio'
                        name='codingSystem'
                        value='OM1'
                        checked={selectedCodingSystem === 'OM1'}
                        onChange={() => setSelectedCodingSystem('OM1')}
                        style={{ marginRight: '10px' }}
                        disabled={!!(query || (!query && pendingBlockValues.length > 0)) && selectedCodingSystem !== 'OM1'}
                    />
                    Search OM1 Coding System
                </label>
                <label style={{ marginRight: '20px' }}>
                    <input
                        type='radio'
                        name='codingSystem'
                        value='MEDS'
                        checked={selectedCodingSystem === 'MEDS'}
                        onChange={() => setSelectedCodingSystem('MEDS')}
                        style={{ marginRight: '10px' }}
                        disabled={!!(query || (!query && pendingBlockValues.length > 0)) && selectedCodingSystem !== 'MEDS'}
                    />
                    Search Medication Classification
                </label>
                <label>
                    <input
                        type='radio'
                        name='codingSystem'
                        value='NDC'
                        checked={selectedCodingSystem === 'NDC'}
                        onChange={() => setSelectedCodingSystem('NDC')}
                        style={{ marginRight: '10px' }}
                        disabled={!!(query || (!query && pendingBlockValues.length > 0)) && selectedCodingSystem !== 'NDC'}
                    />
                    Search National Drug Code Directory
                </label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '60px' }}>
                <div
                    style={{
                        flex: 1,
                        marginRight: '10px',
                        border: '1px solid #ccc',
                        padding: '10px',
                        borderRadius: '5px',
                        minHeight: '350px',
                        overflow: 'hidden'
                    }}
                >
                    <strong>Search Results</strong>
                    <div
                        style={{
                            flex: 1,
                            minHeight: '350px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {refResults.length === 0 && (
                            <div style={{ marginTop: '10px', color: '#aaa' }}>
                                {selectedCodingSystem === 'OM1'
                                    ? 'Search to browse OM1 codes'
                                    : selectedCodingSystem === 'MEDS'
                                    ? 'Search to browse medications'
                                    : 'Search to browse National Drug Codes'}
                            </div>
                        )}
                        <Box width='100%' height='100%'>
                            <InfiniteScroll
                                dataLength={refResults.length}
                                next={() => {
                                    fetchMoreData()
                                }}
                                hasMore={hasMoreData}
                                loader={
                                    <div className='flex justify-center items-center py-4'>
                                        <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-primary'></div>
                                    </div>
                                }
                                className='w-full overflow-y-scroll pr-3 mr-[-12px] space-y-3 max-h-[350px]'
                            >
                                {refResults.map((item, index) => (
                                    <div
                                        key={`${item.diagnosis_concept_id}-${index}`}
                                        onClick={() => {
                                            if (
                                                !pendingBlockValues.includes(
                                                    selectedCodingSystem === 'OM1'
                                                        ? item.boc_cui
                                                        : selectedCodingSystem === 'MEDS'
                                                        ? item.level_2_id
                                                        : item.ndc_code
                                                )
                                            ) {
                                                handleSelect(
                                                    selectedCodingSystem === 'OM1'
                                                        ? item.boc_cui
                                                        : selectedCodingSystem === 'MEDS'
                                                        ? item.level_2_id
                                                        : item.ndc_code
                                                )
                                            }
                                        }}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '10px',
                                            width: '100%',
                                            borderBottom: '1px solid #ccc',
                                            padding: '10px',
                                            height: '100%'
                                        }}
                                    >
                                        <input
                                            type='checkbox'
                                            style={{ marginRight: '10px' }}
                                            onClick={() => {
                                                if (
                                                    !pendingBlockValues.includes(
                                                        selectedCodingSystem === 'OM1'
                                                            ? item.boc_cui
                                                            : selectedCodingSystem === 'MEDS'
                                                            ? item.level_2_id
                                                            : item.ndc_code
                                                    )
                                                ) {
                                                    handleSelect(
                                                        selectedCodingSystem === 'OM1'
                                                            ? item.boc_cui
                                                            : selectedCodingSystem === 'MEDS'
                                                            ? item.level_2_id
                                                            : item.ndc_code
                                                    )
                                                }
                                            }}
                                            disabled={pendingBlockValues.includes(
                                                selectedCodingSystem === 'OM1'
                                                    ? item.boc_cui
                                                    : selectedCodingSystem === 'MEDS'
                                                    ? item.level_2_id
                                                    : item.ndc_code
                                            )}
                                            checked={pendingBlockValues.includes(
                                                selectedCodingSystem === 'OM1'
                                                    ? item.boc_cui
                                                    : selectedCodingSystem === 'MEDS'
                                                    ? item.level_2_id
                                                    : item.ndc_code
                                            )}
                                            onChange={() => {
                                                if (
                                                    !pendingBlockValues.includes(
                                                        selectedCodingSystem === 'OM1'
                                                            ? item.boc_cui
                                                            : selectedCodingSystem === 'MEDS'
                                                            ? item.level_2_id
                                                            : item.ndc_code
                                                    )
                                                ) {
                                                    handleSelect(
                                                        selectedCodingSystem === 'OM1'
                                                            ? item.boc_cui
                                                            : selectedCodingSystem === 'MEDS'
                                                            ? item.level_2_id
                                                            : item.ndc_code
                                                    )
                                                }
                                            }}
                                        />
                                        <span
                                            style={{
                                                textAlign: 'left',
                                                textTransform: 'none',
                                                flex: 1,
                                                paddingBottom: '10px'
                                            }}
                                        >
                                            {selectedCodingSystem === 'OM1'
                                                ? item.boc_display_name
                                                : selectedCodingSystem === 'MEDS'
                                                ? item.medication
                                                : item.ndc_name}
                                        </span>
                                    </div>
                                ))}
                            </InfiniteScroll>
                        </Box>
                    </div>
                </div>
                <div style={{ flex: 1, marginLeft: '10px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px', minHeight: '150px' }}>
                    <strong>
                        Selected{' '}
                        <button
                            className='text-xs'
                            style={{
                                color: '#007bff',
                                textDecoration: 'none',
                                cursor: 'pointer',
                                marginLeft: '5px',
                                background: 'none',
                                border: 'none',
                                padding: '0'
                            }}
                            onClick={() => setPendingBlockValues([])}
                        >
                            Remove all
                        </button>
                    </strong>
                    <div
                        style={{
                            marginTop: '10px',
                            paddingLeft: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            maxHeight: '350px',
                            overflow: 'scroll'
                        }}
                    >
                        {pendingBlockValues.map((value, index) => (
                            <div
                                key={`${value}-${index}`}
                                style={{
                                    padding: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    borderBottom: '1px solid #ccc',
                                    marginTop: index === 0 ? '0px' : '10px'
                                }}
                            >
                                <span style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => handleRemove(value)}>
                                    🗑️
                                </span>
                                <span>
                                    {selectedCodingSystem === 'OM1'
                                        ? refData.find((item) => item.boc_cui === value)?.boc_display_name
                                        : selectedCodingSystem === 'MEDS'
                                        ? refData.find((item) => item.level_2_id === value)?.medication
                                        : refData.find((item) => item.ndc_code === value)?.ndc_name}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <button
                style={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: isInitialBlockValues ? '#ccc' : '#004085',
                    color: '#fff',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px'
                }}
                disabled={isInitialBlockValues}
            >
                + Save Criteria
            </button>
        </div>
    )
}
