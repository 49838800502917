/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { CountDistinctQualifierDTO_Output, DateAwareFilterDTO_Output, DateQualifierDTO_Output, FilterDTO_Output, RefTable } from '@om1/falcon-api'
import { FC, SetStateAction } from 'react'
import { QualifierContent } from '../nodes/QualifierContent'
import { Qualifier } from '../qualifiers/Qualifier'
import { NodeTypes } from '../QueryBlock'
import { FilterPropsWithRefs } from './GenericDateAwareFilter'
import { ListWithMore } from './ListWithMore'

export const processSimpleFilterValues = (filter: FilterDTO_Output | DateAwareFilterDTO_Output, refs?: Array<Record<string, any>>) => {
    const rawValues = Array.isArray(filter.values) ? filter.values : [filter.values]
    const convertedValues: string[] = []

    for (const value of rawValues) {
        if (refs && refs.length > 0) {
            switch (filter.table) {
                case 'patient_diagnosis': {
                    const patient_diagnosis_boc_cui_ref = refs.find((ref) => ref.boc_cui === value)
                    const patient_diagnosis_diagnosis_concept_id_ref = refs.find((ref) => ref.diagnosis_concept_id === value)
                    if (patient_diagnosis_boc_cui_ref) {
                        convertedValues.push(patient_diagnosis_boc_cui_ref.boc_display_name)
                    } else if (patient_diagnosis_diagnosis_concept_id_ref) {
                        convertedValues.push(patient_diagnosis_diagnosis_concept_id_ref.diagnosis)
                    } else {
                        convertedValues.push(value)
                    }
                    break
                }
                case 'patient_lab': {
                    const patient_lab_ref = refs.find((ref) => ref.lab_concept_id === value)
                    if (patient_lab_ref) {
                        convertedValues.push(patient_lab_ref.lab)
                    } else {
                        convertedValues.push(value)
                    }
                    break
                }
                case 'patient_medication': {
                    const patient_medication_boc_cui_ref = refs.find((ref) => ref.boc_cui === value)
                    const patient_medication_ref = refs.find((ref) => ref.id === value)
                    const patient_medication_medication_ndc_code_ref = refs.find((ref) => ref.ndc_code === value)
                    if (patient_medication_boc_cui_ref) {
                        convertedValues.push(patient_medication_boc_cui_ref.boc_name)
                    } else if (patient_medication_ref) {
                        convertedValues.push(patient_medication_ref.medication)
                    } else if (patient_medication_medication_ndc_code_ref) {
                        convertedValues.push(patient_medication_medication_ndc_code_ref.ndc_name)
                    } else {
                        convertedValues.push(value)
                    }
                    break
                }
                case 'patient_procedure': {
                    const patient_procedure_ref = refs.find((ref) => ref.procedure_concept_id === value)
                    if (patient_procedure_ref) {
                        convertedValues.push(patient_procedure_ref.procedure)
                    } else {
                        convertedValues.push(value)
                    }
                    break
                }
                default: {
                    const ref = refs.find((ref) => ref.boc_cui === value)
                    if (ref) {
                        convertedValues.push(ref.boc_display_name)
                    } else {
                        convertedValues.push(value)
                    }
                }
            }
        } else {
            convertedValues.push(value)
        }
    }

    return convertedValues
}

export type SimpleFilterDetailsProps = {
    filterDTO: FilterDTO_Output | DateAwareFilterDTO_Output
    depth: number
    onBlockClick?: (blockId: number, qualifierIndex?: number) => void
    refs?: Array<Record<string, any>>
    valueOverwrite?: (value: string) => string
    disableClick?: boolean
    isSubject?: boolean
    isReference?: boolean
    setEditBlock?: (
        value: SetStateAction<{
            filter: NodeTypes
            index: number | undefined
        } | null>
    ) => void
}

export const SimpleFilterDetails: FC<FilterPropsWithRefs> = (props) => {
    const { filterDTO, refs, valueOverwrite, isReference } = props
    const convertedValues = processSimpleFilterValues(filterDTO, refs)
    const overwrittenValues = valueOverwrite ? convertedValues.map(valueOverwrite) : convertedValues
    const hasCountDistinctQualifier =
        filterDTO.qualifiers && (filterDTO.qualifiers as unknown as any[]).some((qualifier: any) => qualifier.type === 'CountDistinctQualifierDTO')
    return (
        <div style={{ marginBottom: '0.5rem', fontSize: '0.875rem' }}>
            <div style={{ color: '#4B5563' }}>
                <ListWithMore items={overwrittenValues} itemCount={overwrittenValues.length} />
                <>
                    <div style={{ gridColumn: 'span 2 / span 2' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                            {!hasCountDistinctQualifier && !(filterDTO.table === ('observation_period' as RefTable)) && !isReference && (
                                <Qualifier
                                    {...props}
                                    key={`${filterDTO.blockId}-intrinsic-distinct`}
                                    qualifier={
                                        {
                                            type: 'CountDistinctQualifierDTO',
                                            field: filterDTO.field,
                                            value: 1,
                                            operator: 'in',
                                            requiresRecordLevelCube: false,
                                            measureType: 'count_distinct'
                                        } as CountDistinctQualifierDTO_Output
                                    }
                                    depth={props.depth + 1}
                                    parent={filterDTO}
                                    qualifierIndex={-1}
                                />
                            )}
                            {!hasCountDistinctQualifier && !(filterDTO.table === ('observation_period' as RefTable)) && isReference && (
                                <QualifierContent
                                    {...props}
                                    parent={filterDTO}
                                    onBlockClick={() => {
                                        props.onBlockClick && filterDTO?.blockId && props.onBlockClick(filterDTO?.blockId, -1)
                                    }}
                                    qualifierIndex={-1}
                                    qualifier={
                                        {
                                            type: 'CountDistinctQualifierDTO',
                                            qualifiers: null,
                                            measureType: 'count_distinct',
                                            field: filterDTO.field,
                                            operator: 'in',
                                            value: 1,
                                            requiresRecordLevelCube: false
                                        } as CountDistinctQualifierDTO_Output
                                    }
                                >
                                    <div style={{ fontSize: '.875rem', lineHeight: '1rem', minWidth: 'min-content' }}>
                                        <div>{`${
                                            (filterDTO as DateAwareFilterDTO_Output).dateField === 'first_record_date' ||
                                            (filterDTO as DateAwareFilterDTO_Output).dateField === 'first_observation_date'
                                                ? filterDTO.table === 'patient_medication'
                                                    ? 'Medication Initiation'
                                                    : 'First Encounter'
                                                : 'Latest Encounter'
                                        }`}</div>
                                    </div>
                                </QualifierContent>
                            )}
                            {(filterDTO.qualifiers || ([] as unknown as DateQualifierDTO_Output[])).map(
                                (qualifier: DateQualifierDTO_Output, index: number) => {
                                    return (
                                        <Qualifier
                                            {...props}
                                            key={`${filterDTO.blockId}-${index}`}
                                            qualifier={qualifier}
                                            depth={props.depth + 1}
                                            parent={filterDTO}
                                            qualifierIndex={index}
                                        />
                                    )
                                }
                            )}
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}
