/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { FC } from 'react'
import { FilterContent } from '../nodes/FilterContent'
import { CARD_ICONS } from '../queryUtils'
import { FilterProps } from './Filter'
import { FilterDropdownMenu } from './FilterDropdownMenu'
import { FilterHeader } from './FilterHeader'
import { GenericFilterContent } from './GenericFilterContent'

export const PatientExternalCohortFilter: FC<FilterProps> = (props) => {
    const { filterDTO, setEditBlock, editDisabled } = props

    if (!filterDTO) {
        return null
    }

    return (
        <FilterContent {...props}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <FilterHeader icon={CARD_ICONS.external_cohort} title='External Cohort' />
                {editDisabled ? null : <FilterDropdownMenu onEdit={() => setEditBlock && setEditBlock({ filter: filterDTO, index: undefined })} />}
            </div>
            <GenericFilterContent {...props} />
        </FilterContent>
    )
}
