/* eslint-disable string-to-lingui/missing-lingui-transformation */
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'

export const FilterDropdownMenu = ({ onEdit }: { onEdit: () => void }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    return (
        <>
            <IconButton
                aria-controls='filter-menu'
                aria-haspopup='true'
                onClick={(event) => {
                    event.stopPropagation()
                    setAnchorEl(event.currentTarget)
                }}
                size='small'
                style={{ width: 40, color: '#103275' }}
            >
                <MoreHorizIcon fontSize='small' />
            </IconButton>
            <Menu
                id='filter-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null)
                }}
            >
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation()
                        setAnchorEl(null)
                        onEdit()
                    }}
                >
                    <EditOutlinedIcon fontSize='small' color='primary' style={{ marginRight: '8px' }} />
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation()
                        setAnchorEl(null)
                    }}
                >
                    <DeleteOutlinedIcon fontSize='small' color='primary' style={{ marginRight: '8px' }} />
                    Delete
                </MenuItem>
            </Menu>
        </>
    )
}
