import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { AnalyticsChartData, AnalyticsDataState, CohortState, reportsActions } from '../../state'
import { ReportChartComponent } from './ReportChartComponent'

export function createReportChartComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        withRouter(ReportChartComponent),
        (state: TState, ownProps) => ({
            reports: state.cohort.reports.reports,
            reportsLoading: state.cohort.reports.ui.loading,
            analytics: state.cohort.analytics.data[ownProps.cohort.id]?.[ownProps.reportAnalyticsType] as AnalyticsDataState<AnalyticsChartData>
        }),
        reportsActions
    )
}

export type ReportChart = ReturnType<typeof createReportChartComponent>
