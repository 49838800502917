import { useAuth0 } from '@auth0/auth0-react'
import { OpenAPI } from '@om1/falcon-api'
import { jwtDecode } from 'jwt-decode'
import { useEffect, useState } from 'react'

interface UserPermissions {
    user: any // Define a more specific type if available
    permissions: string[]
}

export const useUserPermissions = (): UserPermissions => {
    const { user } = useAuth0()
    const [permissions, setPermissions] = useState<string[]>([])

    useEffect(() => {
        if (typeof OpenAPI.TOKEN === 'string') {
            const decodedToken = jwtDecode<{ permissions: string[] }>(OpenAPI.TOKEN)
            setPermissions(decodedToken.permissions || [])
        }
    }, [])

    return { user, permissions }
}
