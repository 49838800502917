/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { FC, useEffect } from 'react'
import { FilterContent } from '../nodes/FilterContent'
import { CARD_ICONS } from '../queryUtils'
import { FilterProps } from './Filter'
import { FilterButtons } from './FilterButtons'
import { FilterDropdownMenu } from './FilterDropdownMenu'
import { FilterHeader } from './FilterHeader'
import { GenericFilterContent } from './GenericFilterContent'
import { useFetchRefs } from './useFetchRefs'

export const PatientObservationFilter: FC<FilterProps> = (props) => {
    const { filterDTO, setEditBlock, editDisabled } = props

    const { refs, fetchRefs } = useFetchRefs()

    useEffect(() => {
        fetchRefs(filterDTO)
    }, [filterDTO, fetchRefs])

    if (!filterDTO) {
        return null
    }

    return (
        <FilterContent {...props}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <FilterHeader icon={CARD_ICONS.observation} title='Observation' />
                {editDisabled ? null : <FilterDropdownMenu onEdit={() => setEditBlock && setEditBlock({ filter: filterDTO, index: undefined })} />}
            </div>
            <GenericFilterContent {...props} refs={refs} />
            {editDisabled ? null : <FilterButtons filterDTO={filterDTO} />}
        </FilterContent>
    )
}
