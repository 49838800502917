import { connectRedux } from '@om1/platform-utils'
import { get } from 'lodash'
import { cohortBlocksEditActions, CohortState } from '../../../state'
import { CohortCriterionComponent } from './CohortCriterionComponent'

export function createCohortCriterionComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        CohortCriterionComponent,
        (state: TState) => ({
            // Remove after non numeric result types are supported
            observationResultTypeMap: get(state.cohort.edit.blocks.ui.refLabels, ['patient_observation', 'result_type'], {})
        }),
        cohortBlocksEditActions
    )
}

export type CohortCriterion = ReturnType<typeof createCohortCriterionComponent>
