/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Button } from '@mui/material'
import React, { useState } from 'react'
import ReactJson from 'react-json-view'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { lucario as theme } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { NodeTypes, QueryBlock } from '../components/query/QueryBlock'

const RawToggleButton: React.FC<{ showRaw: boolean; setShowRaw: (showRaw: boolean) => void }> = ({ showRaw, setShowRaw }) => {
    return (
        <Button onClick={() => setShowRaw(!showRaw)} style={{ marginBottom: '10px' }}>
            {showRaw ? 'Show Query Block' : 'Show Raw JSON'}
        </Button>
    )
}

export const FormattedMessageContent: React.FC<{ message: any }> = ({ message }) => {
    const [showRaw, setShowRaw] = useState(false)
    return (
        <ReactMarkdown
            components={{
                code: ({ children, className, node, ...props }) => {
                    const language = className?.replace('language-', '')
                    const isInline = node?.position?.start.line === node?.position?.end.line // Check if the code block is inline

                    if (language === 'json') {
                        if (showRaw) {
                            return (
                                <>
                                    <RawToggleButton showRaw={showRaw} setShowRaw={setShowRaw} />
                                    <ReactJson src={JSON.parse(children as string)} />
                                </>
                            )
                        }
                        const messageParts = message.content.split('```')
                        const codeBlock = JSON.parse(messageParts[1].trim().replace(/^json/, ''))
                        return (
                            <>
                                <RawToggleButton showRaw={showRaw} setShowRaw={setShowRaw} />
                                <QueryBlock block={codeBlock as NodeTypes} depth={0} selectedBlock={undefined} setSelectedBlock={() => {}} />
                            </>
                        )
                    }

                    if (isInline) {
                        return <code style={{ backgroundColor: '#f5f5f5', padding: '2px 4px', borderRadius: '4px' }}>{children}</code>
                    }

                    return (
                        <SyntaxHighlighter language={language} style={theme}>
                            {Array.isArray(children) ? children : [children]}
                        </SyntaxHighlighter>
                    )
                },
                ul: ({ children, className, node, ...props }) => {
                    return (
                        <ul style={{ margin: '-10px 5px -10px 5px', padding: '5px', paddingLeft: '15px' }} {...props}>
                            {children}
                        </ul>
                    )
                },
                li: ({ children, className, node, ...props }) => {
                    return (
                        <li style={{ margin: '-5px', padding: '0px' }} {...props}>
                            {children}
                        </li>
                    )
                },
                h3: ({ children, ...props }) => {
                    return (
                        <h3 style={{ margin: '0px' }} {...props}>
                            {children}
                        </h3>
                    )
                }
            }}
        >
            {message.content}
        </ReactMarkdown>
    )
}
