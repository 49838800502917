import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { PlatformSettingsState } from '../state'
import { rwaInsightsReportActions } from '../state/rwa-insights-reports'
import { RWAInsightsReportsPageComponent } from './RWAInsightsReportsPageComponent'

export function createRWAInsightsReportsPage<TState extends { platformSettings: PlatformSettingsState }>() {
    return connectRedux(
        withRouter(RWAInsightsReportsPageComponent, { namespace: 'rwa-conditions', dataFetchAction: 'getRwaInsightsReports' }),
        (state: TState) => {
            return { rwaInsightsReports: state.platformSettings.rwaInsightsReports }
        },
        { ...rwaInsightsReportActions }
    )
}

export type RWAInsightsReportsPage = ReturnType<typeof createRWAInsightsReportsPage>
