/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { FC } from 'react'
import { FilterPropsWithRefs } from './GenericDateAwareFilter'
import { SimpleFilterDetails } from './SimpleFilterDetails'

export const GenericFilterContent: FC<FilterPropsWithRefs> = (props) => {
    return (
        <div style={{ marginBottom: '0.5rem', fontSize: '0.875rem' }}>
            <SimpleFilterDetails {...props} />
        </div>
    )
}
