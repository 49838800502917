/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Typography } from '@mui/material'
import { AndDTO_Output, FilterDTO_Output, OrDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { SelectedBlockProps } from '../../../views/ChatView'
import { PatientAndGroup } from '../filters/PatientAndGroup'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps, NodeTypes, QueryBlock, QueryBlockProps } from '../QueryBlock'
import { capitalizeFirstLetter } from './Or'
import { QueryNode } from './QueryNode'

const isDTOType = (node: any, type: string) => node.type === type

export const isPatientAndGroup = (node: AndDTO_Output) => {
    const [first, second, third] = node.and
    return (
        node.and.length === 3 &&
        isDTOType(first, 'FilterDTO') &&
        isDTOType(second, 'FilterDTO') &&
        isDTOType(third, 'OrDTO') &&
        (third as OrDTO_Output).or.every((orNode: NodeTypes) => orNode.type === 'FilterDTO' && (orNode as FilterDTO_Output).table === 'patient')
    )
}

export type AndProps = {
    andDTO: AndDTO_Output
} & Omit<SelectedBlockProps, 'setSelectedBlockId'> &
    BlockClickProps &
    EditQueryBlockProps &
    Pick<QueryBlockProps, 'depth'>

export const And: FC<AndProps> = ({ andDTO, ...props }) => {
    const { depth } = props
    if (isPatientAndGroup(andDTO)) {
        return <PatientAndGroup {...props} andDTO={andDTO} depth={depth} />
    }

    const renderAndDivider = (index: number, arrayLength: number) => {
        if (index < arrayLength - 1) {
            return (
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '0'
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            height: '57px',
                            width: '1px',
                            backgroundColor: '#32578E',
                            zIndex: 0,
                            marginTop: '4px'
                        }}
                    />
                    <span
                        style={{
                            position: 'sticky',
                            zIndex: 10,
                            padding: '0.5rem 1.5rem 0.25rem 1.5rem',
                            fontSize: '1rem',
                            border: '1px solid',
                            marginTop: '0.5rem',
                            marginBottom: '0.4rem',
                            backgroundColor: depth === 0 ? '#F5F5F5' : 'white',
                            color: 'black',
                            borderColor: depth === 0 ? '#F5F5F5' : 'white',
                            borderRadius: '4px',
                            boxShadow:
                                depth === 0
                                    ? ''
                                    : '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
                        }}
                    >
                        AND
                    </span>
                </div>
            )
        }
        return null
    }

    return (
        <QueryNode {...props} node={andDTO} disabled={Boolean(andDTO.disabled)} and={true}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                {andDTO.name && (
                    <Typography
                        sx={{
                            fontSize: '.95rem',
                            fontStyle: 'italic',
                            color: 'rgba(0, 0, 0, 0.6)',
                            textAlign: 'center'
                        }}
                    >
                        {capitalizeFirstLetter(andDTO.name)}
                    </Typography>
                )}
                {andDTO.and.map((filter, index) => (
                    <div key={Number(filter.blockId) + index}>
                        <QueryBlock {...props} block={{ ...filter, disabled: filter.disabled || andDTO.disabled }} depth={depth + 1} />
                        {renderAndDivider(index, andDTO.and.length)}
                    </div>
                ))}
            </div>
        </QueryNode>
    )
}
