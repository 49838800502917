/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { ExceptDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { SelectedBlockProps } from '../../../views/ChatView'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps, QueryBlock, QueryBlockProps } from '../QueryBlock'
import { QueryNode } from './QueryNode'

export type ExceptProps = {
    exceptDTO: ExceptDTO_Output
} & Omit<SelectedBlockProps, 'setSelectedBlockId'> &
    BlockClickProps &
    EditQueryBlockProps &
    Pick<QueryBlockProps, 'depth'>

export const Except: FC<ExceptProps> = ({ exceptDTO, ...props }) => {
    const { depth } = props
    const renderExceptBlocks = () => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
            {exceptDTO.except.map((except, index) => (
                <div key={Number(except.blockId) + index}>
                    <QueryBlock {...props} block={{ ...except, disabled: except.disabled || exceptDTO.disabled }} />
                </div>
            ))}
        </div>
    )

    return depth && depth > 0 ? (
        <QueryNode {...props} node={exceptDTO} title={`Except ${exceptDTO.blockId}`}>
            {renderExceptBlocks()}
        </QueryNode>
    ) : (
        renderExceptBlocks()
    )
}
