import {
    CountDistinctQualifierDTO_Output,
    DateAwareFilterDTO_Output,
    DateQualifierDTO_Output,
    FilterDTO_Output,
    FilterQualifierDTO_Output,
    FollowUpLengthQualifierDTO_Output,
    PatientAgeQualifierDTO_Output,
    SpecialtyQualifierDTO_Output
} from '@om1/falcon-api'
import { FC } from 'react'

import { SelectedBlockProps } from '../../../views/ChatView'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps } from '../QueryBlock'
import { CountDistinctQualifier } from './CountDistinctQualifier'
import { DateQualifier } from './DateQualifier'
import { FilterQualifier } from './FilterQualifier'
import { FollowUpLengthQualifier } from './FollowUpLengthQualifier'
import { PatientAgeQualifier } from './PatientAgeQualifier'
import { SpecialtyQualifier } from './SpecialtyQualifier'

export type QualifierTypes =
    | DateQualifierDTO_Output
    | PatientAgeQualifierDTO_Output
    | FollowUpLengthQualifierDTO_Output
    | SpecialtyQualifierDTO_Output
    | DateAwareFilterDTO_Output
    | CountDistinctQualifierDTO_Output
    | FilterQualifierDTO_Output

export type QualifierProps = {
    qualifier: QualifierTypes
    depth: number
    parent: FilterDTO_Output | DateAwareFilterDTO_Output
    disableClick?: boolean
    qualifierIndex?: number
} & EditQueryBlockProps &
    BlockClickProps &
    Omit<SelectedBlockProps, 'setSelectedBlockId'>

export const Qualifier: FC<QualifierProps> = (props) => {
    switch (props.qualifier.type) {
        case 'DateQualifierDTO':
            return <DateQualifier {...props} dateQualifierDTO={props.qualifier as DateQualifierDTO_Output} />
        case 'PatientAgeQualifierDTO':
            return <PatientAgeQualifier {...props} patientAgeQualifierDTO={props.qualifier as PatientAgeQualifierDTO_Output} />
        case 'FollowUpLengthQualifierDTO':
            return <FollowUpLengthQualifier {...props} followUpLengthQualifierDTO={props.qualifier as FollowUpLengthQualifierDTO_Output} />
        case 'SpecialtyQualifierDTO':
            return <SpecialtyQualifier {...props} specialtyQualifierDTO={props.qualifier as SpecialtyQualifierDTO_Output} />
        case 'CountDistinctQualifierDTO':
            return <CountDistinctQualifier {...props} countDistinctQualifierDTO={props.qualifier as CountDistinctQualifierDTO_Output} />
        case 'FilterQualifierDTO':
            return <FilterQualifier {...props} filterQualifierDTO={props.qualifier as FilterQualifierDTO_Output} />
        default:
            return <>{JSON.stringify({ ...props.qualifier, qualifierIndex: props.qualifierIndex })}</>
    }
}
