/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Button, MenuItem, Typography } from '@mui/material'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import TextField from '@mui/material/TextField/TextField'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'

import { DateRangeOperator, DateUnit } from '@om1/falcon-api'
import { FC, useEffect, useMemo, useState } from 'react'
import { determineLabel, getNodeFromLabel } from '../filters/RelativeDateFilter'

export const DateQualifierDialogContents: FC<{ editBlock: FilterDTO_Output | DateAwareFilterDTO_Output }> = ({ editBlock }) => {
    const qualifier = useMemo(() => {
        return (editBlock.qualifiers as unknown as any[]).find((qualifier) => qualifier.type === 'DateQualifierDTO')
    }, [editBlock])

    const [initialState, setInitialState] = useState(qualifier)
    const [currentState, setCurrentState] = useState(qualifier)
    useEffect(() => {
        setInitialState(qualifier)
        setCurrentState(qualifier)
    }, [qualifier])

    const hasChanged = JSON.stringify(initialState) !== JSON.stringify(currentState)
    const currentLabel = useMemo(() => {
        const label = determineLabel(currentState)
        return label || ''
    }, [currentState])

    const updateLabel = (label: 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than' | 'At any time between') => {
        const amount = currentState.intervalStartFromReferenceDate || currentState.intervalEndFromReferenceDate
        const node = getNodeFromLabel(label, Number(amount))
        setCurrentState({ ...currentState, ...node })
    }
    const isBetween = useMemo(() => {
        const result = currentState?.dateRangeOperator === 'Between'
        return result
    }, [currentState])

    const adjustedDate = new Date(currentState.referenceDate)
    adjustedDate.setDate(adjustedDate.getDate() + (currentState.intervalEndFromReferenceDate || 0))

    return (
        <>
            <DialogContent
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                    padding: '24px',
                    paddingTop: '40px',
                    overflowY: 'auto'
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px', gap: '16px' }}>
                    <Typography variant='h6' style={{ fontSize: '16px', fontWeight: 600, lineHeight: '56px' }}>
                        At least 1 Encounter(s)
                    </Typography>
                    <TextField
                        select
                        value={isBetween ? 'At any time between' : currentLabel}
                        onChange={(e) => {
                            if (e.target.value === 'At any time between') {
                                setCurrentState({ ...currentState, dateRangeOperator: 'Between' })
                                updateLabel('At any time between')
                            } else {
                                setCurrentState({ ...currentState, dateRangeOperator: 'After' })
                                updateLabel(e.target.value as 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than')
                            }
                        }}
                        style={{ minWidth: 120 }}
                    >
                        <MenuItem value='At most'>At most</MenuItem>
                        <MenuItem value='At least'>At least</MenuItem>
                        <MenuItem value='Exactly'>Exactly</MenuItem>
                        <MenuItem value='Less than'>Less than</MenuItem>
                        <MenuItem value='More than'>More than</MenuItem>
                        <MenuItem value='At any time between'>Time Range</MenuItem>
                    </TextField>
                    {isBetween ? (
                        <>
                            <TextField
                                type='date'
                                value={currentState.referenceDate}
                                onChange={(e) => setCurrentState({ ...currentState, referenceDate: new Date(e.target.value) })}
                                style={{ width: 180 }}
                            />
                            <TextField
                                type='date'
                                value={adjustedDate.toISOString().split('T')[0]}
                                onChange={(e) => setCurrentState({ ...currentState, referenceDate: new Date(e.target.value) })}
                                style={{ width: 180 }}
                            />
                        </>
                    ) : (
                        <>
                            <TextField
                                type='number'
                                value={
                                    currentState.intervalStartFromReferenceDate !== undefined
                                        ? currentState.intervalStartFromReferenceDate
                                        : currentState.intervalEndFromReferenceDate !== undefined
                                        ? currentState.intervalEndFromReferenceDate
                                        : ''
                                }
                                onChange={(e) => setCurrentState({ ...currentState, intervalStartFromReferenceDate: Number(e.target.value) })}
                                style={{ width: 120 }}
                            />
                            <TextField
                                select
                                value={currentState.intervalUnitFromReferenceDate || ''}
                                onChange={(e) => setCurrentState({ ...currentState, intervalUnitFromReferenceDate: e.target.value as DateUnit })}
                                style={{ minWidth: 120 }}
                            >
                                <MenuItem value='day'>Days</MenuItem>
                                <MenuItem value='month'>Months</MenuItem>
                                <MenuItem value='year'>Years</MenuItem>
                            </TextField>
                            <TextField
                                select
                                value={currentState.dateRangeOperator || ''}
                                onChange={(e) => setCurrentState({ ...currentState, dateRangeOperator: e.target.value as DateRangeOperator })}
                                style={{ minWidth: 120 }}
                            >
                                <MenuItem value='After'>After</MenuItem>
                                <MenuItem value='Before'>Before</MenuItem>
                                <MenuItem value='BeforeOrAfter'>Before Or After</MenuItem>
                            </TextField>
                            <TextField
                                type='date'
                                value={currentState.referenceDate}
                                onChange={(e) => setCurrentState({ ...currentState, referenceDate: new Date(e.target.value) })}
                                style={{ width: 180 }}
                            />
                        </>
                    )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', paddingTop: '16px' }}>
                    <Button variant='contained' color='primary' disabled={!hasChanged}>
                        Save
                    </Button>
                </div>
            </DialogContent>
        </>
    )
}
