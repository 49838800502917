/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { FC } from 'react'
import { QueryNode } from '../nodes/QueryNode'
import { FilterProps } from './Filter'
import { SimpleFilterDetails } from './SimpleFilterDetails'

export type FilterPropsWithRefs = FilterProps & {
    refs?: Array<Record<string, any>>
    valueOverwrite?: (value: string) => string
}

export const GenericDateAwareFilter: FC<FilterPropsWithRefs> = (props) => {
    const { filterDTO } = props
    return (
        <QueryNode {...props} node={filterDTO} title={`${filterDTO.table} date aware filter ${filterDTO.blockId}`}>
            <div style={{ marginBottom: '0.5rem', fontSize: '0.875rem' }}>
                <SimpleFilterDetails {...props} />
            </div>
        </QueryNode>
    )
}
