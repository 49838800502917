/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { AssistantSystemMessage } from '@om1/falcon-api/codegen/client/models/AssistantSystemMessage'
const type = 'system'
export const selectedFilterKey = 'Selected filter with ID:'
export const removeSelectedFilterKey = 'Removed filter selection'
/* eslint-disable string-to-lingui/missing-lingui-transformation */
export const setSelectedBlockIdMessage = (id: number | undefined, name: string): AssistantSystemMessage => {
    return {
        type,
        content: `${selectedFilterKey} ${id}; name: ${name}`
    }
}
export const removeSelectedBlockIdMessage = (): AssistantSystemMessage => {
    return {
        type,
        content: removeSelectedFilterKey
    }
}
