import { faChartGantt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import HealingOutlinedIcon from '@mui/icons-material/HealingOutlined'
import LocalPharmacyOutlinedIcon from '@mui/icons-material/LocalPharmacyOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import React, { FC, ReactNode } from 'react'

export const CriteriaTypeIcon: React.FunctionComponent<{ criteriaType: string }> = ({ criteriaType, ...props }) => {
    switch (criteriaType) {
        case 'Patient Attributes':
            return <AssignmentIndOutlinedIcon {...props} />
        case 'Diagnosis':
            return <AssignmentTurnedInOutlinedIcon {...props} />
        case 'Procedure':
            return <HealingOutlinedIcon {...props} />
        case 'Lab':
            return <ScienceOutlinedIcon {...props} />
        case 'Lab Tests':
            return <ScienceOutlinedIcon {...props} />
        case 'Medication':
            return <LocalPharmacyOutlinedIcon {...props} />
        case 'Observation':
            return <VisibilityOutlinedIcon {...props} />
        case 'EHR Notes':
            return <AssignmentOutlinedIcon {...props} />
        case 'Observation Period':
            return <FontAwesomeIcon style={{ marginLeft: '2px', marginRight: '2px' }} icon={faChartGantt} width={14} />
        case 'External Cohort':
            return <GroupsIcon {...props} />
        default:
            return null
    }
}

// Reusable header component
interface FilterHeaderProps {
    icon: ReactNode
    title: string
    onClick?: () => void
    disableClick?: boolean
    additionalLabel?: ReactNode
}

export const FilterHeader: FC<FilterHeaderProps> = ({ icon, title, onClick, disableClick = false, additionalLabel }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', marginBottom: '0.5rem' }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.25rem',
                    cursor: disableClick ? 'not-allowed' : 'pointer',
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: 0,
                    marginTop: '0.5rem'
                }}
                onClick={() => onClick && onClick()}
            >
                <span style={{ display: 'grid', placeItems: 'center', width: '1.5rem', height: '1.5rem' }}>
                    <i style={{ color: '#1e40af' }}>
                        <CriteriaTypeIcon criteriaType={title} />
                    </i>
                </span>
                <span style={{ fontWeight: '800', color: '#103275' }}>{title}</span>
            </div>
            {additionalLabel}
        </div>
    )
}
