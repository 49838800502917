/* eslint-disable string-to-lingui/missing-lingui-transformation */
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlineIcon from '@mui/icons-material/EditOutlined'
import { IconButton } from '@mui/material'
import { PatientAgeQualifierDTO_Output, RelativeDateFilterDTO_Output } from '@om1/falcon-api'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import React, { FC } from 'react'
import { determineLabel } from '../filters/RelativeDateFilter'
import { QualifierContent } from '../nodes/QualifierContent'
import { capitalizeFirstLetter } from './CountDistinctQualifier'
import { QualifierProps } from './Qualifier'

export const PatientAgeQualifier: FC<
    Omit<QualifierProps, 'qualifier'> & {
        patientAgeQualifierDTO: PatientAgeQualifierDTO_Output
    }
> = ({
    patientAgeQualifierDTO,
    parent,
    onBlockClick,
    disableClick = false,
    qualifierIndex = -1,
    setEditBlock,
    selectedBlock,
    editDisabled,
    setSelectedBlock
}) => {
    const parentName = capitalizeFirstLetter((parent as DateAwareFilterDTO_Output).table.replace('patient_', ''))

    const handleBlockClick = () => {
        if (onBlockClick && parent?.id) {
            onBlockClick(parent.id, qualifierIndex)
        }
    }

    const handleEditClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (setEditBlock) {
            setEditBlock({
                filter: parent,
                index: qualifierIndex
            })
        }
    }

    const ageLabel = `Patient Age ${determineLabel(patientAgeQualifierDTO as unknown as RelativeDateFilterDTO_Output)?.toLowerCase()} ${
        patientAgeQualifierDTO.intervalStart || patientAgeQualifierDTO.intervalEnd
    } ${patientAgeQualifierDTO.intervalUnit}s Old At Initial ${parentName}`

    return (
        <QualifierContent
            qualifier={patientAgeQualifierDTO}
            parent={parent}
            onBlockClick={handleBlockClick}
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
            setEditBlock={setEditBlock}
            selectedBlock={selectedBlock}
            setSelectedBlock={setSelectedBlock}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: '.875rem', lineHeight: '1rem' }}>{ageLabel}</div>
                    {!editDisabled && (
                        <div style={{ display: 'flex' }}>
                            <IconButton key='edit' color='primary' aria-label='Edit' sx={{ padding: 0.25 }} onClick={handleEditClick}>
                                <EditOutlineIcon fontSize='small' />
                            </IconButton>
                            <IconButton key='delete' color='primary' aria-label='Delete' sx={{ padding: 0.25 }} onClick={() => {}}>
                                <DeleteOutlineIcon fontSize='small' />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </QualifierContent>
    )
}
