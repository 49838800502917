import { t } from '@lingui/macro'
import { ReactNode } from 'react'
import { CriterionNode, FilterValueQualifier, FilterWithUnitQualifier, QualifierOperator, QualifierType } from '../../../state'
import { findQualifierByType } from '../utils/finders'
import { CohortCriterionQualifierSummary } from './CohortCriterionQualifierSummary'

export interface CohortCriterionLabNumericQualifierSummaryProps {
    node: CriterionNode
    readOnly?: boolean
    onEditClick: () => void
    onDeleteClick: () => void
}

export const CohortCriterionLabNumericQualifierSummary = (props: CohortCriterionLabNumericQualifierSummaryProps) => {
    const { node, readOnly, onDeleteClick, onEditClick } = props

    const qualifierWithoutUnits = findQualifierByType<FilterValueQualifier>(node.qualifiers, QualifierType.FilterQualifierDTO)
    const qualifierWithUnits = findQualifierByType<FilterWithUnitQualifier>(node.qualifiers, QualifierType.FilterWithUnitQualifierDTO)

    const qualifier = qualifierWithoutUnits || qualifierWithUnits

    const renderLabNumericOperatorText = (operator: QualifierOperator) => {
        switch (operator) {
            case QualifierOperator.Equals:
                return t`equal to`
            case QualifierOperator.NotEquals:
                return t`not equal to`
            case QualifierOperator.GreaterThan:
                return t`greater than`
            case QualifierOperator.GreaterThanOrEquals:
                return t`greater than or equal to`
            case QualifierOperator.LessThan:
                return t`less than`
            case QualifierOperator.LessThanOrEquals:
                return t`less than or equal to`
            case QualifierOperator.Between:
                return t`between`
            case QualifierOperator.NotBetween:
                return t`not between`
            case QualifierOperator.In:
                return t`in`
            case QualifierOperator.NotIn:
                return t`not in`
        }
    }

    const renderLabNumericQualifierText = (field: string, operator: QualifierOperator, values: string[] | number[]) => {
        if (operator === QualifierOperator.Between || operator === QualifierOperator.NotBetween) {
            return values.join(' and ')
        } else if (operator === QualifierOperator.In || operator === QualifierOperator.NotIn) {
            return `[${values.join(', ')}]`
        } else {
            return `${values[0]}`
        }
    }

    let label: ReactNode = null
    if (!qualifier) {
        label = t`Unknown`
    } else {
        const { field, operator, values } = qualifier
        const labOperatorText = renderLabNumericOperatorText(operator)
        const labScoresText = renderLabNumericQualifierText(field, operator, values)

        label = `${labOperatorText} ${labScoresText} ${qualifierWithUnits ? qualifierWithUnits.unit : ''}`
    }

    const handleDeleteClick = () => {
        onDeleteClick()
    }

    const handleEditClick = () => {
        onEditClick()
    }

    return (
        <CohortCriterionQualifierSummary
            summaryText={t`Lab result` + ' ' + label}
            readOnly={readOnly}
            editAction={{ label: t`Edit Lab Numeric Result`, onClick: handleEditClick }}
            deleteAction={{ label: t`Delete Lab Numeric Result`, onClick: handleDeleteClick }}
        />
    )
}
