/* eslint-disable string-to-lingui/missing-lingui-transformation */
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlineIcon from '@mui/icons-material/EditOutlined'
import { IconButton } from '@mui/material'
import { DateQualifierDTO_Output } from '@om1/falcon-api/codegen/client/models/DateQualifierDTO_Output'
import { RelativeDateFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/RelativeDateFilterDTO_Output'
import { FC } from 'react'
import { determineLabel } from '../filters/RelativeDateFilter'
import { QualifierContent } from '../nodes/QualifierContent'
import { QualifierProps } from './Qualifier'

export const DateQualifier: FC<
    Omit<QualifierProps, 'qualifier'> & {
        dateQualifierDTO: DateQualifierDTO_Output
    }
> = ({
    dateQualifierDTO,
    parent,
    onBlockClick,
    disableClick = false,
    qualifierIndex = -1,
    setEditBlock,
    selectedBlock,
    editDisabled,
    setSelectedBlock
}) => {
    const label = determineLabel(dateQualifierDTO as unknown as RelativeDateFilterDTO_Output)

    const formatDate = (date: Date) =>
        new Intl.DateTimeFormat('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        }).format(date)

    const calculateAdjustedDate = (referenceDate: Date, interval: number) => {
        const adjustedDate = new Date(referenceDate)
        adjustedDate.setDate(adjustedDate.getDate() + interval)
        return adjustedDate
    }

    const referenceDate = new Date(dateQualifierDTO.referenceDate)
    referenceDate.setUTCHours(0, 0, 0, 0)
    referenceDate.setDate(referenceDate.getDate() + 1)

    const adjustedDate = calculateAdjustedDate(referenceDate, dateQualifierDTO.intervalEndFromReferenceDate || 0)
    const formattedReferenceDate = calculateAdjustedDate(new Date(dateQualifierDTO.referenceDate), 1)

    const relationshipString =
        label === 'At any time between' || dateQualifierDTO.dateRangeOperator === 'Between'
            ? `At any time between ${formatDate(referenceDate)} and ${formatDate(adjustedDate)}`
            : `${label} ${dateQualifierDTO.intervalStartFromReferenceDate || dateQualifierDTO.intervalEndFromReferenceDate} ${
                  dateQualifierDTO.intervalUnitFromReferenceDate
              }${dateQualifierDTO?.intervalStartFromReferenceDate === 1 || dateQualifierDTO?.intervalEndFromReferenceDate === 1 ? '' : 's'} ${
                  dateQualifierDTO.dateRangeOperator.toLowerCase() === 'beforeorafter'
                      ? 'Before or After'
                      : dateQualifierDTO.dateRangeOperator.toLowerCase()
              } ${formatDate(formattedReferenceDate)}`

    return (
        <QualifierContent
            parent={parent}
            onBlockClick={() => onBlockClick && parent?.id && onBlockClick(parent?.id, qualifierIndex)}
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
            qualifier={dateQualifierDTO}
            setEditBlock={setEditBlock}
            selectedBlock={selectedBlock}
            setSelectedBlock={setSelectedBlock}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: '.875rem', lineHeight: '1rem' }}>{relationshipString}</div>
                    {!editDisabled && (
                        <div style={{ display: 'flex' }}>
                            <IconButton
                                key='edit'
                                color='primary'
                                aria-label={'Edit'}
                                sx={{ padding: 0.25 }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setEditBlock && setEditBlock({ filter: parent, index: qualifierIndex })
                                }}
                            >
                                <EditOutlineIcon fontSize='small' />
                            </IconButton>
                            <IconButton key='delete' color='primary' aria-label={'Delete'} sx={{ padding: 0.25 }} onClick={() => {}}>
                                <DeleteOutlineIcon fontSize='small' />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </QualifierContent>
    )
}
