/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Button, DialogContent, MenuItem, TextField } from '@mui/material'
import Typography from '@mui/material/Typography/Typography'
import { DateRangeOperator } from '@om1/falcon-api/codegen/client/models/DateRangeOperator'
import { DateUnit } from '@om1/falcon-api/codegen/client/models/DateUnit'
import { RelativeDateFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/RelativeDateFilterDTO_Output'
import { FC, useEffect, useMemo, useState } from 'react'
import { determineLabel, getNodeFromLabel } from '../filters/RelativeDateFilter'
import { tableMap } from '../qualifiers/CountDistinctQualifier'

export const EditRelativeDateFilterDialog: FC<{ editBlock: RelativeDateFilterDTO_Output }> = ({ editBlock }) => {
    const [initialState, setInitialState] = useState(editBlock)
    const [currentState, setCurrentState] = useState(editBlock)

    useEffect(() => {
        setInitialState(editBlock)
        setCurrentState(editBlock)
    }, [editBlock])

    const hasChanged = JSON.stringify(initialState) !== JSON.stringify(currentState)
    const currentLabel = useMemo(() => {
        const label = determineLabel(currentState)
        return label || 'At most'
    }, [currentState])

    const updateLabel = (label: 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than') => {
        const amount = currentState.intervalStartFromReferenceDate || currentState.intervalEndFromReferenceDate
        const node = getNodeFromLabel(label, Number(amount))
        setCurrentState({ ...currentState, ...node })
    }

    return (
        <>
            <DialogContent
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                    padding: '24px',
                    paddingTop: '40px',
                    overflowY: 'auto'
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px', gap: '16px' }}>
                    <Typography variant='h6' style={{ fontSize: '16px', fontWeight: 600, lineHeight: '56px' }}>
                        {tableMap[currentState.subjectFilter.table].charAt(0).toUpperCase() + tableMap[currentState.subjectFilter.table].slice(1)}
                    </Typography>
                    <TextField
                        select
                        value={currentLabel}
                        onChange={(e) => updateLabel(e.target.value as 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than')}
                        style={{ minWidth: 120 }}
                    >
                        <MenuItem value='At most'>At most</MenuItem>
                        <MenuItem value='At least'>At least</MenuItem>
                        <MenuItem value='Exactly'>Exactly</MenuItem>
                        <MenuItem value='Less than'>Less than</MenuItem>
                        <MenuItem value='More than'>More than</MenuItem>
                    </TextField>
                    <TextField
                        type='number'
                        value={currentState.intervalStartFromReferenceDate || currentState.intervalEndFromReferenceDate || ''}
                        onChange={(e) => setCurrentState({ ...currentState, intervalStartFromReferenceDate: Number(e.target.value) })}
                        style={{ width: 60 }}
                    />
                    <TextField
                        select
                        value={currentState.intervalUnitFromReferenceDate || ''}
                        onChange={(e) => setCurrentState({ ...currentState, intervalUnitFromReferenceDate: e.target.value as DateUnit })}
                        style={{ minWidth: 120 }}
                    >
                        <MenuItem value='day'>Days</MenuItem>
                        <MenuItem value='month'>Months</MenuItem>
                        <MenuItem value='year'>Years</MenuItem>
                    </TextField>
                    <TextField
                        select
                        value={currentState.dateRangeOperator || ''}
                        onChange={(e) => setCurrentState({ ...currentState, dateRangeOperator: e.target.value as DateRangeOperator })}
                        style={{ minWidth: 120 }}
                    >
                        <MenuItem value='After'>After</MenuItem>
                        <MenuItem value='Before'>Before</MenuItem>
                        <MenuItem value='BeforeOrAfter'>Before Or After</MenuItem>
                    </TextField>
                    <Typography variant='h6' style={{ fontSize: '16px', fontWeight: 600, lineHeight: '56px' }}>
                        {tableMap[currentState.subjectFilter.table].charAt(0).toUpperCase() + tableMap[currentState.subjectFilter.table].slice(1)}
                    </Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', paddingTop: '16px' }}>
                    <Button variant='contained' color='primary' disabled={!hasChanged}>
                        Save
                    </Button>
                </div>
            </DialogContent>
        </>
    )
}
