import { t } from '@lingui/macro'
import { format } from 'date-fns'
import { ReactNode } from 'react'
import { CriterionNode, FilterValueQualifier, QualifierOperator, QualifierType } from '../../../state'
import { DTODateStringToDate } from '../utils/date-utils'
import { findQualifierByType } from '../utils/finders'
import { CohortCriterionQualifierSummary } from './CohortCriterionQualifierSummary'
import { ObservationScoreField } from './ObservationScoreEditDialogComponent'

export interface CohortCriterionObservationScoreSummaryProps {
    node: CriterionNode
    readOnly?: boolean
    onEditClick: () => void
    onDeleteClick: () => void
}

export const CohortCriterionObservationScoreSummary = (props: CohortCriterionObservationScoreSummaryProps) => {
    const { node, readOnly, onDeleteClick, onEditClick } = props

    const observationScoreQualifier = findQualifierByType<FilterValueQualifier>(node.qualifiers, QualifierType.FilterQualifierDTO)

    const renderObservationFieldText = (field: string) => {
        if (field === `${ObservationScoreField.Numeric}_result`) {
            return t`Observation score`
        } else if (field === `${ObservationScoreField.Date}_result`) {
            return t`Observation date`
        } else {
            return t`Observation response`
        }
    }

    const renderObservationNumericOperatorText = (operator: QualifierOperator) => {
        switch (operator) {
            case QualifierOperator.Equals:
                return t`equal to`
            case QualifierOperator.NotEquals:
                return t`not equal to`
            case QualifierOperator.GreaterThan:
                return t`greater than`
            case QualifierOperator.GreaterThanOrEquals:
                return t`greater than or equal to`
            case QualifierOperator.LessThan:
                return t`less than`
            case QualifierOperator.LessThanOrEquals:
                return t`less than or equal to`
            case QualifierOperator.Between:
                return t`between`
            case QualifierOperator.NotBetween:
                return t`not between`
            case QualifierOperator.In:
                return t`in`
            case QualifierOperator.NotIn:
                return t`not in`
        }
    }

    const renderObservationDateOperatorText = (operator: QualifierOperator) => {
        switch (operator) {
            case QualifierOperator.Equals:
                return t`on`
            case QualifierOperator.NotEquals:
                return t`not on`
            case QualifierOperator.GreaterThan:
                return t`after`
            case QualifierOperator.GreaterThanOrEquals:
                return t`on or after`
            case QualifierOperator.LessThan:
                return t`before`
            case QualifierOperator.LessThanOrEquals:
                return t`before or on`
            case QualifierOperator.Between:
                return t`between`
            case QualifierOperator.NotBetween:
                return t`not between`
            case QualifierOperator.In:
                return t`in`
            case QualifierOperator.NotIn:
                return t`not in`
        }
    }

    const renderObservationTextOperatorText = (operator: QualifierOperator) => {
        switch (operator) {
            case QualifierOperator.In:
                return t`in`
            case QualifierOperator.NotIn:
                return t`not in`
        }
    }

    // Render the operator text based on the field type
    const renderObservationOperatorText = (field: string, operator: QualifierOperator) => {
        if (field === `${ObservationScoreField.Numeric}_result`) {
            return renderObservationNumericOperatorText(operator)
        } else if (field === `${ObservationScoreField.Date}_result`) {
            return renderObservationDateOperatorText(operator)
        } else if (field === `${ObservationScoreField.Text}_result`) {
            return renderObservationTextOperatorText(operator)
        } else {
            return t`unknown`
        }
    }

    const renderObservationScoresText = (field: string, operator: QualifierOperator, values: string[] | number[]) => {
        // Reformat date qualifiers, leave text and numeric unchanged
        const formattedValues = (() => {
            if (field === `${ObservationScoreField.Date}_result`) {
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                return values.map((value) => format(DTODateStringToDate(value), 'MMM dd, yyyy'))
            }

            return values
        })()

        if (operator === QualifierOperator.Between || operator === QualifierOperator.NotBetween) {
            return formattedValues.join(' and ')
        } else if (operator === QualifierOperator.In || operator === QualifierOperator.NotIn) {
            return `[${formattedValues.join(', ')}]`
        } else {
            return `${formattedValues[0]}`
        }
    }

    let label: ReactNode = null
    if (!observationScoreQualifier) {
        label = t`Unknown`
    } else {
        const { field, operator, values } = observationScoreQualifier
        const observationFieldText = renderObservationFieldText(field)
        const observationOperatorText = renderObservationOperatorText(field, operator)
        const observationScoresText = renderObservationScoresText(field, operator, values)

        label = `${observationFieldText} ${observationOperatorText} ${observationScoresText}`
    }

    const handleDeleteClick = () => {
        onDeleteClick()
    }

    const handleEditClick = () => {
        onEditClick()
    }

    return (
        <CohortCriterionQualifierSummary
            summaryText={label}
            readOnly={readOnly}
            editAction={{ label: t`Edit Observation Score`, onClick: handleEditClick }}
            deleteAction={{ label: t`Delete Observation Score`, onClick: handleDeleteClick }}
        />
    )
}
