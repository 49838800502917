/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { FC } from 'react'
import { DateQualifierDialogContents } from '../dialogs/DateQualifierDialogContents'
import { PatientAgeQualifierDialogContents } from '../dialogs/PatientAgeQualifierDialogContents'

export const EditQualifierDialogContents: FC<{ editBlock: FilterDTO_Output | DateAwareFilterDTO_Output; qualifierType: string }> = ({
    editBlock,
    qualifierType
}) => {
    switch (qualifierType) {
        case 'DateQualifierDTO':
            return <DateQualifierDialogContents editBlock={editBlock} />
        case 'PatientAgeQualifierDTO':
            return <PatientAgeQualifierDialogContents editBlock={editBlock} />
        case 'FollowUpLengthQualifierDTO':
            return <>{`FollowUpLengthQualifierDTO :${JSON.stringify(editBlock)}`}</>
        case 'SpecialtyQualifierDTO':
            return <>{`SpecialtyQualifierDTO :${JSON.stringify(editBlock)}`}</>
        case 'CountDistinctQualifierDTO':
            return <>{`CountDistinctQualifierDTO :${JSON.stringify(editBlock)}`}</>
        case 'FilterQualifierDTO':
            return <>{`FilterQualifierDTO :${JSON.stringify(editBlock)}`}</>
        default:
            return <>{JSON.stringify(editBlock)}</>
    }
}
