/* eslint-disable */
import { Typography } from '@mui/material'
import { DateAwareFilterDTO_Output, FilterDTO_Output, RelativeDateFilterDTO_Output, RelativeFollowUpFilterDTO_Output } from '@om1/falcon-api'
import { FC, PropsWithChildren } from 'react'
import { SelectedBlockProps } from '../../../views/ChatView'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps, QueryBlockProps } from '../QueryBlock'
import { capitalizeFirstLetter } from './Or'

export type FilterContentProps = {
    filterDTO: FilterDTO_Output | DateAwareFilterDTO_Output | RelativeDateFilterDTO_Output | RelativeFollowUpFilterDTO_Output
} & Omit<SelectedBlockProps, 'setSelectedBlockId'> &
    BlockClickProps &
    EditQueryBlockProps &
    Omit<QueryBlockProps, 'block'>

// Base card content wrapper
export const FilterContent: FC<PropsWithChildren<Omit<FilterContentProps, 'depth'> & { disableClick?: boolean }>> = (props) => {
    const isSelected = props.filterDTO?.id && props.filterDTO.id === props.selectedBlock?.id
    const isRelativeDateFilter = props.filterDTO?.type === 'RelativeDateFilterDTO'
    const isRelativeFollowUpFilter = props.filterDTO?.type === 'RelativeFollowUpFilterDTO'
    const isRelativeFilter = isRelativeDateFilter || isRelativeFollowUpFilter
    const clickable = !props.filterDTO?.disabled && !props.disableClick && !isRelativeFilter
    if (isRelativeDateFilter) {
        return <>{props.children}</> || null
    }
    return (
        <>
            <div style={{ backgroundColor: '#F5F5F5', width: '100%' }}>
                <div
                    style={{
                        zIndex: 50,
                        width: '100%',
                        height: '',
                        marginTop: '0rem',
                        overflow: 'hidden',
                        position: 'relative',
                        borderWidth: isSelected ? '5px' : '',
                        padding: '1rem 1rem 1rem 1rem',
                        opacity: props.filterDTO?.disabled ? 0.25 : 1,
                        backgroundColor: isRelativeFollowUpFilter ? '#F5F5F5' : '#FFFFFF',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                        borderColor: isRelativeFilter ? '#e9d8fd' : isSelected ? '3px solid' : 'transparent',
                        border: isSelected
                            ? isRelativeFilter
                                ? '1px dashed gray'
                                : '1px dashed blue'
                            : isRelativeFilter
                            ? '1px solid transparent'
                            : '1px solid transparent',
                        cursor: clickable ? 'pointer' : 'default'
                    }}
                    onClick={(event) => {
                        if (clickable && props.onBlockClick && props.filterDTO.id && !isRelativeFilter) {
                            event.stopPropagation()
                            props.onBlockClick(Number(props.filterDTO.id), undefined)
                        }
                    }}
                >
                    {props.filterDTO?.name && (
                        <>
                            <Typography
                                sx={{
                                    fontSize: '.95rem',
                                    marginTop: '0.5rem',
                                    fontStyle: 'italic',
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    textAlign: 'center'
                                }}
                            >
                                {capitalizeFirstLetter(props.filterDTO.name)}
                            </Typography>
                            <div
                                style={{
                                    marginTop: '0.5rem',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                                    width: '100%'
                                }}
                            />
                        </>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ flex: 1, transition: 'all 0.3s' }}>{props.children || null}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
