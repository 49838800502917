/* eslint-disable string-to-lingui/missing-lingui-transformation */
import EditOutlineIcon from '@mui/icons-material/EditOutlined'
import { IconButton } from '@mui/material'
import { CountDistinctQualifierDTO_Output } from '@om1/falcon-api/codegen/client/models/CountDistinctQualifierDTO_Output'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import React, { FC } from 'react'
import { QualifierContent } from '../nodes/QualifierContent'
import { QualifierProps } from './Qualifier'

export const tableMap: Record<string, string> = {
    patient_diagnosis: 'diagnosis',
    patient_lab: 'lab',
    patient_medication: 'medication',
    patient_note: 'note',
    patient_external_cohort: 'external cohort',
    observation_period: 'observation period',
    patient_observation: 'observation',
    patient_procedure: 'procedure'
}

// Utility function to capitalize the first letter
export const capitalizeFirstLetter = (str: string) => {
    if (!str) return str
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const CountDistinctQualifier: FC<
    Omit<QualifierProps, 'qualifier'> & {
        countDistinctQualifierDTO: CountDistinctQualifierDTO_Output
    }
> = (props) => {
    const {
        countDistinctQualifierDTO,
        parent,
        onBlockClick,
        disableClick = false,
        qualifierIndex = -1,
        setEditBlock,
        selectedBlock,
        editDisabled
    } = props
    const dateField = (parent as DateAwareFilterDTO_Output).dateField
    const parentName = capitalizeFirstLetter((parent as DateAwareFilterDTO_Output).table.replace('patient_', ''))

    const getDateFieldText = () => {
        switch (dateField) {
            case undefined:
            case 'any_record_date':
            case 'any_observation_date':
                return `At Least ${countDistinctQualifierDTO.value} Encounter(s)`
            case 'first_record_date':
                return `Initial ${parentName}`
            case 'last_record_date':
                return `Latest ${parentName}`
            default:
                return ''
        }
    }

    const handleBlockClick = () => {
        if (onBlockClick && parent?.id) {
            onBlockClick(parent.id, qualifierIndex)
        }
    }

    const handleEditClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (setEditBlock) {
            setEditBlock({
                filter: parent,
                index: qualifierIndex
            })
        }
    }

    return (
        <QualifierContent
            {...props}
            parent={parent}
            onBlockClick={handleBlockClick}
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
            qualifier={countDistinctQualifierDTO}
            setEditBlock={setEditBlock}
            selectedBlock={selectedBlock}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <div style={{ fontSize: '.875rem', lineHeight: '1rem' }}>{getDateFieldText()}</div>
                    {!editDisabled && (
                        <div style={{ display: 'flex' }}>
                            <IconButton key='edit' color='primary' aria-label='Edit' sx={{ padding: 0.25 }} onClick={handleEditClick}>
                                <EditOutlineIcon fontSize='small' />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </QualifierContent>
    )
}
