/* eslint-disable string-to-lingui/missing-lingui-transformation */
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlineIcon from '@mui/icons-material/EditOutlined'
import { IconButton } from '@mui/material'
import { PaginatedDictDTO_str_Any_ } from '@om1/falcon-api/codegen/client/models/PaginatedDictDTO_str_Any_'
import { RefTable } from '@om1/falcon-api/codegen/client/models/RefTable'
import { SpecialtyQualifierDTO_Output } from '@om1/falcon-api/codegen/client/models/SpecialtyQualifierDTO_Output'
import { ExplorerAnalyticsRefService } from '@om1/falcon-api/codegen/client/services/ExplorerAnalyticsRefService'
import { FC, useCallback, useEffect, useState } from 'react'
import { QualifierContent } from '../nodes/QualifierContent'
import { fetchData } from '../queryUtils'
import { tableMap } from './CountDistinctQualifier'
import { QualifierProps } from './Qualifier'

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const SpecialtyQualifier: FC<
    Omit<QualifierProps, 'qualifier'> & {
        specialtyQualifierDTO: SpecialtyQualifierDTO_Output
    }
> = ({
    specialtyQualifierDTO,
    parent,
    onBlockClick,
    disableClick = false,
    qualifierIndex = -1,
    setEditBlock,
    selectedBlock,
    editDisabled,
    setSelectedBlock
}) => {
    const [paginatedDict, setPaginatedDict] = useState<PaginatedDictDTO_str_Any_ | null>(null)

    useEffect(() => {
        const fetchDataAsync = async () => {
            const data = await fetchData(
                'explorerCohorts',
                { refTableName: 'ref_specialty' as RefTable, page: 1, limit: 100, query: null, filters: specialtyQualifierDTO.values },
                ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet
            )
            setPaginatedDict(data)
        }

        fetchDataAsync()
    }, [specialtyQualifierDTO.values])

    const handleBlockClick = useCallback(() => {
        if (onBlockClick && parent?.id) {
            onBlockClick(parent.id, qualifierIndex)
        }
    }, [onBlockClick, parent, qualifierIndex])

    const handleEditClick = useCallback(
        (e) => {
            e.stopPropagation()
            if (setEditBlock) {
                setEditBlock({
                    filter: parent,
                    index: qualifierIndex
                })
            }
        },
        [setEditBlock, parent, qualifierIndex]
    )

    const renderSpecialties = () => {
        return specialtyQualifierDTO.values
            .map((value) => {
                const specialty = paginatedDict?.data.find((item) => item.specialty_concept_id === value)?.specialty
                return specialty || value // Fallback to the value if no specialty is found
            })
            .join(', ')
    }

    return (
        <QualifierContent
            qualifier={specialtyQualifierDTO}
            parent={parent}
            onBlockClick={handleBlockClick}
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
            setEditBlock={setEditBlock}
            selectedBlock={selectedBlock}
            setSelectedBlock={setSelectedBlock}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <div style={{ fontSize: '.875rem', lineHeight: '1rem' }}>
                        {`${capitalizeFirstLetter(
                            (parent.table && tableMap[parent.table]) || parent.table || 'default'
                        )}s from ${renderSpecialties()}`}
                    </div>
                    {!editDisabled && (
                        <div style={{ display: 'flex' }}>
                            <IconButton key='edit' color='primary' aria-label={'Edit'} sx={{ padding: 0.25 }} onClick={handleEditClick}>
                                <EditOutlineIcon fontSize='small' />
                            </IconButton>
                            <IconButton key='delete' color='primary' aria-label={'Delete'} sx={{ padding: 0.25 }} onClick={() => {}}>
                                <DeleteOutlineIcon fontSize='small' />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </QualifierContent>
    )
}
