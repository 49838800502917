import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { CohortState, cohortBlocksEditActions, cohortEditActions, reportsActions } from '../../state'
import { SummaryReportPageComponent } from './SummaryReportPageComponent'

export function createSummaryReportPageComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        withRouter(SummaryReportPageComponent, { namespace: 'cohorts', dataFetchAction: 'cohortGet' }),
        (state: TState) => ({
            tree: state.cohort.edit.blocks.tree,
            cohort: state.cohort.edit.base.cohort,
            sizeLoading: state.cohort.common.ui.cohortSizeLoading[state.cohort.edit.base.cohort.id],
            summaryReportResult: state.cohort.reports.summaryReportResult,
            reports: state.cohort.reports
        }),
        { ...cohortEditActions, ...cohortBlocksEditActions, ...reportsActions }
    )
}

export type SummaryReportPage = ReturnType<typeof createSummaryReportPageComponent>
