/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Typography } from '@mui/material'
import { OrDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { SelectedBlockProps } from '../../../views/ChatView'
import { isPatientOrGroup, PatientOrGroup } from '../filters/PatientOrGroup'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps, QueryBlock, QueryBlockProps } from '../QueryBlock'
import { QueryNode } from './QueryNode'

// Utility function to capitalize the first letter of the first word
export const capitalizeFirstLetter = (text: string) => {
    if (!text) return text
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export type OrProps = {
    orDTO: OrDTO_Output
} & Omit<SelectedBlockProps, 'setSelectedBlockId'> &
    BlockClickProps &
    EditQueryBlockProps &
    Pick<QueryBlockProps, 'depth'>

const OR_SEPARATOR_STYLE = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0'
}

const OR_LINE_STYLE = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '60px',
    width: '1px',
    backgroundColor: '#32578E',
    zIndex: 0,
    marginTop: '5px'
}

const OR_TEXT_STYLE = {
    position: 'sticky',
    zIndex: 10,
    padding: '0.5rem 1.5rem',
    fontSize: '1rem',
    border: '1px solid',
    marginTop: '0.5rem',
    marginBottom: '0.4rem',
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'white',
    borderRadius: '4px'
}

export const Or: FC<OrProps> = ({ orDTO, ...props }) => {
    const { depth } = props
    if (isPatientOrGroup(orDTO)) {
        return <PatientOrGroup {...props} node={orDTO} depth={depth + 1} />
    }

    const renderOrSeparator = (index: number, arrayLength: number) => {
        if (index < arrayLength - 1) {
            return (
                <div style={{ ...OR_SEPARATOR_STYLE, position: 'relative' as const }}>
                    <div style={{ ...OR_LINE_STYLE, position: 'absolute' as const }} />
                    <span style={{ ...OR_TEXT_STYLE, position: 'sticky' as const }}>OR</span>
                </div>
            )
        }
        return null
    }

    return (
        <QueryNode {...props} node={orDTO} disabled={Boolean(orDTO.disabled)} or={true}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                {orDTO.name && (
                    <Typography
                        sx={{
                            fontSize: '.95rem',
                            fontStyle: 'italic',
                            color: 'rgba(0, 0, 0, 0.6)',
                            textAlign: 'center'
                        }}
                    >
                        {capitalizeFirstLetter(orDTO.name)}
                    </Typography>
                )}
                {orDTO.or.map((or, index, array) => (
                    <div key={Number(or.blockId) + index}>
                        <QueryBlock {...props} block={{ ...or, disabled: or.disabled || orDTO.disabled }} depth={depth + 1} />
                        {renderOrSeparator(index, array.length)}
                    </div>
                ))}
            </div>
        </QueryNode>
    )
}
