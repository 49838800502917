import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { reportsActions } from '../../state'
import { CohortReportsComponent } from './CohortReportsComponent'

export function createCohortReports<TState extends {}>() {
    return connectRedux(withRouter(CohortReportsComponent), (state: TState) => state, reportsActions)
}

export type CohortReports = ReturnType<typeof createCohortReports>
