import { explorerAssistantConversationActions } from '@om1/platform-assistant-ui'
import { withRouter } from '@om1/platform-routing'
import { trackingActions } from '@om1/platform-tracking'
import { connectRedux } from '@om1/platform-utils'
import {
    CohortOwner,
    CohortState,
    cohortCommonActions,
    cohortCreateActions,
    cohortDeleteActions,
    cohortEditActions,
    cohortListActions,
    reportsActions
} from '../../state'
import { CohortDashboardPageComponent } from './CohortDashboardPageComponent'

export function createCohortDashboardPageComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        withRouter(CohortDashboardPageComponent, { namespace: 'dashboard' }),
        (state: TState) => ({
            cohorts: state.cohort.list.cohorts[CohortOwner.User],
            datasets: state.cohort.list.cohorts[CohortOwner.System],
            paginationMeta: state.cohort.list.paginationMeta,
            createLoading: state.cohort.create.ui.loading,
            cohortsLoading: state.cohort.list.loading,
            cohortSizeLoading: state.cohort.common.ui.cohortSizeLoading
        }),
        {
            ...cohortListActions,
            ...trackingActions,
            ...reportsActions,
            ...cohortCreateActions,
            ...cohortEditActions,
            cohortDelete: cohortDeleteActions.cohortDelete,
            cohortSizeGet: cohortCommonActions.cohortSizeGet,
            trackEvent: trackingActions.trackEvent,
            ...explorerAssistantConversationActions
        }
    )
}

export type CohortDashboardPage = ReturnType<typeof createCohortDashboardPageComponent>
