import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { CircularProgress, Tooltip } from '@mui/material'
import { XCircleIcon } from 'lucide-react'
import React from 'react'

interface ToolCallsProps {
    toolCall: any
    isComplete: boolean
    status?: string
    error?: string
}

const capitalize = <T extends string>(s: T) =>
    s
        .split(' ')
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(' ') as Capitalize<T>

export const ToolCalls: React.FC<ToolCallsProps> = ({ toolCall, isComplete, status, error }) => {
    const toolName = capitalize(toolCall.function.name.split('_').join(' '))

    return (
        <Tooltip
            title={
                <div>
                    {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                    <strong>Type: {toolCall.type}</strong>
                    <br />
                    {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                    <strong>Args: </strong>{' '}
                    {toolName !== 'get_my_cohort' && toolName !== 'summarize_my_cohort'
                        ? toolCall.function.arguments
                        : JSON.stringify({
                              cohort_id: JSON.parse(toolCall.function.arguments).cohort_id,
                              user_token: 'REDACTED'
                          })}
                    {toolName !== 'get_my_cohort' && toolName !== 'summarize_my_cohort' && error !== 'null' && status === 'error' && (
                        <div>
                            {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                            <strong>Error: </strong>
                            {error}
                        </div>
                    )}
                </div>
            }
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    borderRadius: '4px',
                    backgroundColor: '#f5f5f5',
                    fontSize: '0.85em',
                    color: '#666',
                    fontStyle: 'italic'
                }}
            >
                {isComplete ? (
                    status === 'success' ? (
                        <CheckCircleIcon style={{ color: '#4caf50', fontSize: '16px' }} />
                    ) : (
                        <XCircleIcon style={{ color: '#f44336' }} size={16} />
                    )
                ) : (
                    <CircularProgress size={16} />
                )}
                <span style={{ marginTop: '4px', marginBottom: '4px' }}>{toolName}</span>
            </div>
        </Tooltip>
    )
}
