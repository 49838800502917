import { QueryClient, QueryKey } from '@tanstack/react-query'
import { call } from 'redux-saga/effects'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 60, // 1 hour in ms
            cacheTime: 1000 * 60 * 60, // 1 hour in ms
            refetchOnWindowFocus: false // Disables automatic refetching when browser window is focused.
        }
    }
})

// Generic function to generate query key
export function generateQueryKey<T extends Record<string, unknown>>(baseKey: string, payload: T): QueryKey {
    return [baseKey, ...Object.entries(payload).flat()]
}

// Generic function to fetch data with caching
export function* fetchDataWithCacheGenerator<T, P extends Record<string, unknown>>(
    payload: P,
    fetchFn: (params: P) => Promise<T>,
    baseKeyOverride?: string
): Generator<unknown, T, T> {
    const baseKey = baseKeyOverride || fetchFn.name || 'defaultBaseKey'
    const queryKey = generateQueryKey(baseKey, payload)
    let data: T | undefined = queryClient.getQueryData<T>(queryKey)

    if (!data) {
        data = yield call(() => queryClient.fetchQuery(queryKey, () => fetchFn(payload)))
    }

    return data
}

// Generic function to fetch data with caching
export async function fetchDataWithCache<T, P extends Record<string, unknown>>(
    baseKey: string,
    payload: P,
    fetchFn: (params: P) => Promise<T>
): Promise<T | undefined> {
    const queryKey = generateQueryKey(baseKey, payload)
    let data: T | undefined = queryClient.getQueryData<T>(queryKey)

    if (!data) {
        await queryClient
            .fetchQuery(queryKey, () => fetchFn(payload))
            .then((newData) => {
                data = newData
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }

    if (!data) {
        console.error('Data could not be fetched and is undefined')
    }

    return data
}
