import { DateAwareFilterDTO_Output, FilterDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { FilterProps } from './Filter'
import { GenericDateAwareFilter } from './GenericDateAwareFilter'
import { ObservationPeriodFilter } from './ObservationPeriodFilter'
import { PatientDiagnosisFilter } from './PatientDiagnosisFilter'
import { PatientLabFilter } from './PatientLabFilter'
import { PatientMedicationFilter } from './PatientMedicationFilter'
import { PatientNoteFilter } from './PatientNoteFilter'
import { PatientObservationFilter } from './PatientObservationFilter'
import { PatientProcedureFilter } from './PatientProcedureFilter'

// Mapping of table names to their respective filter components
const filterComponentMap: Record<string, FC<FilterProps & { filterDTO: DateAwareFilterDTO_Output | FilterDTO_Output }>> = {
    patient_note: PatientNoteFilter,
    patient_procedure: PatientProcedureFilter,
    patient_medication: PatientMedicationFilter,
    patient_diagnosis: PatientDiagnosisFilter,
    observation_period: ObservationPeriodFilter,
    patient_lab: PatientLabFilter,
    patient_observation: PatientObservationFilter
}

export const DateAwareFilter: FC<Omit<FilterProps, 'filterDTO'> & { dateAwareFilterDTO: DateAwareFilterDTO_Output }> = (props) => {
    const { dateAwareFilterDTO } = props
    const FilterComponent = filterComponentMap[dateAwareFilterDTO.table] || GenericDateAwareFilter
    return <FilterComponent {...props} filterDTO={dateAwareFilterDTO} />
}
