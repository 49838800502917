/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Trans } from '@lingui/macro'
import { Typography } from '@mui/material'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { FC, useEffect } from 'react'
import { FilterContent } from '../nodes/FilterContent'
import { CARD_ICONS } from '../queryUtils'
import { FilterProps } from './Filter'
import { FilterButtons } from './FilterButtons'
import { FilterDropdownMenu } from './FilterDropdownMenu'
import { FilterHeader } from './FilterHeader'
import { GenericFilterContent } from './GenericFilterContent'
import { useFetchRefs } from './useFetchRefs'

export const PatientDiagnosisFilter: FC<FilterProps> = (props) => {
    const { filterDTO, setEditBlock, editDisabled } = props

    const { refs, fetchRefs }: { refs: Array<Record<string, any>>; fetchRefs: (filter: FilterDTO_Output | DateAwareFilterDTO_Output) => void } =
        useFetchRefs()

    useEffect(() => {
        fetchRefs(filterDTO)
    }, [filterDTO, fetchRefs])

    if (!filterDTO) {
        return null
    }

    return (
        <FilterContent {...props}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <FilterHeader
                    icon={CARD_ICONS.diagnosis}
                    title='Diagnosis'
                    additionalLabel={
                        filterDTO.field === 'boc_cui' ? (
                            filterDTO.field.endsWith('_icd10') ? (
                                <Typography fontSize={12} color='#456296'>
                                    <Trans>ICD10 + ICD9</Trans>
                                </Typography>
                            ) : (
                                <Typography fontSize={12} color='#456296'>
                                    <Trans>OM1 CODING SYSTEM</Trans>
                                </Typography>
                            )
                        ) : (
                            <Typography fontSize={12} color='#456296'>
                                <Trans>ICD10</Trans>
                            </Typography>
                        )
                    }
                />
                {editDisabled ? null : <FilterDropdownMenu onEdit={() => setEditBlock && setEditBlock({ filter: filterDTO, index: undefined })} />}
            </div>
            <GenericFilterContent {...props} refs={refs} />
            {editDisabled ? null : <FilterButtons filterDTO={filterDTO} />}
        </FilterContent>
    )
}
