/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { CohortDTO, ExplorerCohortsService } from '@om1/falcon-api'
import { ExplorerAssistantConversationService } from '@om1/falcon-api/codegen/client/services/ExplorerAssistantConversationService'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { fetchDataWithCache } from '../../../../apps/platform/src/query-utils'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'
import { ExplorerAssistantConversationState } from '../state/explorer-assistant-conversation-state'
import { Query } from './query/Query'

export type CohortQueryExplorerComponentProps = RoutedFrameworkComponentProps<
    Parameters<typeof ExplorerAssistantConversationService.indexExplorerAssistantConversationGet>[0],
    { cohortId?: string },
    ExplorerAssistantConversationState,
    typeof explorerAssistantConversationActions,
    { activeCohortId?: string; activeCohortName?: string }
>
export const CohortQueryExplorerComponent: FunctionComponent<CohortQueryExplorerComponentProps> = ({ state, actions, routing, props }) => {
    const [selectedCohort, setSelectedCohort] = useState<CohortDTO | undefined>(undefined)
    const getExplorerCohortsCohortIdGet = useCallback(async (cohortId: string) => {
        try {
            if (cohortId) {
                const data: CohortDTO | undefined = await fetchDataWithCache(
                    'explorerCohorts',
                    { cohortId: cohortId },
                    ExplorerCohortsService.getExplorerCohortsCohortIdGet
                )
                setSelectedCohort(data)
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }, [])

    useEffect(() => {
        if (routing.pathParams.cohortId) {
            getExplorerCohortsCohortIdGet(routing.pathParams.cohortId)
        }
    }, [routing.pathParams.cohortId, getExplorerCohortsCohortIdGet])

    return <Query query={selectedCohort?.query} selectedBlock={undefined} setSelectedBlock={() => {}} />
}
