/* eslint-disable string-to-lingui/missing-lingui-transformation */
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlineIcon from '@mui/icons-material/EditOutlined'
import { IconButton } from '@mui/material'
import { FollowUpLengthQualifierDTO_Output } from '@om1/falcon-api'
import React, { FC } from 'react'
import { QualifierContent } from '../nodes/QualifierContent'
import { QualifierProps } from './Qualifier'

export const FollowUpLengthQualifier: FC<
    Omit<QualifierProps, 'qualifier'> & {
        followUpLengthQualifierDTO: FollowUpLengthQualifierDTO_Output
    }
> = ({
    followUpLengthQualifierDTO,
    parent,
    onBlockClick,
    disableClick = false,
    qualifierIndex = -1,
    setEditBlock,
    selectedBlock,
    editDisabled,
    setSelectedBlock
}) => {
    const handleBlockClick = () => {
        if (onBlockClick && parent?.id) {
            onBlockClick(parent.id, qualifierIndex)
        }
    }

    const handleEditClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (setEditBlock) {
            setEditBlock({
                filter: parent,
                index: qualifierIndex
            })
        }
    }

    const qualifierText = `At least ${followUpLengthQualifierDTO.amount} ${followUpLengthQualifierDTO.units}${
        followUpLengthQualifierDTO.amount > 1 ? 's' : ''
    }`

    return (
        <QualifierContent
            parent={parent}
            onBlockClick={handleBlockClick}
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
            qualifier={followUpLengthQualifierDTO}
            setEditBlock={setEditBlock}
            selectedBlock={selectedBlock}
            setSelectedBlock={setSelectedBlock}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: '.875rem', lineHeight: '1rem' }}>{qualifierText}</div>
                    {!editDisabled && (
                        <div style={{ display: 'flex' }}>
                            <IconButton key='edit' color='primary' aria-label='Edit' sx={{ padding: 0.25 }} onClick={handleEditClick}>
                                <EditOutlineIcon fontSize='small' />
                            </IconButton>
                            <IconButton key='delete' color='primary' aria-label='Delete' sx={{ padding: 0.25 }} onClick={() => {}}>
                                <DeleteOutlineIcon fontSize='small' />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </QualifierContent>
    )
}
