import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { useUserPermissions } from '@om1/platform-authentication'
import React from 'react'

export const HomePageProfileComponent: React.FunctionComponent = () => {
    const { user, permissions } = useUserPermissions()

    return (
        <>
            <b>
                <Trans>Profile Information:</Trans>
            </b>
            {user &&
                Object.keys(user).map((value: string, index: number) => (
                    <Box data-testid={`field${index}`} key={index}>
                        <b>{value}</b>:{user[value]}
                    </Box>
                ))}
            <Box data-testid={`field-permissions`} key={'permissions'}>
                <b>
                    <Trans>permissions</Trans>
                </b>
                :{permissions.join(',')}
            </Box>
        </>
    )
}
