import { v4 as uuidv4 } from 'uuid'

import {
    CohortBlocksEditActions,
    CohortNode,
    CriteriaBase,
    CriteriaOperation,
    CriteriaReference,
    CriteriaRelationDialogOpenState,
    CriteriaRelationDialogState,
    CriteriaType,
    CriterionNode,
    DateRangeIntervalUnit,
    DateRangeOperator,
    DateRelationMetadata,
    DiagnosisCriterion,
    ExternalCohortCriterion,
    FollowUpRelationMetadata,
    isCountDistinctQualifier,
    LabTestCriterion,
    MedicationCriterion,
    OperationNode,
    ProcedureCriterion
} from '../../../state'

import { DEFAULT_DATE_FIELD } from '../../../components/edit/utils/constants'
import { CohortBlocksEditActionTypes } from './cohort-blocks-edit-actions'
import {
    CohortBlocksEditState,
    CriteriaRelationType,
    DataTypesDialogState,
    EhrNotesCriterion,
    FollowUpLengthEditDialogState,
    initialCohortBlocksEditState,
    LabNumericQualifierEditDialogState,
    ObservationCriterion,
    ObservationScoreEditDialogState,
    OccurrenceEditDialogState,
    PatientAgeEditDialogState,
    RecencyEditDialogState,
    SpecialtyEditDialogState
} from './cohort-blocks-edit-state'

export function cohortBlocksEditReducer(previousState: CohortBlocksEditState | undefined, action: CohortBlocksEditActions): CohortBlocksEditState {
    const state = previousState || initialCohortBlocksEditState

    switch (action.type) {
        case CohortBlocksEditActionTypes.RESET: {
            return initialCohortBlocksEditState
        }
        case CohortBlocksEditActionTypes.COHORT_DRAG_START: {
            return { ...state, ui: { ...state.ui, dragging: { ...state.ui.dragging, active: true, allowRelate: action.payload.allowRelate } } }
        }
        case CohortBlocksEditActionTypes.COHORT_DRAG_END: {
            return { ...state, ui: { ...state.ui, dragging: { ...state.ui.dragging, active: false, allowRelate: false } } }
        }
        case CohortBlocksEditActionTypes.COHORT_SET_LAST_SAVED_BLOCKS: {
            return { ...state, ui: { ...state.ui, lastSavedBlocks: action.payload.blocks } }
        }
        case CohortBlocksEditActionTypes.OPERATION_INSERT: {
            const oldRoot = state.tree
            let newRoot
            const insertFirst = action.payload.insertFirst
            if (action.payload.target.nodeId) {
                const targetNode = findNode(oldRoot, action.payload.target.nodeId)

                if (targetNode === undefined) {
                    return state
                }

                if (isOperationNode(targetNode)) {
                    newRoot = insertOperationAtOperation(
                        oldRoot,
                        targetNode.id,
                        {
                            id: action.payload.uuid,
                            operation: action.payload.operation,
                            children: []
                        },
                        insertFirst
                    )
                }
            } else {
                newRoot = {
                    id: action.payload.uuid,
                    operation: action.payload.operation,
                    children: []
                }
            }

            return { ...state, tree: newRoot }
        }
        case CohortBlocksEditActionTypes.OPERATION_UPDATE: {
            if (action.payload.target.nodeId) {
                const oldRoot = state.tree
                const targetNode = findNode(oldRoot, action.payload.target.nodeId)

                if (targetNode === undefined) {
                    return state
                }

                const newRoot = updateOperationNode(oldRoot, targetNode.id, action.payload.operation)
                return { ...state, tree: newRoot }
            }

            return state
        }
        case CohortBlocksEditActionTypes.EXCLUDED_UPDATE: {
            if (action.payload.target.nodeId) {
                const oldRoot = state.tree
                const targetNode = findNode(oldRoot, action.payload.target.nodeId)

                if (targetNode === undefined) {
                    return state
                }

                const newRoot = updateNodeExcluded(oldRoot, targetNode.id, action.payload.operation.excluded || false)
                return { ...state, tree: newRoot }
            }

            return state
        }
        case CohortBlocksEditActionTypes.CRITERIA_INSERT: {
            const oldRoot = state.tree
            let targetNode = findNode(oldRoot, action.payload.target.nodeId!)
            const insertFirst = action.payload.insertFirst

            if (targetNode === undefined) {
                return state
            }

            const criterion: CriterionNode = {
                id: action.payload.uuid,
                type: action.payload.criteriaType,
                filters: Array.isArray(action.payload.filters) ? action.payload.filters : [],
                dateField: DEFAULT_DATE_FIELD,
                qualifiers: []
            }

            if (action.payload.dateField) {
                criterion.dateField = action.payload.dateField
            }

            if (action.payload.criteriaType === CriteriaType.ObservationPeriod && action.payload.startDateField) {
                // @ts-ignore
                criterion.startDateField = action.payload.startDateField
            }

            if (action.payload.criteriaType === CriteriaType.ObservationPeriod && action.payload.endDateField) {
                // @ts-ignore
                criterion.endDateField = action.payload.endDateField
            }

            if (Array.isArray(action.payload.qualifiers)) {
                criterion.qualifiers = action.payload.qualifiers
            }

            let newRoot: OperationNode
            if (isOperationNode(targetNode)) {
                newRoot = insertNodeAtOperation(oldRoot, targetNode.id, criterion, undefined, insertFirst)
            } else if (isCriterionNode(targetNode) && action.payload.target.relate) {
                let { dateRelation, followUpRelation } = action.payload // only one will be non-null
                if (
                    (action.payload.criteriaType === CriteriaType.ObservationPeriod ||
                        action.payload.target.relateType === CriteriaRelationType.FollowUp) &&
                    followUpRelation === undefined
                ) {
                    followUpRelation = {}
                }
                newRoot = relateCriteriaToCriteria(oldRoot, targetNode.id, criterion, dateRelation, followUpRelation)
            } else {
                newRoot = insertNodeAtCriteria(oldRoot, targetNode.id, CriteriaOperation.OR, criterion)
            }

            if (state.ui.treeEditMode === 'import') {
                return { ...state, tree: newRoot }
            } else {
                return { ...state, tree: postProcessNodes(newRoot) }
            }
        }
        case CohortBlocksEditActionTypes.CRITERIA_UPDATE: {
            const sourceRoot = state.tree

            const { target, ...criterion } = action.payload

            const updatedRoot = updateCriterionNode(sourceRoot, target.nodeId, criterion)

            if (sourceRoot === updatedRoot) {
                return state
            }

            return { ...state, tree: updatedRoot }
        }
        case CohortBlocksEditActionTypes.CRITERIA_RELATION_UPDATE: {
            const sourceRoot = state.tree

            const updatedRoot = updateNodeRelation(sourceRoot, action.payload.target.nodeId, action.payload.relationData)

            if (sourceRoot === updatedRoot) {
                return state
            }

            return { ...state, tree: updatedRoot }
        }
        case CohortBlocksEditActionTypes.CRITERIA_COPY: {
            const oldRoot = state.tree
            const sourceNode = findNode(oldRoot, action.payload.source.nodeId)
            let relate = false
            let targetNode: CohortNode | undefined
            let insertAfterSource = false
            // If target is not provided, instead we insert the copy into the source's parent operation, immediately after the source node.
            if (action.payload.target) {
                targetNode = findNode(oldRoot, action.payload.target.nodeId!)
                if (action.payload.target.relate !== undefined) {
                    relate = action.payload.target.relate
                }
            } else {
                insertAfterSource = true
                targetNode = findParentOperator(oldRoot, action.payload.source.nodeId)
            }

            if (sourceNode === undefined || targetNode === undefined) {
                return state
            }

            const copy: CriterionNode = JSON.parse(JSON.stringify(sourceNode))
            copy.id = uuidv4()
            if (copy.reference) {
                copy.reference.criteria.id = uuidv4()
            }

            let newRoot: OperationNode
            if (isOperationNode(targetNode)) {
                newRoot = insertNodeAtOperation(oldRoot, targetNode.id, copy, insertAfterSource ? sourceNode : undefined)
            } else if (isCriterionNode(targetNode) && relate) {
                newRoot = relateCriteriaToCriteria(
                    oldRoot,
                    targetNode.id,
                    copy,
                    undefined,
                    targetNode.type === CriteriaType.ObservationPeriod ? {} : undefined
                )
            } else {
                newRoot = insertNodeAtCriteria(oldRoot, targetNode.id, CriteriaOperation.OR, copy)
            }

            return { ...state, tree: postProcessNodes(newRoot) }
        }
        case CohortBlocksEditActionTypes.CRITERIA_MOVE: {
            const root = state.tree
            let sourceNode = findNode(root, action.payload.source.nodeId)
            const targetNode = findNode(root, action.payload.target.nodeId!)

            if (sourceNode === undefined || targetNode === undefined) {
                return state
            }

            if (sourceNode === targetNode) {
                return state
            }

            let newRoot = deleteCriterionNode(root, sourceNode.id)

            if (isOperationNode(targetNode)) {
                newRoot = insertNodeAtOperation(newRoot, targetNode.id, sourceNode)
            } else if (isCriterionNode(targetNode) && isCriterionNode(sourceNode) && action.payload.target.relate) {
                newRoot = relateCriteriaToCriteria(
                    newRoot,
                    targetNode.id,
                    sourceNode,
                    undefined,
                    targetNode.type === CriteriaType.ObservationPeriod ? {} : undefined
                )
            } else {
                newRoot = insertNodeAtCriteria(newRoot, targetNode.id, CriteriaOperation.OR, sourceNode)
            }

            newRoot = postProcessNodes(newRoot)

            return { ...state, tree: newRoot }
        }
        case CohortBlocksEditActionTypes.OPERATION_DELETE: {
            const oldRoot = state.tree
            const newRoot = postProcessNodes(deleteOperationNode(oldRoot, action.payload.nodeId))

            if (newRoot === oldRoot) {
                return state
            }

            return { ...state, tree: newRoot }
        }
        case CohortBlocksEditActionTypes.CRITERIA_DELETE: {
            const oldRoot = state.tree
            const newRoot = postProcessNodes(deleteCriterionNode(oldRoot, action.payload.nodeId))

            if (newRoot === oldRoot) {
                return state
            }

            return { ...state, tree: newRoot }
        }
        case CohortBlocksEditActionTypes.CLEAN_TREE: {
            return { ...state, tree: postProcessNodes(state.tree) }
        }
        case CohortBlocksEditActionTypes.TREE_EDIT_MODE_UPDATE: {
            return { ...state, ui: { ...state.ui, treeEditMode: action.payload.treeEditMode } }
        }
        case CohortBlocksEditActionTypes.CRITERIA_DIALOG_TRIGGER: {
            return { ...state, ui: { ...state.ui, criteriaDialog: action.payload } }
        }
        case CohortBlocksEditActionTypes.REFS_QUERY_LOADING: {
            const { destination, dimension, loading } = action.payload
            return {
                ...state,
                ui: {
                    ...state.ui,
                    refsLoading: {
                        ...state.ui.refsLoading,
                        [dimension]: { ...state.ui.refsLoading[dimension], [destination]: loading }
                    }
                }
            }
        }
        case CohortBlocksEditActionTypes.REF_LABELS_MERGE: {
            const newRefLabels = action.payload
            let mergedRefLabels = { ...state.ui.refLabels }

            for (const table in newRefLabels) {
                if (!mergedRefLabels[table]) {
                    mergedRefLabels = { ...mergedRefLabels, [table]: {} }
                }
                for (const field in newRefLabels[table]) {
                    if (!mergedRefLabels[table][field]) {
                        mergedRefLabels = { ...mergedRefLabels, [table]: { ...mergedRefLabels[table], [field]: {} } }
                    }
                    for (const value in newRefLabels[table][field]) {
                        mergedRefLabels = {
                            ...mergedRefLabels,
                            [table]: {
                                ...mergedRefLabels[table],
                                [field]: { ...mergedRefLabels[table][field], [value]: newRefLabels[table][field][value] }
                            }
                        }
                    }
                }
            }

            return { ...state, ui: { ...state.ui, refLabels: mergedRefLabels } }
        }
        case CohortBlocksEditActionTypes.REF_LABELS_LOADING_SET: {
            return { ...state, ui: { ...state.ui, refLabelsLoading: action.payload.loading } }
        }
        case CohortBlocksEditActionTypes.CRITERIA_RELATION_DIALOG_TRIGGER: {
            if (action.payload.target) {
                const oldRoot = state.tree
                const targetNode = findNode(oldRoot, action.payload.target.nodeId)

                return {
                    ...state,
                    ui: {
                        ...state.ui,
                        criteriaRelationDialog: {
                            ...(action.payload as Omit<CriteriaRelationDialogOpenState, 'criteria'>),
                            criteria: targetNode as
                                | DiagnosisCriterion
                                | MedicationCriterion
                                | LabTestCriterion
                                | ProcedureCriterion
                                | ObservationCriterion
                                | EhrNotesCriterion
                                | ExternalCohortCriterion
                        }
                    }
                }
            }
            return {
                ...state,
                ui: {
                    ...state.ui,
                    criteriaRelationDialog: action.payload as CriteriaRelationDialogState
                }
            }
        }
        case CohortBlocksEditActionTypes.OCCURRENCE_EDIT_DIALOG_TRIGGER: {
            if (action.payload.target) {
                return {
                    ...state,
                    ui: {
                        ...state.ui,
                        occurenceEditDialog: { ...action.payload }
                    }
                }
            }
            return {
                ...state,
                ui: {
                    ...state.ui,
                    occurenceEditDialog: action.payload as OccurrenceEditDialogState
                }
            }
        }
        case CohortBlocksEditActionTypes.RECENCY_EDIT_DIALOG_TRIGGER: {
            if (action.payload.target) {
                return {
                    ...state,
                    ui: {
                        ...state.ui,
                        recencyEditDialog: { ...action.payload }
                    }
                }
            }
            return {
                ...state,
                ui: {
                    ...state.ui,
                    recencyEditDialog: action.payload as RecencyEditDialogState
                }
            }
        }
        case CohortBlocksEditActionTypes.SPECIALTY_EDIT_DIALOG_TRIGGER: {
            if (action.payload.target) {
                return {
                    ...state,
                    ui: {
                        ...state.ui,
                        specialtyEditDialog: { ...action.payload }
                    }
                }
            }
            return {
                ...state,
                ui: {
                    ...state.ui,
                    specialtyEditDialog: action.payload as SpecialtyEditDialogState
                }
            }
        }
        case CohortBlocksEditActionTypes.DATA_TYPES_DIALOG_TRIGGER: {
            if (action.payload.value) {
                return {
                    ...state,
                    ui: {
                        ...state.ui,
                        dataTypeDialog: { ...action.payload }
                    }
                }
            }
            return {
                ...state,
                ui: {
                    ...state.ui,
                    dataTypeDialog: action.payload as DataTypesDialogState
                }
            }
        }
        case CohortBlocksEditActionTypes.PATIENT_AGE_EDIT_DIALOG_TRIGGER: {
            if (action.payload.target) {
                return {
                    ...state,
                    ui: {
                        ...state.ui,
                        patientAgeEditDialog: { ...action.payload }
                    }
                }
            }
            return {
                ...state,
                ui: {
                    ...state.ui,
                    patientAgeEditDialog: action.payload as PatientAgeEditDialogState
                }
            }
        }
        case CohortBlocksEditActionTypes.FOLLOW_UP_LENGTH_EDIT_DIALOG_TRIGGER: {
            if (action.payload.target) {
                return {
                    ...state,
                    ui: {
                        ...state.ui,
                        followUpLengthEditDialog: { ...action.payload }
                    }
                }
            }
            return {
                ...state,
                ui: {
                    ...state.ui,
                    followUpLengthEditDialog: action.payload as FollowUpLengthEditDialogState
                }
            }
        }
        case CohortBlocksEditActionTypes.OBSERVATION_SCORE_EDIT_DIALOG_TRIGGER: {
            if (action.payload.target) {
                return {
                    ...state,
                    ui: {
                        ...state.ui,
                        observationScoreEditDialog: { ...action.payload }
                    }
                }
            }
            return {
                ...state,
                ui: {
                    ...state.ui,
                    observationScoreEditDialog: action.payload as ObservationScoreEditDialogState
                }
            }
        }
        case CohortBlocksEditActionTypes.LAB_NUMERIC_QUALIFIER_EDIT_DIALOG_TRIGGER: {
            if (action.payload.target) {
                return { ...state, ui: { ...state.ui, labNumericQualifierEditDialog: { ...action.payload } } }
            }
            return {
                ...state,
                ui: {
                    ...state.ui,
                    labNumericQualifierEditDialog: action.payload as LabNumericQualifierEditDialogState
                }
            }
        }

        default:
            return state
    }
}

/* Tree Operations */

/**
 * Finds a node by id within a tree of nodes
 *
 * @param node The root node of the tree
 * @param targetId The node id we are trying to find
 * @returns A node if found, undefined if not
 */
export function findNode(node: CohortNode, targetId: string): CohortNode | undefined {
    if (node.id === targetId) {
        return node
    } else if (isCriterionNode(node) && node.reference?.criteria.id === targetId) {
        return node.reference.criteria
    } else if (isOperationNode(node)) {
        return node.children.map((childNode) => findNode(childNode, targetId)).find((childNode) => childNode !== undefined)
    }
}

export function findNodeByType(node: CohortNode, type: CriteriaType): CriterionNode | undefined {
    if (isCriterionNode(node) && node.type === type) {
        return node
    } else if (isOperationNode(node)) {
        return node.children.map((childNode) => findNodeByType(childNode, type)).find((childNode) => childNode !== undefined)
    }
}

/**
 * Find the parent node of a child node
 *
 * @param node The root node of the tree
 * @param targetId The child node id
 * @returns A node if a parent node of the passed in child node's id is found, undefined if not
 */
export function findParentOperator(node: OperationNode, targetId: string): OperationNode | undefined {
    for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i]
        if (isCriterionNode(child)) {
            if (child.id === targetId || (child.reference && child.reference.criteria.id === targetId)) {
                return node
            }
        } else if (isOperationNode(child)) {
            const foundNode = findParentOperator(child, targetId)
            if (foundNode) {
                return foundNode
            }
        }
    }
}

/**
 * Inserts an operation into an existing operation.
 *
 * @example
 *
 * We want to insert operation C into an AND operation node that already contains [A, B] (e.g. A & B).
 * After, the insertion operation, we will have a an AND operation node that contains [A, B, C] (e.g. A & B & C)
 *
 * @param node The root node of the tree
 * @param targetId The node id of the operation node to insert into
 * @param insertionNode The operation node to insert
 * @returns If an insertion was unsuccesful, the original reference to the root node. Otherwise, the new root node.
 */
function insertOperationAtOperation(node: OperationNode, targetId: string, insertionNode: OperationNode, insertFirst?: boolean): OperationNode {
    if (isOperationNode(node)) {
        if (node.id === targetId) {
            const children = insertFirst ? [insertionNode, ...node.children] : [...node.children, insertionNode]
            node = { ...node, children }
        } else {
            let success = false
            for (let i = 0; i < node.children.length && !success; i++) {
                const childNode = node.children[i]

                if (isOperationNode(childNode)) {
                    const recursedNode = insertOperationAtOperation(childNode, targetId, insertionNode, insertFirst)
                    if (recursedNode !== childNode) {
                        success = true
                        const children = [...node.children]
                        children[i] = recursedNode
                        node = { ...node, children }
                    }
                }
            }
        }
    }
    return node
}

/**
 * Inserts a node into an existing operation.
 *
 * @example
 *
 * We want to insert node C into an AND operation node that already contains [A, B] (e.g. A & B).
 * After, the insertion operation, we will have a an AND operation node that contains [A, B, C] (e.g. A & B & C)
 *
 * @param node The root node of the tree
 * @param targetId The node id of the operation node to insert into
 * @param insertionNode The node to insert
 * @param afterNode If provided, the insertion node will be added after this node in the operation's children
 * @returns If an insertion was unsuccesful, the original reference to the root node. Otherwise, the new root node.
 */
export function insertNodeAtOperation(
    node: OperationNode,
    targetId: string,
    insertionNode: CohortNode,
    afterNode?: CohortNode,
    insertFirst?: boolean
): OperationNode {
    if (isOperationNode(node)) {
        if (node.id === targetId) {
            let children = [...node.children]
            let insertionIndex = insertFirst ? 0 : children.length
            if (afterNode) {
                // If we are supposed to insert the new node "after" another in the tree, look for either a subject or a
                // reference node in the three that may match the "after" node. In the case that it is a reference, we
                // will insert it after the subject it is a part of instead.
                const foundSubjectNode = children.find((child) => {
                    if (isCriterionNode(child)) {
                        if (child.id === afterNode.id || (child.reference && child.reference.criteria.id === afterNode.id)) {
                            return child
                        }
                    }
                    return undefined
                })
                if (foundSubjectNode) {
                    insertionIndex = children.indexOf(foundSubjectNode) + 1
                }
            }
            children.splice(insertionIndex, 0, insertionNode)
            node = { ...node, children }
        } else {
            let success = false
            for (let i = 0; i < node.children.length && !success; i++) {
                const childNode = node.children[i]

                if (isOperationNode(childNode)) {
                    const recursedNode = insertNodeAtOperation(childNode, targetId, insertionNode, afterNode, insertFirst)
                    if (recursedNode !== childNode) {
                        success = true
                        const children = [...node.children]
                        children[i] = recursedNode
                        node = { ...node, children }
                    }
                }
            }
        }
    }
    return node
}

/**
 * Inserts a node at criteria node. Since two nodes can not exist at the same location, work fork at the target node,
 * replace it with an operation node that is the opposite of the closets parent operation, and both the target and the
 * new node are made its children.
 *
 * @example
 *
 * We have the tree A & B, and we want to OR A with a new node, C.
 *
 * @param node The root node of the tree
 * @param targetId The node id of the criteria we want to fork at
 * @param lastSeenOperation The last seen operation node's operation. Seed with the initial operation that would be above the root node.
 * @param insertionNode The node to insert
 * @returns If an insertion was unsuccesful, the original reference to the root node. Otherwise, the new root node.
 */
export function insertNodeAtCriteria(
    node: OperationNode,
    targetId: string,
    lastSeenOperation: CriteriaOperation,
    insertionNode: CohortNode
): OperationNode {
    if (isOperationNode(node)) {
        let success = false
        if (node.operation === CriteriaOperation.AND || node.operation === CriteriaOperation.OR) {
            lastSeenOperation = node.operation
        }
        for (let i = 0; i < node.children.length && !success; i++) {
            const childNode = node.children[i]

            if (childNode.id === targetId) {
                success = true
                const children = [...node.children]
                const operationId = uuidv4()
                children[i] = {
                    id: operationId,
                    operation: lastSeenOperation === CriteriaOperation.AND ? CriteriaOperation.OR : CriteriaOperation.AND,
                    children: [children[i], insertionNode]
                }
                node = { ...node, children }
            } else if (isOperationNode(childNode)) {
                if (node.operation === CriteriaOperation.AND || node.operation === CriteriaOperation.OR) {
                    lastSeenOperation = node.operation
                }
                const recursedNode = insertNodeAtCriteria(childNode, targetId, lastSeenOperation, insertionNode)
                if (recursedNode !== childNode) {
                    success = true
                    const children = [...node.children]
                    children[i] = recursedNode
                    node = { ...node, children }
                }
            }
        }
    }
    return node
}

/**
 * Inserts a node at criteria node. Since two nodes can not exist at the same location, work fork at the target node,
 * replace it with an operation node that is the opposite of the closets parent operation, and both the target and the
 * new node are made its children.
 *
 * @example
 *
 * We have the tree A & B, and we want to OR A with a new node, C.
 *
 * @param node The root node of the tree
 * @param targetId The node id of the criteria we want to fork at
 * @param lastSeenOperation The last seen operation node's operation. Seed with the initial operation that would be above the root node.
 * @param referenceNode The node to insert
 * @returns If an insertion was unsuccesful, the original reference to the root node. Otherwise, the new root node.
 */
function relateCriteriaToCriteria(
    node: OperationNode,
    targetId: string,
    referenceNode: CriterionNode,
    dateRelation?: DateRelationMetadata,
    followUpRelation?: FollowUpRelationMetadata
): OperationNode {
    if (isOperationNode(node)) {
        let success = false
        for (let i = 0; i < node.children.length && !success; i++) {
            const childNode = node.children[i]

            if (childNode.id === targetId) {
                success = true
                const children = [...node.children]

                let relationToInsert
                if (followUpRelation) {
                    const hasFollowUp = followUpRelation?.followUp !== undefined && followUpRelation?.followUp !== null
                    const hasBaseline = followUpRelation?.baseline !== undefined && followUpRelation?.baseline !== null
                    const hasEitherFollowUpOrBaseline = hasFollowUp || hasBaseline
                    if (hasEitherFollowUpOrBaseline) {
                        relationToInsert = {
                            followUpRelation: followUpRelation
                        }
                    } else {
                        relationToInsert = {
                            followUpRelation: {
                                baseline: hasBaseline
                                    ? followUpRelation?.baseline
                                    : {
                                          dateRangeOperator: DateRangeOperator.Before,
                                          intervalStartFromReferenceDate: 90,
                                          intervalEndFromReferenceDate: undefined,
                                          intervalUnitFromReferenceDate: DateRangeIntervalUnit.Day
                                      },
                                followUp: hasFollowUp
                                    ? followUpRelation?.followUp
                                    : {
                                          dateRangeOperator: DateRangeOperator.After,
                                          intervalStartFromReferenceDate: 90,
                                          intervalEndFromReferenceDate: undefined,
                                          intervalUnitFromReferenceDate: DateRangeIntervalUnit.Day
                                      }
                            }
                        }
                    }
                } else {
                    relationToInsert = {
                        dateRelation: dateRelation || {
                            dateRangeOperator: DateRangeOperator.After,
                            intervalStartFromReferenceDate: 30,
                            intervalEndFromReferenceDate: undefined,
                            intervalUnitFromReferenceDate: DateRangeIntervalUnit.Day,
                            intervalIsInclusive: false
                        }
                    }
                }

                children[i] = {
                    ...children[i],
                    reference: {
                        criteria: referenceNode,
                        ...relationToInsert
                    }
                }
                node = { ...node, children }
            } else if (isOperationNode(childNode)) {
                const recursedNode = relateCriteriaToCriteria(childNode, targetId, referenceNode, dateRelation, followUpRelation)
                if (recursedNode !== childNode) {
                    success = true
                    const children = [...node.children]
                    children[i] = recursedNode
                    node = { ...node, children }
                }
            }
        }
    }
    return node
}

/**
 * Deletes a node by id within a tree of nodes
 *
 * @param node The root node of the tree
 * @param targetId The node id we are trying to delete
 * @returns If a delete was unsuccesful, the original reference to the root node. Otherwise, the new root node.
 */
function deleteCriterionNode(node: OperationNode, targetId: string): OperationNode {
    if (isOperationNode(node)) {
        let success = false
        for (let i = 0; i < node.children.length && !success; i++) {
            const childNode = node.children[i]

            if (isCriterionNode(childNode)) {
                if (childNode.id === targetId) {
                    success = true
                    const children = [...node.children]
                    children.splice(i, 1)
                    node = { ...node, children }
                } else if (childNode.reference && childNode.reference.criteria.id === targetId) {
                    success = true
                    const children = [...node.children]
                    const { reference, ...newChild } = childNode
                    children[i] = newChild
                    node = { ...node, children }
                }
            } else if (isOperationNode(childNode)) {
                const recursedNode = deleteCriterionNode(childNode, targetId)
                if (childNode !== recursedNode) {
                    success = true
                    const children = [...node.children]
                    children[i] = recursedNode
                    node = { ...node, children }
                }
            }
        }
    }
    return node
}

/**
 * Deletes an operation node by id within a tree of nodes
 *
 * @param node The root node of the tree
 * @param targetId The node id we are trying to delete
 * @returns If a delete was unsuccesful, the original reference to the root node. Otherwise, the new root node.
 */
function deleteOperationNode(node: OperationNode, targetId: string): OperationNode {
    if (isOperationNode(node)) {
        let success = false
        for (let i = 0; i < node.children.length && !success; i++) {
            const childNode = node.children[i]
            if (isOperationNode(childNode) && childNode.id === targetId) {
                success = true
                const children = [...node.children]
                children.splice(i, 1)
                node = { ...node, children }
            } else if (isOperationNode(childNode)) {
                const recursedNode = deleteOperationNode(childNode, targetId)
                if (childNode !== recursedNode) {
                    success = true
                    const children = [...node.children]
                    children[i] = recursedNode
                    node = { ...node, children }
                }
            }
        }
    }
    return node
}

/**
 * Updates a criterion node by id within a tree of nodes
 *
 * @param node The root node of the tree
 * @param targetId The node id we are trying to update
 * @returns If an update was unsuccesful, the original reference to the root node. Otherwise, the updated root node.
 */
function updateCriterionNode(
    node: OperationNode,
    targetId: string,
    payload: Partial<Pick<CriteriaBase, 'filters' | 'qualifiers' | 'dateField'>>
): OperationNode {
    if (isOperationNode(node)) {
        let success = false
        for (let i = 0; i < node.children.length && !success; i++) {
            const childNode = node.children[i]

            if (!isOperationNode(childNode)) {
                if (childNode.id === targetId) {
                    success = true
                    const children = [...node.children]
                    children[i] = { ...childNode, ...payload }
                    node = { ...node, children }
                } else if (childNode.reference && childNode.reference.criteria.id === targetId) {
                    success = true
                    const children = [...node.children]
                    children[i] = { ...childNode, reference: { ...childNode.reference, criteria: { ...childNode.reference.criteria, ...payload } } }
                    node = { ...node, children }
                }
            } else {
                const recursedNode = updateCriterionNode(childNode, targetId, payload)
                if (childNode !== recursedNode) {
                    success = true
                    const children = [...node.children]
                    children[i] = recursedNode
                    node = { ...node, children }
                }
            }
        }
    }
    return node
}

/**
 * Updates an operation node by id within a tree of nodes
 *
 * @param node The root node of the tree
 * @param targetId The node id we are trying to update
 * @returns If an update was unsuccesful, the original reference to the root node. Otherwise, the updated root node.
 */
function updateOperationNode(node: OperationNode, targetId: string, payload: Partial<Pick<OperationNode, 'name'>>): OperationNode {
    if (node.id === targetId) {
        node = { ...node, ...payload }
    } else {
        let success = false
        for (let i = 0; i < node.children.length && !success; i++) {
            const childNode = node.children[i]

            if (isOperationNode(childNode)) {
                const recursedNode = updateOperationNode(childNode, targetId, payload)
                if (childNode !== recursedNode) {
                    success = true
                    const children = [...node.children]
                    children[i] = recursedNode
                    node = { ...node, children }
                }
            }
        }
    }
    return node
}

/**
 * Updates an operation node by id within a tree of nodes
 *
 * @param node The root node of the tree
 * @param targetId The node id we are trying to update
 * @returns If an update was unsuccesful, the original reference to the root node. Otherwise, the updated root node.
 */
function updateNodeExcluded(node: OperationNode, targetId: string, excluded: boolean): OperationNode {
    if (node.id === targetId) {
        node = { ...node, excluded: excluded }
    } else {
        let success = false
        for (let i = 0; i < node.children.length && !success; i++) {
            const childNode = node.children[i]

            if (isOperationNode(childNode)) {
                const recursedNode = updateNodeExcluded(childNode, targetId, excluded)
                if (childNode !== recursedNode) {
                    success = true
                    const children = [...node.children]
                    children[i] = recursedNode
                    node = { ...node, children }
                }
            }
        }
    }
    return node
}

/**
 * Updates an operation node by id within a tree of nodes
 *
 * @param node The root node of the tree
 * @param targetId The node id we are trying to update
 * @returns If an update was unsuccesful, the original reference to the root node. Otherwise, the updated root node.
 */
export function updateNodeOperation(node: OperationNode, targetId: string, operation: CriteriaOperation): OperationNode {
    if (node.id === targetId) {
        node = { ...node, operation: operation }
    } else {
        let success = false
        for (let i = 0; i < node.children.length && !success; i++) {
            const childNode = node.children[i]

            if (isOperationNode(childNode)) {
                const recursedNode = updateNodeOperation(childNode, targetId, operation)
                if (childNode !== recursedNode) {
                    success = true
                    const children = [...node.children]
                    children[i] = recursedNode
                    node = { ...node, children }
                }
            }
        }
    }
    return node
}

/**
 * Updates a node's relation by id within a tree of nodes
 *
 * @param node The root node of the tree
 * @param targetId The node id we are trying to update
 * @returns If an update was unsuccesful, the original reference to the root node. Otherwise, the updated root node.
 */
function updateNodeRelation(node: OperationNode, targetId: string, payload: Omit<CriteriaReference, 'criteria'>): OperationNode {
    if (isOperationNode(node)) {
        let success = false

        for (let i = 0; i < node.children.length && !success; i++) {
            const childNode = node.children[i]

            if (!isOperationNode(childNode)) {
                if (childNode.reference && childNode.id === targetId) {
                    success = true
                    const children = [...node.children]
                    children[i] = { ...childNode, reference: { ...childNode.reference, ...payload } }
                    node = { ...node, children }
                }
            } else {
                const recursedNode = updateNodeRelation(childNode, targetId, payload)
                if (childNode !== recursedNode) {
                    success = true
                    const children = [...node.children]
                    children[i] = recursedNode
                    node = { ...node, children }
                }
            }
        }
    }

    return node
}

/**
 * A cleanup function to run on the tree after adding / removing / moving items to ensure the tree is in a clean and
 * valid state.
 *
 * @param node The root node of the tree
 * @returns The root node of the tree with all post-processing functions applied
 */
export function postProcessNodes(node: OperationNode) {
    return ensureValidDateFields(collapseRedundantOperators(clearEmptyNodes(node)))
}

/**
 * Finds any parent / child nodes that share the same operator and collapse them into a single node.
 *
 * @param node The root node of the tree
 * @returns The root node of the tree, with all redundant operator nodes collapsed.
 */
function collapseRedundantOperators(node: OperationNode) {
    if (isOperationNode(node)) {
        for (let i = 0; i < node.children.length; i++) {
            const childNode = node.children[i]
            if (isOperationNode(childNode)) {
                if (childNode.operation === node.operation) {
                    const start = node.children.slice(0, i)
                    const middle = childNode.children
                    const end = node.children.slice(i + 1)
                    const children = [...start, ...middle, ...end]
                    node = { ...node, children }
                } else {
                    const recursedNode = collapseRedundantOperators(childNode)
                    if (childNode !== recursedNode) {
                        const children = [...node.children]
                        children[i] = recursedNode
                        node = { ...node, children }
                    }
                }
            }
        }
    }
    return node
}

/**
 * Collapses empty operators that may exist in the tree
 *
 * @param node The root node of the tree
 * @param depth The depth at which the tree is being visited
 * @returns The root node of the tree, with all empty operators and collapsed / removed
 */
function clearEmptyNodes(node: OperationNode, depth: number = 0): OperationNode {
    // Only clear nodes that aren't the parent "except", the two default "and"s, or their direct children
    const MIN_CLEAR_DEPTH = 2
    if (isOperationNode(node)) {
        let success = false
        for (let i = 0; i < node.children.length && !success; i++) {
            const childNode = node.children[i]
            if (isOperationNode(childNode)) {
                if (childNode.children.length === 0 && depth + 1 > MIN_CLEAR_DEPTH) {
                    success = true
                    const children = [...node.children]
                    children.splice(i, 1)
                    node = { ...node, children }
                } else if (childNode.children.length === 1 && depth + 1 > MIN_CLEAR_DEPTH) {
                    success = true
                    const children = [...node.children]
                    children[i] = childNode.children[0]
                    node = { ...node, children }
                } else {
                    const recursedNode = clearEmptyNodes(childNode, depth + 1)
                    if (childNode !== recursedNode) {
                        success = true
                        const children = [...node.children]
                        children[i] = recursedNode
                        node = { ...node, children }
                    }
                }
            }
        }
    }
    return node
}

/**
 * Ensures that dates and qualifiers are in valid states. Allows potentially invalid operations in the tree to occur to
 * reduce the amount of business logic needed while actually editing the tree. Any invalid states for dates / qualifiers
 * will be caught in a single function and handled.
 *
 * @param node The root node of the tree
 * @returns The root node of the tree, with date fields and qualifiers validated
 */
function ensureValidDateFields(node: OperationNode): OperationNode {
    if (isOperationNode(node)) {
        for (let i = 0; i < node.children.length; i++) {
            const childNode = node.children[i]

            if (!isOperationNode(childNode)) {
                if (childNode.reference) {
                    // When relating two criteria, they can not both have an 'any' date. We make the decision to reset
                    // the relating / reference criteria to a 'first' date in this instance.
                    if (childNode.dateField === 'any' && childNode.reference.criteria.dateField === 'any') {
                        const children = [...node.children]
                        children[i] = {
                            ...childNode,
                            reference: {
                                ...childNode.reference,
                                criteria: {
                                    ...childNode.reference.criteria,
                                    dateField: 'first',
                                    qualifiers: [...childNode.reference.criteria.qualifiers.filter((q) => !isCountDistinctQualifier(q))]
                                }
                            }
                        }
                        node = { ...node, children }
                    }
                }
            } else {
                const recursedNode = ensureValidDateFields(childNode)
                if (childNode !== recursedNode) {
                    const children = [...node.children]
                    children[i] = recursedNode
                    node = { ...node, children }
                }
            }
        }
    }
    return node
}

function isOperationNode(node?: CohortNode): node is OperationNode {
    return node !== undefined && (node as OperationNode).operation !== undefined
}

function isCriterionNode(node?: CohortNode): node is CriterionNode {
    return node !== undefined && (node as CriterionNode).type !== undefined
}
