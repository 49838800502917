import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import { OrganizationMetadataCreateDTO, OrganizationMetadataPatchDTO } from '@om1/falcon-api'
import { useUserPermissions } from '@om1/platform-authentication'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { dataDeliveryActions, OrganizationMetadataState } from '../state/data-delivery'

type OrganizationMetadataFormValues = {
    share: string
    database: string
}

export type OrganizationMetadataSaveValues = OrganizationMetadataFormValues & { id?: string }

export type CreateEditOrganizationMetadataDialogProps = RoutedFrameworkComponentProps<
    {},
    {},
    { organizationMetadata: OrganizationMetadataState },
    Pick<typeof dataDeliveryActions, 'getOrganizationMetadata' | 'createOrganizationMetadata' | 'updateOrganizationMetadata'>,
    {
        onCancel: () => void
        onSave: () => void
    }
>

/**
 * A dialog to edit the metadata fields for an organization's data delivery.
 */
export const CreateEditOrganizationMetadataDialogComponent = ({
    state,
    props: { onCancel, onSave },
    actions
}: CreateEditOrganizationMetadataDialogProps) => {
    const { organizationMetadata } = state
    const { user } = useUserPermissions()
    const validationSchema = Yup.object().shape({
        share: Yup.string().required(t`Share is required`),
        database: Yup.string().required(t`Database is required`)
    })

    const handleCancel = () => onCancel()
    const onSubmit: SubmitHandler<OrganizationMetadataFormValues> = (formOrganizationMetadata: OrganizationMetadataFormValues) => {
        if (organizationMetadata.metadata.id) {
            const organizationMetadataPatchDTO: OrganizationMetadataPatchDTO = {
                deliveryDatabase: formOrganizationMetadata.database,
                deliveryShareName: formOrganizationMetadata.share
            }
            actions.updateOrganizationMetadata({ organizationMetadataId: organizationMetadata.metadata.id, dto: organizationMetadataPatchDTO })
        } else {
            if (user && user.org_id) {
                const organizationMetadataCreateDTO: OrganizationMetadataCreateDTO = {
                    organizationId: user.org_id,
                    deliveryDatabase: formOrganizationMetadata.database,
                    deliveryShareName: formOrganizationMetadata.share
                }
                actions.createOrganizationMetadata(organizationMetadataCreateDTO)
            }
        }
        onSave()
    }

    const { handleSubmit, control } = useForm<OrganizationMetadataFormValues>({
        values: {
            share: organizationMetadata.metadata.deliveryShareName || '',
            database: organizationMetadata.metadata.deliveryDatabase || ''
        },
        defaultValues: {
            share: organizationMetadata.metadata.deliveryShareName || '',
            database: organizationMetadata.metadata.deliveryDatabase || ''
        },
        resolver: yupResolver(validationSchema)
    })

    return (
        <Dialog open maxWidth='sm' fullWidth onClose={handleCancel}>
            <DialogTitle>
                <Trans>Data Studio Configuration</Trans>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} data-testid='form'>
                <DialogContent>
                    <Box>
                        <Stack spacing={3}>
                            <Controller
                                control={control}
                                name='share'
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        id='share'
                                        label={<Trans>Share</Trans>}
                                        required
                                        disabled={organizationMetadata.isLoading}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name='database'
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        id='database'
                                        label={<Trans>Database</Trans>}
                                        required
                                        disabled={organizationMetadata.isLoading}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={handleCancel} disabled={organizationMetadata.isLoading}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit' disabled={organizationMetadata.isLoading}>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
