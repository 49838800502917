import { FC } from 'react'
import { FilterProps } from './Filter'
import { GenericPatientFilter } from './GenericPatientFilter'

export const PatientEncounterFilter: FC<FilterProps> = (props) => {
    const { filterDTO } = props

    if (!filterDTO) {
        return null
    }

    return <GenericPatientFilter {...props} />
}
