/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { FC } from 'react'
import { FilterContent } from '../nodes/FilterContent'
import { CARD_ICONS } from '../queryUtils'
import { FilterHeader } from './FilterHeader'

import { FilterProps } from './Filter'
import { FilterDropdownMenu } from './FilterDropdownMenu'
import { GenericPatientFilter } from './GenericPatientFilter'

export const PatientFilter: FC<FilterProps> = (props) => {
    const { filterDTO, setEditBlock, editDisabled } = props

    if (!filterDTO) {
        return null
    }

    if (filterDTO.field === 'has_labs') {
        return (
            <FilterContent {...props}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <FilterHeader icon={CARD_ICONS.lab} title='Lab Tests' />
                    {editDisabled ? null : (
                        <FilterDropdownMenu onEdit={() => setEditBlock && setEditBlock({ filter: filterDTO, index: undefined })} />
                    )}
                </div>
                <div style={{ marginBottom: '0.5rem', fontSize: '0.875rem' }}>Any</div>
            </FilterContent>
        )
    } else {
        return <GenericPatientFilter {...props} />
    }
}
