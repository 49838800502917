/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { faSliders, faUserDoctor } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined'
import MoreTime from '@mui/icons-material/MoreTime'
import { Button } from '@mui/material'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { capitalizeFirstLetter } from '../qualifiers/CountDistinctQualifier'
import { QualifierTypes } from '../qualifiers/Qualifier'
import { FilterTypes } from '../QueryBlock'

export const FilterButtons = (props: { filterDTO: FilterDTO_Output | DateAwareFilterDTO_Output }) => {
    const { filterDTO } = props
    const selectedBlockType = capitalizeFirstLetter((filterDTO as FilterTypes)?.table?.replace('patient_', '') ?? 'Patient Attributes')
    const faded = 0.38
    const full = 1
    return (
        <>
            {selectedBlockType !== 'External Cohort' && (
                <Button
                    disabled={Boolean(filterDTO.disabled)}
                    onClick={(event) => {
                        event.stopPropagation()
                        console.log('clicked')
                    }}
                    component='span'
                    style={{
                        width: 40,
                        height: 40,
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'primary.main',
                        borderRadius: '5rem',
                        opacity:
                            (filterDTO.qualifiers || ([] as unknown as QualifierTypes[])).filter((qualifier) => qualifier.type === 'DateQualifierDTO')
                                .length > 0
                                ? faded
                                : full
                    }}
                >
                    <CalendarMonthOutlinedIcon />
                </Button>
            )}
            {selectedBlockType !== 'Observation Period' && selectedBlockType !== 'External Cohort' && (
                <Button
                    disabled={Boolean(filterDTO.disabled)}
                    onClick={(event) => {
                        event.stopPropagation()
                        console.log('clicked')
                    }}
                    component='span'
                    style={{
                        width: 40,
                        height: 40,
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'primary.main',
                        borderRadius: '5rem',
                        opacity:
                            (filterDTO.qualifiers || ([] as unknown as QualifierTypes[])).filter(
                                (qualifier) => qualifier.type === 'PatientAgeQualifierDTO'
                            ).length > 0
                                ? faded
                                : full
                    }}
                >
                    <FamilyRestroomOutlinedIcon />
                </Button>
            )}
            {selectedBlockType === 'Observation Period' ||
                (selectedBlockType === 'Observation' && (
                    <Button
                        disabled={Boolean(filterDTO.disabled)}
                        onClick={(event) => {
                            event.stopPropagation()
                            console.log('clicked')
                        }}
                        component='span'
                        sx={{
                            width: 40,
                            height: 40,
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'primary.main',
                            borderRadius: '5rem',
                            opacity:
                                (filterDTO.qualifiers || ([] as unknown as QualifierTypes[])).filter(
                                    (qualifier) => qualifier.type === 'FollowUpLengthQualifier'
                                ).length > 0
                                    ? faded
                                    : full
                        }}
                    >
                        <FontAwesomeIcon icon={faSliders} width={24} height={24} style={{ marginBottom: '-2px' }} />
                    </Button>
                ))}
            {selectedBlockType === 'Note' && (
                <Button
                    disabled={Boolean(filterDTO.disabled)}
                    onClick={(event) => {
                        event.stopPropagation()
                        console.log('clicked')
                    }}
                    component='span'
                    sx={{
                        width: 40,
                        height: 40,
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'primary.main',
                        borderRadius: '5rem',
                        opacity:
                            (filterDTO.qualifiers || ([] as unknown as QualifierTypes[])).filter(
                                (qualifier) => qualifier.type === 'SpecialtyQualifierDTO'
                            ).length > 0
                                ? faded
                                : full
                    }}
                >
                    <FontAwesomeIcon icon={faUserDoctor} width={24} height={24} style={{ marginBottom: '-2px' }} />
                </Button>
            )}
            {selectedBlockType === 'Observation Period' && (
                <Button
                    disabled={Boolean(filterDTO.disabled)}
                    onClick={(event) => {
                        event.stopPropagation()
                        console.log('clicked')
                    }}
                    component='span'
                    sx={{
                        width: 40,
                        height: 40,
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'primary.main',
                        borderRadius: '5rem',
                        opacity:
                            (filterDTO.qualifiers || ([] as unknown as QualifierTypes[])).filter(
                                (qualifier) => qualifier.type === 'SpecialtyQualifierDTO'
                            ).length > 0
                                ? faded
                                : full
                    }}
                >
                    <MoreTime />
                </Button>
            )}
        </>
    )
}
