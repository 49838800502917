/* eslint-disable string-to-lingui/missing-lingui-transformation */
/* eslint-disable string-to-lingui/text-restrictions */
import {
    CountDistinctQualifierDTO_Output,
    DateAwareFilterDTO_Output,
    DateQualifierDTO_Output,
    FilterDTO_Output,
    FilterQualifierDTO_Output,
    FollowUpLengthQualifierDTO_Output,
    PatientAgeQualifierDTO_Output
} from '@om1/falcon-api'
import { SpecialtyQualifierDTO_Output } from '@om1/falcon-api/codegen/client/models/SpecialtyQualifierDTO_Output'
import React, { FC, ReactNode } from 'react'
import { SelectedBlockProps } from '../../../views/ChatView'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps } from '../QueryBlock'

export type QualifierContentProps = {
    children: ReactNode
    qualifier:
        | SpecialtyQualifierDTO_Output
        | PatientAgeQualifierDTO_Output
        | FollowUpLengthQualifierDTO_Output
        | FilterQualifierDTO_Output
        | DateQualifierDTO_Output
        | CountDistinctQualifierDTO_Output
    parent: FilterDTO_Output | DateAwareFilterDTO_Output
    onDelete?: () => void
    onEdit?: () => void
    disableClick?: boolean
    qualifierIndex: number
} & EditQueryBlockProps &
    BlockClickProps &
    Omit<SelectedBlockProps, 'setSelectedBlockId'>

const styles = {
    container: {
        backgroundColor: 'transparent',
        width: '100%'
    },
    content: (isParentSelected: boolean, isParentRelativeDateFilter: boolean, parentDisabled: boolean, clickable: boolean) => ({
        zIndex: 50,
        width: '100%',
        height: '100%',
        marginTop: '0.25rem',
        overflow: 'hidden',
        position: 'relative',
        borderWidth: isParentSelected ? '5px' : '',
        padding: '0.25rem',
        opacity: parentDisabled ? 0.25 : 1,
        backgroundColor: '#EEEEEE',
        borderColor: isParentRelativeDateFilter ? '#e9d8fd' : isParentSelected ? 'transparent' : 'transparent',
        border: isParentSelected ? (isParentRelativeDateFilter ? '1px dashed gray' : '1px dashed transparent') : '1px solid transparent',
        cursor: clickable ? 'pointer' : 'default'
    }),
    iconButton: {
        position: 'absolute',
        top: '50%',
        right: '0.5rem',
        transform: 'translateY(-50%)'
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    flexChild: {
        flex: 1,
        transition: 'all 0.3s'
    }
}

export const QualifierContent: FC<QualifierContentProps> = (props) => {
    const { children, parent, onBlockClick, selectedBlock, disableClick = false, qualifierIndex } = props
    const clickable = !parent.disabled && !disableClick
    const isParentSelected = Boolean(parent.id && parent.id === selectedBlock?.id)
    const isParentRelativeDateFilter = parent.type === 'RelativeDateFilterDTO'

    const handleBlockClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (clickable && onBlockClick && parent.id) {
            event.stopPropagation()
            onBlockClick(Number(parent.id), qualifierIndex)
        }
    }

    return (
        <>
            <style>
                {`
                    @property --angle {
                        syntax: '<angle>';
                        initial-value: 0deg;
                        inherits: false;
                    }

                    @keyframes rotate {
                        to {
                            --angle: 360deg;
                        }
                    }

                    .animated-border {
                        position: relative;
                        overflow: hidden;
                    }

                    .animated-border::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: inherit;
                        padding: 2px;
                        background: linear-gradient(var(--angle), #0000ff, #0000b3);
                        -webkit-mask:
                            linear-gradient(#fff 0 0) content-box,
                            linear-gradient(#fff 0 0);
                        mask-composite: exclude;
                        animation: rotate 3s linear infinite;
                        pointer-events: none;
                    }
                `}
            </style>
            <div style={styles.container}>
                <div
                    style={styles.content(isParentSelected, isParentRelativeDateFilter, Boolean(parent.disabled), clickable) as React.CSSProperties}
                    onClick={handleBlockClick}
                >
                    <div style={styles.flexContainer}>
                        <div style={styles.flexChild}>{children}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
