/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateQualifierDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { QueryNode } from '../nodes/QueryNode'
import { Qualifier } from '../qualifiers/Qualifier'
import { FilterProps } from './Filter'

export const GenericPatientFilter: FC<FilterProps> = (props) => {
    const { filterDTO, depth } = props
    if (!filterDTO) return null

    const renderField = (label: string, value: string | string[]) => (
        <>
            <span style={{ color: '#4B5563' }}>{label}:</span>
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '10rem' }}>
                {Array.isArray(value) ? value.join(', ') : value}
            </span>
        </>
    )

    const renderQualifiers = () => {
        if (!filterDTO.qualifiers || !(filterDTO.qualifiers as DateQualifierDTO_Output[]).length) return null

        return (
            <div>
                {(filterDTO.qualifiers as DateQualifierDTO_Output[]).map((q, index) => (
                    <Qualifier {...props} key={`${filterDTO.blockId}-${index}`} qualifier={q} depth={depth + 1} parent={filterDTO} />
                ))}
            </div>
        )
    }

    return (
        <QueryNode {...props} node={filterDTO} title={`${filterDTO.table} filter ${filterDTO.blockId}`}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                        gap: '0.25rem',
                        fontSize: '1rem',
                        overflow: 'hidden'
                    }}
                >
                    {renderField('Field', filterDTO.field)}
                    {renderField('Operator', filterDTO.operator)}
                    {renderField('Values', filterDTO.values)}
                    {renderQualifiers()}
                </div>
            </div>
        </QueryNode>
    )
}
