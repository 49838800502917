/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { ChatModel } from '@om1/falcon-api'
import { Send } from 'lucide-react'
import React, { memo, useRef, useState } from 'react'
import { CriteriaTypeIcon } from '../components/query/filters/FilterHeader'
import { capitalizeFirstLetter } from '../components/query/nodes/Or'
import { FilterTypes } from '../components/query/QueryBlock'
import { SelectedBlockProps } from './ChatView'
import { MessageInput } from './MessageInput'
import { Tooltip } from '@mui/material'

export enum ChatModels {
    GPT_4o = 'gpt-4o',
    GPT_4o_mini = 'gpt-4o-mini'
}

export type MessageFormProps = {
    sendMessage: (content: string) => void
    model: ChatModel
    setModel: (model: ChatModel) => void
    message: string
    setMessage: (message: string) => void
} & SelectedBlockProps

// Utility function to capitalize each word in a string
const capitalizeWords = (text: string): string => {
    return text
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}

export const MessageForm = memo(({ sendMessage, model, setModel, message, setMessage, selectedBlock, setSelectedBlock }: MessageFormProps) => {
    const [isInvoking, setIsInvoking] = useState(false)
    const formRef = useRef(null)

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (!message.trim() || isInvoking) return

        setIsInvoking(true)
        try {
            sendMessage(message.trim())
            setMessage('')
        } finally {
            setIsInvoking(false)
        }
    }
    const selectedBlockType = capitalizeWords(
        (selectedBlock as FilterTypes)?.table?.replace('patient_', '').replace('_', ' ') ?? 'Patient Attributes'
    )

    return (
        <form ref={formRef} onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.25rem' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <select
                        value={model}
                        onChange={(e) => setModel(e.target.value as ChatModel)}
                        style={{
                            padding: '0.5rem',
                            backgroundColor: 'white',
                            color: '#2d3748',
                            transition: 'border-color 0.2s',
                            outline: 'none',
                            border: 'none',
                            textAlign: 'left',
                            margin: '0.5rem 0.5rem 0.5rem 1.5rem'
                        }}
                    >
                        {Object.values(ChatModels).map((model) => (
                            <option key={model} value={model}>
                                {model}
                            </option>
                        ))}
                    </select>
                    {selectedBlock?.id !== undefined && (
                        <Tooltip title={`ID: ${selectedBlock.id}`}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    marginLeft: '2rem',
                                    padding: '0.25rem',
                                    margin: '0.5rem 0.5rem 0.5rem 1.5rem',
                                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                                    border: '1px dashed blue'
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {selectedBlockType && (
                                        <>
                                            <i style={{ color: '#1e40af' }}>
                                                <CriteriaTypeIcon criteriaType={selectedBlockType} />
                                            </i>
                                            <span style={{ fontWeight: '800', color: '#103275', marginLeft: '0.5rem' }}>{selectedBlockType}</span>
                                        </>
                                    )}
                                    <span style={{ marginLeft: '8px' }}>
                                        {selectedBlock.name ? `${capitalizeFirstLetter(selectedBlock.name)}` : `${selectedBlock.id}`}
                                    </span>
                                </div>
                                <button
                                    onClick={() => setSelectedBlock?.(undefined)}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        color: 'red',
                                        cursor: 'pointer',
                                        fontSize: '1rem'
                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                        </Tooltip>
                    )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '1rem' }}>
                    <MessageInput message={message} setMessage={setMessage} isInvoking={isInvoking} formRef={formRef} />
                    <button
                        type='submit'
                        disabled={!message.trim() || isInvoking}
                        style={{
                            padding: '0.5rem 1rem',
                            color: '#ffffff',
                            borderRadius: '0.5rem',
                            transition: 'background-color 0.2s',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            backgroundColor: isInvoking ? '#63b3ed' : '#3182ce'
                        }}
                    >
                        <Send style={{ width: '1rem', height: '1rem' }} />
                        <span>Send</span>
                    </button>
                </div>
            </div>
        </form>
    )
})

MessageForm.displayName = 'MessageForm'
