/* eslint-disable string-to-lingui/missing-lingui-transformation */
import React, { FC } from 'react'
import { SelectedBlockProps } from '../../../views/ChatView'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps, NodeTypes } from '../QueryBlock'

export type QueryNodeProps = {
    children: React.ReactNode
    node: NodeTypes
    depth: number
    title?: string
    className?: string
    disabled?: boolean
    and?: boolean
    or?: boolean
} & BlockClickProps &
    EditQueryBlockProps &
    Omit<SelectedBlockProps, 'setSelectedBlockId'>

export const QueryNode: FC<QueryNodeProps> = (props) => {
    const { children, node, title = '', className, onBlockClick, selectedBlock, disabled, and, or, depth } = props
    const clickable = !or && !and && !disabled
    const selected = selectedBlock?.id === node.id && !(isNaN(Number(selectedBlock?.id)) && isNaN(Number(node.id)))
    return (
        <div>
            <div
                style={{
                    cursor: clickable ? 'pointer' : 'default',
                    padding: '1rem 1rem 1rem 1rem',
                    transition: 'all 0.2s',
                    zIndex: 10,
                    position: 'sticky',
                    width: '100%',
                    backgroundColor: depth === 0 ? 'transparent' : and ? '#F5F5F5' : or ? 'white' : 'white',
                    boxShadow: depth > 0 ? '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)' : '',
                    border: selected
                        ? or
                            ? '1px solid gray'
                            : and
                            ? '1px solid gray'
                            : '1px solid gray'
                        : or
                        ? '1px dotted transparent'
                        : and
                        ? '1px dotted transparent'
                        : '1px dotted transparent',
                    ...(className && { className })
                }}
                onClick={(event) => {
                    event.stopPropagation()
                    if (clickable && onBlockClick && node.id && !or && !and) {
                        onBlockClick(node.id)
                    }
                }}
            >
                {title && <div style={{ fontWeight: '600', marginBottom: '1rem' }}>{title}</div>}
                {children}
            </div>
        </div>
    )
}
