import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ExplorerAssistantConversationService } from '../client'

export const QUERY_KEYS = {
    assistantConversationDetail: 'assistantConversationDetail'
} as const

export function useConversation(params: Parameters<typeof ExplorerAssistantConversationService.getExplorerAssistantConversationIdGet>[0]) {
    const memoizedParams = useMemo(() => params, [params])

    const {
        data: assistantConversationDetail,
        isLoading,
        refetch
    } = useQuery({
        queryKey: [QUERY_KEYS.assistantConversationDetail, memoizedParams.id, memoizedParams.model],
        queryFn: () =>
            ExplorerAssistantConversationService.getExplorerAssistantConversationIdGet({
                id: memoizedParams.id,
                model: memoizedParams.model
            })
    })

    return {
        isLoading,
        assistantConversationDetail,
        refetchAssistantConversationDetail: refetch
    }
}
