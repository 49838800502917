/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { RelativeFollowUpFilterDTO_Output } from '@om1/falcon-api'
import React, { FC } from 'react'
import { SelectedBlockProps } from '../../../views/ChatView'
import { FilterContent } from '../nodes/FilterContent'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps, QueryBlock, QueryBlockProps } from '../QueryBlock'

export type RelativeFollowUpFilterProps = {
    relativeFollowUpFilterDTO: RelativeFollowUpFilterDTO_Output
} & Omit<SelectedBlockProps, 'setSelectedBlockId'> &
    BlockClickProps &
    EditQueryBlockProps &
    Omit<QueryBlockProps, 'block'>

const CenteredContainer: FC<{ children: React.ReactNode }> = ({ children }) => <div style={{ width: '100%' }}>{children}</div>

const VerticalLine: FC = () => (
    <div
        style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            height: '50px',
            width: '1px',
            backgroundColor: '#32578E',
            zIndex: 0,
            marginTop: '0px'
        }}
    />
)

const RelationshipLabel: FC<{ text: string }> = ({ text }) => (
    <span
        style={{
            position: 'sticky',
            zIndex: 10,
            padding: '0.5rem 1.5rem',
            fontSize: '1rem',
            border: '1px solid',
            marginTop: '0.5rem',
            marginBottom: '0.4rem',
            backgroundColor: 'white',
            color: 'black',
            borderColor: 'white',
            borderRadius: '4px',
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
        }}
    >
        {text}
    </span>
)

export const RelativeFollowUpFilter: FC<RelativeFollowUpFilterProps> = (props) => {
    const { relativeFollowUpFilterDTO, depth } = props
    const relationshipString = `AND`.trim()

    return (
        <FilterContent {...props} filterDTO={relativeFollowUpFilterDTO}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                <CenteredContainer>
                    {relativeFollowUpFilterDTO.baseline && (
                        <QueryBlock
                            {...props}
                            block={{
                                ...relativeFollowUpFilterDTO.baseline,
                                disabled: relativeFollowUpFilterDTO.disabled || relativeFollowUpFilterDTO.baseline.disabled
                            }}
                            depth={depth + 1}
                            isSubject
                        />
                    )}
                </CenteredContainer>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <VerticalLine />
                    <RelationshipLabel text={relationshipString} />
                </div>
                <CenteredContainer>
                    {relativeFollowUpFilterDTO.followUp && (
                        <QueryBlock
                            {...props}
                            block={{
                                ...relativeFollowUpFilterDTO.followUp,
                                disabled: relativeFollowUpFilterDTO.disabled || relativeFollowUpFilterDTO.followUp.disabled
                            }}
                            depth={depth + 1}
                            isReference
                        />
                    )}
                </CenteredContainer>
            </div>
        </FilterContent>
    )
}
