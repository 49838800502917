import { Trans, t } from '@lingui/macro'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
    Autocomplete,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material'
import { Box } from '@mui/system'
import { CohortDTO, DeliveryCohortDTO, ExplorerCohortsService, PaginateMeta, RegistryType, RegistryTypeDTO } from '@om1/falcon-api'
import { FrameworkComponentProps } from '@om1/platform-utils'
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { dataDeliveryActions } from '../state/data-delivery'

export type DataDeliveryCohortInputProps = FrameworkComponentProps<
    {
        registryTypes: RegistryTypeDTO[]
        registryTypesLoading: boolean
    },
    Pick<typeof dataDeliveryActions, 'getDataDeliveryRegistryTypes'>,
    {
        value: DeliveryCohortDTO[]
        helperText?: string
        onChange: (cohorts: DeliveryCohortDTO[]) => void
        disabled: boolean
        datasets: CohortDTO[]
    }
>

interface CohortAutocompleteOption {
    label: string
    id: string
    isSystem: boolean
    cohortSize: number | null | undefined
}

const numberFormatter = new Intl.NumberFormat(undefined)

export const DataDeliveryCohortInputComponent = ({
    state: { registryTypes, registryTypesLoading },
    props: { value, helperText, onChange, disabled, datasets },
    actions
}: DataDeliveryCohortInputProps) => {
    const [selectedCohort, setSelectedCohort] = useState<CohortAutocompleteOption>()
    const [selectedCohorts, setSelectedCohorts] = useState<DeliveryCohortDTO[]>([])
    const [selectedRegistryType, setSelectedRegistryType] = useState<RegistryType | ''>('')
    const [selectedSampleSize, setSelectedSampleSize] = useState<number | ''>('')
    const [query, setQuery] = useState<string>('')
    const [cohorts, setCohorts] = useState<CohortDTO[]>([])
    const [refMeta, setRefMeta] = useState<PaginateMeta>({
        currentPage: 0,
        totalPages: 0,
        totalItems: 0,
        itemsPerPage: 10
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [debouncedQuery, setDebouncedQuery] = useState<string>('')
    const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false)

    const availableCohorts = useMemo(() => {
        return cohorts.filter((c) => {
            return selectedCohorts.findIndex((s) => s.cohort.id === c.id) === -1
        })
    }, [cohorts, selectedCohorts])

    // Debounce logic using useEffect
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(query)
        }, 300)

        return () => {
            clearTimeout(handler)
        }
    }, [query])

    // Fetch cohorts when debouncedQuery changes
    useEffect(() => {
        const fetchCohorts = async () => {
            try {
                setLoading(true)
                const response = await ExplorerCohortsService.indexExplorerCohortsGet({
                    query: debouncedQuery || undefined,
                    page: 1,
                    limit: refMeta.itemsPerPage
                })

                setCohorts(response.data) // Adjust based on API response
                setRefMeta(response.meta) // Update pagination info
            } catch (error) {
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                console.error('Failed to fetch cohorts:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchCohorts()
    }, [debouncedQuery, refMeta.itemsPerPage])

    // Fetch next page
    const fetchNextPage = async () => {
        if (isFetchingMore || refMeta.currentPage >= refMeta.totalPages) return

        setIsFetchingMore(true)
        try {
            const response = await ExplorerCohortsService.indexExplorerCohortsGet({
                query: debouncedQuery || undefined,
                page: refMeta.currentPage + 1,
                limit: refMeta.itemsPerPage
            })

            setCohorts((prev) => [...prev, ...response.data]) // Append new data
            setRefMeta(response.meta) // Update pagination info
        } catch (error) {
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            console.error('Failed to fetch next page:', error)
        } finally {
            setIsFetchingMore(false)
        }
    }

    useEffect(() => {
        if (!registryTypesLoading && registryTypes.length === 0) {
            actions.getDataDeliveryRegistryTypes()
        }
    }, [actions, registryTypes, registryTypesLoading])

    const handleScroll = (event: React.SyntheticEvent) => {
        const listboxNode = event.currentTarget
        if (
            listboxNode.scrollTop + listboxNode.clientHeight >=
            listboxNode.scrollHeight - 10 // Adjust the threshold as needed
        ) {
            fetchNextPage()
        }
    }

    const handleAddCohort = () => {
        if (selectedCohort && selectedRegistryType) {
            const dataDeliveryCohort: DeliveryCohortDTO = {
                id: '',
                cohort: { id: selectedCohort.id, name: selectedCohort.label, cohortSize: selectedCohort.cohortSize },
                registryType: selectedRegistryType
            }

            if (selectedSampleSize && typeof selectedSampleSize === 'number' && selectedSampleSize > 0) {
                dataDeliveryCohort.sampleSize = selectedSampleSize
            }

            onChange([...value, dataDeliveryCohort])
            setSelectedCohort(undefined)
            setSelectedRegistryType('')
            setSelectedSampleSize('')
            setSelectedCohorts([...selectedCohorts, dataDeliveryCohort])
        }
    }
    const handleRemoveCohort = useCallback(
        (cohort: DeliveryCohortDTO, index: number) => {
            const newValues = [...value.slice(0, index), ...value.slice(index + 1)]
            onChange(newValues)
            setSelectedCohorts(newValues)
        },
        [onChange, value]
    )

    const registryTypeLabelMap = useMemo(() => {
        return (registryTypes || []).reduce(
            (acc, reg) => ({
                ...acc,
                [reg.registryType]: reg.name
            }),
            {}
        )
    }, [registryTypes])

    const renderSelectedDataDeliveryCohorts = useCallback(() => {
        const label = (
            <Box marginBottom={0} marginTop={1}>
                <Trans>Selected Cohorts</Trans>
            </Box>
        )
        const selectedCohorts = value.map((d, index) => (
            <Box
                key={d.cohort.id}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                bgcolor='grey.200'
                fontSize={12}
                paddingX={1}
                paddingY={0.25}
            >
                <Box>
                    {d.cohort.name} ({getCohortSizeString(d.cohort.cohortSize!)}) - {registryTypeLabelMap[d.registryType]} - {d.sampleSize || t`All`}{' '}
                    <Trans>Patients</Trans>
                </Box>
                <IconButton color='primary' aria-label={t`Remove Cohort`} sx={{ padding: 0.5 }} onClick={() => handleRemoveCohort(d, index)}>
                    <DeleteOutlineIcon fontSize='small' />
                </IconButton>
            </Box>
        ))

        return (
            <>
                {value.length > 0 ? label : null}
                {selectedCohorts}
            </>
        )
    }, [value, registryTypeLabelMap, handleRemoveCohort])

    const cohortOptions: CohortAutocompleteOption[] = useMemo(() => {
        return datasets.concat(availableCohorts || []).map((c) => ({
            label: c.name,
            id: c.id,
            isSystem: c.isSystem,
            cohortSize: c.cohortSize
        }))
    }, [availableCohorts, datasets])

    const renderHelperText = () => {
        if (helperText) {
            return (
                <FormHelperText error sx={{ position: 'relative', bottom: 0 }}>
                    {helperText}
                </FormHelperText>
            )
        }

        return <></>
    }

    const renderRegistryTypeOptions = useMemo(() => {
        return (registryTypes || []).map((registry) => (
            <MenuItem value={registry.registryType} key={registry.registryType}>
                {registry.name}
            </MenuItem>
        ))
    }, [registryTypes])

    const renderSampleSizeOptions = () => {
        const sampleSizeOptions: Record<number, string> = {
            0: t`All Patients`,
            10: t`10 Patients`,
            100: t`100 Patients`,
            1000: t`1000 Patients`
        }

        return Object.keys(sampleSizeOptions).map((k) => (
            <MenuItem value={k} key={k}>
                {sampleSizeOptions[k]}
            </MenuItem>
        ))
    }

    const getCohortSizeString = (cohortSize: number | null | undefined): string => {
        return typeof cohortSize === 'number' ? numberFormatter.format(cohortSize) : '—'
    }

    return (
        <>
            <Box>
                <Box display='flex' gap={2} alignItems='center'>
                    <FormControl sx={{ flex: '2 0' }}>
                        <Autocomplete
                            blurOnSelect
                            disableClearable
                            disabled={disabled}
                            options={cohortOptions}
                            groupBy={(option: CohortAutocompleteOption) => (option.isSystem ? t`Datasets` : t`Custom Cohorts`)}
                            getOptionLabel={(option) => `${option.label} (${getCohortSizeString(option.cohortSize)})`}
                            isOptionEqualToValue={(option, value) => {
                                return value && value.id === option.id
                            }}
                            loading={loading}
                            // @ts-ignore null isn't correct from a type perspective, but undefined causes uncontrolled -> controlled errors
                            value={
                                selectedCohort
                                    ? {
                                          label: selectedCohort.label,
                                          id: selectedCohort.id,
                                          isSystem: selectedCohort.isSystem,
                                          cohortSize: selectedCohort.cohortSize || 0
                                      }
                                    : null
                            }
                            onChange={(event: SyntheticEvent<Element, Event>, value: NonNullable<CohortAutocompleteOption>) => {
                                setSelectedCohort(value)
                            }}
                            onInputChange={(event, newInputValue) => {
                                setQuery(newInputValue)
                            }}
                            ListboxProps={{
                                onScroll: handleScroll
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        {option.label} ({getCohortSizeString(option.cohortSize)})
                                    </li>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t`Cohort`}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search'
                                    }}
                                />
                            )}
                            loadingText={<CircularProgress size={20} />}
                        />
                    </FormControl>
                    <FormControl sx={{ flex: '1 0' }}>
                        <InputLabel id='type-label'>
                            <Trans>Type</Trans>
                        </InputLabel>
                        <Select
                            id='type'
                            labelId='type-label'
                            label={<Trans>Type</Trans>}
                            disabled={disabled}
                            value={selectedRegistryType}
                            onChange={(event) => {
                                setSelectedRegistryType(event.target.value as RegistryType)
                            }}
                        >
                            {renderRegistryTypeOptions}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ flex: '1 0' }}>
                        <InputLabel id='size-label'>
                            <Trans>Size</Trans>
                        </InputLabel>
                        <Select
                            id='size'
                            labelId='size-label'
                            label={<Trans>Size</Trans>}
                            disabled={disabled}
                            value={selectedSampleSize}
                            onChange={(event) => {
                                setSelectedSampleSize(parseInt(`${event.target.value}`, 10))
                            }}
                        >
                            {renderSampleSizeOptions()}
                        </Select>
                    </FormControl>
                    <Box flex='0 0'>
                        <Button
                            sx={{ paddingY: '0.25rem' }}
                            startIcon={<AddIcon />}
                            onClick={handleAddCohort}
                            disabled={!selectedCohort || !selectedRegistryType}
                        >
                            <Trans>Add</Trans>
                        </Button>
                    </Box>
                </Box>
                <Box display='flex' flexDirection='column' gap={1}>
                    {renderSelectedDataDeliveryCohorts()}
                    {renderHelperText()}
                </Box>
            </Box>
        </>
    )
}
