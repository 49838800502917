/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { AndDTO_Output, OrDTO_Output } from '@om1/falcon-api'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { FC } from 'react'
import { SelectedBlockProps } from '../../../views/ChatView'
import { FilterContent } from '../nodes/FilterContent'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps, QueryBlockProps } from '../QueryBlock'
import { CARD_ICONS } from '../queryUtils'
import { FilterDropdownMenu } from './FilterDropdownMenu'
import { FilterHeader } from './FilterHeader'
import { PatientOrGroup } from './PatientOrGroup'

export type PatientAndGroupProps = {
    andDTO: AndDTO_Output
} & Omit<SelectedBlockProps, 'setSelectedBlockId'> &
    BlockClickProps &
    EditQueryBlockProps &
    Pick<QueryBlockProps, 'depth'>

export const PatientAndGroup: FC<PatientAndGroupProps> = (props) => {
    const { andDTO, depth, setEditBlock, editDisabled } = props
    const fieldValuesMap = {
        [(andDTO.and[0] as FilterDTO_Output).field]: (andDTO.and[0] as FilterDTO_Output).values,
        [(andDTO.and[1] as FilterDTO_Output).field]: (andDTO.and[1] as FilterDTO_Output).values
    }
    const { sex, race } = fieldValuesMap
    const orNode = andDTO.and[2] as OrDTO_Output

    return (
        <FilterContent {...props} filterDTO={{ ...andDTO.and[0], id: andDTO.id } as FilterDTO_Output}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <FilterHeader icon={CARD_ICONS.patient_attributes} title='Patient Attributes' />
                {editDisabled ? null : <FilterDropdownMenu onEdit={() => setEditBlock && setEditBlock({ filter: andDTO, index: undefined })} />}
            </div>
            <div style={{ marginBottom: '0.25rem', fontSize: '0.75rem' }}>
                {sex && (
                    <div>
                        <span style={{ fontWeight: '800' }}>Sex:</span> {sex.join(', ') === '' ? 'Any' : sex.join(', ')}
                    </div>
                )}
                {race && (
                    <div>
                        <span style={{ fontWeight: '800' }}>Race:</span> {race.join(', ') === '' ? 'Any' : race.join(', ')}
                    </div>
                )}
                <PatientOrGroup {...props} node={orNode} depth={depth + 1} />
            </div>
        </FilterContent>
    )
}
