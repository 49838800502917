import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import { Box, Button, DialogActions, DialogContent, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { ReactNode, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { cohortBlocksEditActions, CriterionNode, FilterValueQualifier, QualifierOperator, QualifierType } from '../../../state'
import { findQualifierByType } from '../utils/finders'
import { ObservationScoreField } from './ObservationScoreEditDialogComponent'
import { useRefTable } from '@om1/falcon-api/codegen/hooks/useRef'

interface ObservationTextQualifierEditFormValues {
    operator: QualifierOperator
    field: string
    input: string[]
}

export type ObservationTextQualifierEditDialogComponentProps = FrameworkComponentProps<
    {
        criteria: CriterionNode
    },
    typeof cohortBlocksEditActions
>

export const ObservationTextQualifierEditDialogComponent = ({ state, actions }: ObservationTextQualifierEditDialogComponentProps) => {
    const { criteria } = state

    const { data: textValueData } = useRefTable('ref_observation_text_value', criteria.filters[0]?.values, 1000)

    const textValues = useMemo(() => {
        return textValueData?.pages[0]?.data || []
    }, [textValueData])

    const validationSchema = Yup.object().shape({
        operator: Yup.string(),
        field: Yup.string().oneOf(Object.values(ObservationScoreField)),
        input: Yup.array()
            .of(Yup.string())
            .min(1, t`Please select at least one value`)
            .required()
    })

    const initialValue = useMemo(() => {
        const existingQualifier = findQualifierByType<FilterValueQualifier>(criteria.qualifiers, QualifierType.FilterQualifierDTO)

        if (!existingQualifier) {
            return {
                operator: QualifierOperator.In,
                field: ObservationScoreField.Text,
                input: []
            }
        }

        const { operator, values, field } = existingQualifier

        return {
            operator,
            field: field.replace('_result', ''),
            input: values.map((value) => value.toString())
        }
    }, [criteria])

    const {
        control,
        handleSubmit,
        register,
        formState: { errors }
    } = useForm<ObservationTextQualifierEditFormValues>({
        defaultValues: {
            operator: initialValue.operator,
            field: initialValue.field,
            input: initialValue.input
        },
        resolver: yupResolver(validationSchema)
    })

    const onCancel = () => {
        actions.observationScoreEditDialogTrigger({})
    }

    const onSubmit: SubmitHandler<ObservationTextQualifierEditFormValues> = (formValues: ObservationTextQualifierEditFormValues) => {
        let qualifiers = [...criteria.qualifiers]
        const newQualifier: FilterValueQualifier = formValuesToFilterValueQualifier(formValues)
        qualifiers = qualifiers.filter((q) => q.type !== QualifierType.FilterQualifierDTO)
        qualifiers.push(newQualifier)

        actions.observationScoreEditDialogTrigger({})
        actions.criteriaUpdate({ target: { nodeId: criteria.id }, qualifiers })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Box paddingTop={2} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Box flexShrink={0} marginRight={1}>
                        <Trans>Observation Value</Trans>
                    </Box>
                    <Controller
                        control={control}
                        name='operator'
                        render={({ field: { onChange, value } }) => (
                            <Select
                                id='operator'
                                labelId='operator-label'
                                sx={{ flex: 1, marginRight: 1 }}
                                required
                                value={value}
                                onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                            >
                                <MenuItem value={QualifierOperator.In}>
                                    <Trans>In</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.NotIn}>
                                    <Trans>Not In</Trans>
                                </MenuItem>
                            </Select>
                        )}
                    />
                    <Controller
                        control={control}
                        name='input'
                        render={({ field: { onChange, value } }) => (
                            <Select
                                multiple
                                {...register('input')}
                                sx={{ marginRight: 1, flex: 1 }}
                                value={value}
                                onChange={onChange}
                                error={!!errors.input}
                            >
                                {textValues.map((textValue) => (
                                    <MenuItem key={textValue.text_value} value={textValue.text_value}>
                                        {textValue.text_value}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='primary' onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button variant='contained' type='submit'>
                    <Trans>Save</Trans>
                </Button>
            </DialogActions>
        </form>
    )
}

function formValuesToFilterValueQualifier(formValues: ObservationTextQualifierEditFormValues): FilterValueQualifier {
    return {
        type: QualifierType.FilterQualifierDTO,
        field: `${formValues.field}_result`,
        operator: formValues.operator,
        values: formValues.input
    }
}
