/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { AndDTO_Output } from '@om1/falcon-api/codegen/client/models/AndDTO_Output'
import { RefTable } from '@om1/falcon-api/codegen/client/models/RefTable'
import { ExplorerAnalyticsRefService } from '@om1/falcon-api/codegen/client/services/ExplorerAnalyticsRefService'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { fetchData } from '../queryUtils'

// Type guard to check if the object is a FilterDTO_Output with 'values'
const isFilterWithValues = (filter: any): filter is { field: string; values: string[] } => 'field' in filter && 'values' in filter

// Type guard to check if the object is of type OrDTO_Output
const isOrDTO = (filter: any): filter is { or: any[] } => 'or' in filter

export const PatientAttributesContents: FC<{ editBlock: AndDTO_Output }> = ({ editBlock }) => {
    const [stateRefs, setStateRefs] = useState<Array<Record<string, any>>>([])
    const [raceRefs, setRaceRefs] = useState<Array<Record<string, any>>>([])
    const [sexRefs, setSexRefs] = useState<Array<Record<string, any>>>([])
    const [selectedSex, setSelectedSex] = useState<string[]>([])
    const [selectedRace, setSelectedRace] = useState<string[]>([])
    const [selectedLocation, setSelectedLocation] = useState<string[]>([])
    const [hasChanged, setHasChanged] = useState<boolean>(false)

    const initialSex = useMemo(
        () => (editBlock.and.find((filter) => isFilterWithValues(filter) && filter.field === 'sex') as { values: string[] })?.values || [],
        [editBlock]
    )

    const initialRace = useMemo(
        () => (editBlock.and.find((filter) => isFilterWithValues(filter) && filter.field === 'race') as { values: string[] })?.values || [],
        [editBlock]
    )

    const initialState = useMemo(
        () =>
            (
                (editBlock.and.find((filter) => isOrDTO(filter)) as { or: any[] })?.or?.find(
                    (filter) => isFilterWithValues(filter) && filter.field === 'state'
                ) as {
                    values: string[]
                }
            )?.values || [],
        [editBlock]
    )

    useEffect(() => {
        setSelectedSex(initialSex)
        setSelectedRace(initialRace)
        setSelectedLocation(initialState)

        // Ensure the button is initially disabled
        setHasChanged(false)

        const fetchDataAsync = async () => {
            const stateData = await fetchData(
                'explorerCohorts',
                {
                    refTableName: `ref_state` as RefTable,
                    page: 1,
                    limit: 100,
                    filters: []
                },
                ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet
            )
            setStateRefs(stateData.data)
            const raceData = await fetchData(
                'explorerCohorts',
                {
                    refTableName: `ref_race` as RefTable,
                    page: 1,
                    limit: 100,
                    filters: []
                },
                ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet
            )
            setRaceRefs(raceData.data)
            const sexData = await fetchData(
                'explorerCohorts',
                {
                    refTableName: `ref_sex` as RefTable,
                    page: 1,
                    limit: 100,
                    filters: []
                },
                ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet
            )
            setSexRefs(sexData.data)
        }

        fetchDataAsync()
    }, [editBlock, initialSex, initialRace, initialState])

    const handleCheckboxChange = useCallback((setState: React.Dispatch<React.SetStateAction<string[]>>, value: string, initialValues: string[]) => {
        setState((prev) => {
            const newState = prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
            setHasChanged(JSON.stringify(newState) !== JSON.stringify(initialValues))
            return newState
        })
    }, [])

    return (
        <div style={{ padding: '20px', position: 'relative' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Update Patient Attributes</h2>

            <div style={{ marginBottom: '20px' }}>
                <h3>Sex</h3>
                <label key='any-sex' style={{ marginRight: '10px' }}>
                    <input
                        type='checkbox'
                        checked={selectedSex.length === 0}
                        onChange={() => {
                            setSelectedSex([])
                            setHasChanged(false)
                        }}
                    />
                    Any
                </label>
                {sexRefs.map((state) => (
                    <label key={state.sex} style={{ marginRight: '10px' }}>
                        <input
                            type='checkbox'
                            checked={selectedSex.includes(state.sex)}
                            onChange={() => handleCheckboxChange(setSelectedSex, state.sex, initialSex)}
                        />
                        {state.sex}
                    </label>
                ))}
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h3>Race</h3>
                <label key='any-race' style={{ marginRight: '10px' }}>
                    <input
                        type='checkbox'
                        checked={selectedRace.length === 0}
                        onChange={() => {
                            setSelectedRace([])
                            setHasChanged(false)
                        }}
                    />
                    Any
                </label>
                {raceRefs.map((race) => (
                    <label key={race.race} style={{ marginRight: '10px' }}>
                        <input
                            type='checkbox'
                            checked={selectedRace.includes(race.race)}
                            onChange={() => handleCheckboxChange(setSelectedRace, race.race, initialRace)}
                        />
                        {race.race}
                    </label>
                ))}
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h3>Location</h3>
                <label key='any-location' style={{ marginRight: '10px' }}>
                    <input
                        type='checkbox'
                        checked={selectedLocation.length === 0}
                        onChange={() => {
                            setSelectedLocation([])
                            setHasChanged(false)
                        }}
                    />
                    Any
                </label>
                {stateRefs.map((state) => (
                    <label key={state.state_name} style={{ marginRight: '10px' }}>
                        <input
                            type='checkbox'
                            checked={selectedLocation.includes(state.state_name)}
                            onChange={() => handleCheckboxChange(setSelectedLocation, state.state_name, initialState)}
                        />
                        {state.state_name}
                    </label>
                ))}
            </div>

            <button
                style={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: !hasChanged ? '#ccc' : '#004085',
                    color: '#fff',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                    fontSize: '1em'
                }}
                disabled={!hasChanged}
            >
                + Save Criteria
            </button>
        </div>
    )
}
