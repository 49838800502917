import { put } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'
import { webSocketActions } from './websocket-sagas'

export function createInvokeExplorerAssistantConversationSaga() {
    return function* (action: ReturnType<typeof explorerAssistantConversationActions.invokeConversation>) {
        try {
            yield put(explorerAssistantConversationActions.setAssistantIsResponding(true))
            if (!action.payload.id) {
                throw new Error('Conversation ID is required')
            }
            yield put(
                explorerAssistantConversationActions.appendResponse({
                    content: action.payload.requestBody.messages[0].content,
                    type: 'human',
                    id: uuidv4(),
                    additional_kwargs: {},
                    response_metadata: {}
                })
            )
            yield put(webSocketActions.sendMessage(action.payload.requestBody.messages[0].content))
        } catch (error: any) {
            console.error(error)
        }
    }
}

export type InvokeExplorerAssistantConversationSaga = ReturnType<typeof createInvokeExplorerAssistantConversationSaga>
