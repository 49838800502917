import { GetNextPageParamFunction, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ExplorerAssistantConversationService, PaginatedDTO_AssistantConversationDTO_ } from '../client'

export const QUERY_KEYS = {
    assistantConversations: 'assistantConversations'
} as const

export function useConversations(params: Parameters<typeof ExplorerAssistantConversationService.indexExplorerAssistantConversationGet>[0]) {
    const memoizedParams = useMemo(() => params, [params])

    const getNextPageParam: GetNextPageParamFunction<PaginatedDTO_AssistantConversationDTO_> = (lastPage) => {
        return lastPage.meta.currentPage < lastPage.meta.totalPages ? lastPage.meta.currentPage + 1 : undefined
    }

    const {
        data: assistantConversations,
        error,
        isLoading,
        fetchNextPage,
        hasNextPage
    } = useInfiniteQuery<PaginatedDTO_AssistantConversationDTO_>({
        queryKey: [QUERY_KEYS.assistantConversations, memoizedParams.referenceCohortId, memoizedParams.limit],
        queryFn: () =>
            ExplorerAssistantConversationService.indexExplorerAssistantConversationGet({
                referenceCohortId: memoizedParams.referenceCohortId,
                page: memoizedParams.page,
                limit: memoizedParams.limit
            }),
        getNextPageParam
    })

    return {
        assistantConversations,
        error,
        isLoading,
        fetchNextPage,
        hasNextPage
    }
}

export function useCreateConversation() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (params: Parameters<typeof ExplorerAssistantConversationService.createExplorerAssistantConversationPost>[0]) =>
            ExplorerAssistantConversationService.createExplorerAssistantConversationPost(params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assistantConversations] })
        }
    })
}

export function useUpdateConversation() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (params: Parameters<typeof ExplorerAssistantConversationService.patchExplorerAssistantConversationIdPatch>[0]) =>
            ExplorerAssistantConversationService.patchExplorerAssistantConversationIdPatch(params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assistantConversations] })
        }
    })
}

export function useDeleteConversation() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (params: Parameters<typeof ExplorerAssistantConversationService.deleteExplorerAssistantConversationIdDelete>[0]) =>
            ExplorerAssistantConversationService.deleteExplorerAssistantConversationIdDelete(params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assistantConversations] })
        }
    })
}
