/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output, DateQualifierDTO_Output, FilterDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { SelectedBlockProps } from '../../../views/ChatView'
import { QueryNode } from '../nodes/QueryNode'
import { Qualifier } from '../qualifiers/Qualifier'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps, QueryBlockProps } from '../QueryBlock'
import { ObservationPeriodFilter } from './ObservationPeriodFilter'
import { PatientDiagnosisFilter } from './PatientDiagnosisFilter'
import { PatientEncounterFilter } from './PatientEncounterFilter'
import { PatientExternalCohortFilter } from './PatientExternalCohortFilter'
import { PatientFilter } from './PatientFilter'
import { PatientLabFilter } from './PatientLabFilter'
import { PatientMedicationFilter } from './PatientMedicationFilter'
import { PatientNoteFilter } from './PatientNoteFilter'
import { PatientObservationFilter } from './PatientObservationFilter'
import { PatientProcedureFilter } from './PatientProcedureFilter'

export type FilterProps = {
    filterDTO: FilterDTO_Output | DateAwareFilterDTO_Output
} & Omit<SelectedBlockProps, 'setSelectedBlockId'> &
    BlockClickProps &
    EditQueryBlockProps &
    Omit<QueryBlockProps, 'block'>

const filterComponents = {
    patient_note: PatientNoteFilter,
    patient: PatientFilter,
    patient_encounter: PatientEncounterFilter,
    patient_procedure: PatientProcedureFilter,
    patient_medication: PatientMedicationFilter,
    patient_diagnosis: PatientDiagnosisFilter,
    patient_external_cohort: PatientExternalCohortFilter,
    observation_period: ObservationPeriodFilter,
    patient_observation: PatientObservationFilter,
    patient_lab: PatientLabFilter
}

export const Filter: FC<FilterProps> = (props: FilterProps) => {
    const { filterDTO } = props
    const FilterComponent = filterComponents[filterDTO.table]

    if (FilterComponent) {
        return <FilterComponent {...props} />
    }

    return (
        <QueryNode title={`FilterDTO`} {...props} node={filterDTO}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '0.25rem', fontSize: '0.875rem' }}>
                    <span style={{ color: '#4B5563' }}>Table:</span>
                    <span>
                        {filterDTO.table}
                        <span style={{ marginLeft: '0.5rem', color: 'red', display: 'inline-flex', alignItems: 'center' }}>
                            ⚠️
                            <span style={{ marginLeft: '0.25rem' }}>invalid</span>
                        </span>
                    </span>
                    <span style={{ color: '#4B5563' }}>Field:</span>
                    <span>{filterDTO.field}</span>
                    <span style={{ color: '#4B5563' }}>Operator:</span>
                    <span>{filterDTO.operator}</span>
                    <span style={{ color: '#4B5563' }}>Values:</span>
                    <span>{Array.isArray(filterDTO.values) ? filterDTO.values.join(', ') : filterDTO.values}</span>
                    {filterDTO.qualifiers && (filterDTO.qualifiers as any[]).length > 0 && (
                        <div>
                            {(filterDTO.qualifiers as any[]).map((q: DateQualifierDTO_Output, index: number) => (
                                <Qualifier
                                    {...props}
                                    key={`${filterDTO.blockId}-${index}`}
                                    qualifier={q}
                                    depth={props.depth + 1}
                                    parent={filterDTO}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </QueryNode>
    )
}
