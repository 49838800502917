/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { ExceptDTO_Output, QueryDTO_Input, QueryDTO_Output } from '@om1/falcon-api'
import { FC, SetStateAction } from 'react'
import { SelectedBlockProps } from '../../views/ChatView'
import { NodeTypes, QueryBlock } from './QueryBlock'

const isExceptDTO = (node: any): node is ExceptDTO_Output => node.type === 'ExceptDTO'

const isBaseDTOFilter = (node: QueryDTO_Output | QueryDTO_Input | null | undefined): boolean => {
    return Boolean(
        node &&
            node.filters?.length === 1 &&
            isExceptDTO(node.filters[0]) &&
            (node.filters[0].except.length === 1 || node.filters[0].except.length === 2) &&
            node.filters[0].except.every((exceptNode) => exceptNode.type === 'AndDTO')
    )
}

export type QueryBlockProps = {
    query?: QueryDTO_Output | QueryDTO_Input | null
}

export type EditQueryBlockProps = QueryBlockProps & {
    editDisabled?: boolean
    setEditBlock?: (
        value: SetStateAction<{
            filter: NodeTypes
            index: number | undefined
        } | null>
    ) => void
}

export type QueryProps = {
    isBase?: boolean
    onBlockClick?: (blockId: number, qualifierIndex?: number) => void
} & SelectedBlockProps &
    EditQueryBlockProps

export const Query: FC<QueryProps> = (props) => {
    const { query, isBase } = props
    const isBaseDTO = isBase && isBaseDTOFilter(query)

    const renderQueryBlock = (block: any, key: string) => <QueryBlock {...props} key={key} block={block} depth={0} editDisabled={true} />

    return (
        <div style={{ minHeight: '66.54dvh', marginBottom: '25px' }}>
            {isBaseDTO && query?.filters && isExceptDTO(query.filters[0]) ? (
                <>
                    <h4>Inclusion Criteria: </h4>
                    {renderQueryBlock(query.filters[0].except[0], 'query-block-inclusion')}
                    {query.filters[0].except.length > 1 && (
                        <>
                            <h4>Exclusion Criteria: </h4>
                            {renderQueryBlock(query.filters[0].except[1], 'query-block-exclusion')}
                        </>
                    )}
                </>
            ) : (
                query?.filters?.map((filter, index) => renderQueryBlock(filter, `query-block-${index}`))
            )}
        </div>
    )
}
