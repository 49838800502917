/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { useAuth0 } from '@auth0/auth0-react'
import '@fontsource/metropolis' // Defaults to weight 400.
import { AllLocaleData, AllMessages } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { styled } from '@mui/system'
import { OpenAPI } from '@om1/falcon-api/codegen/client/core/OpenAPI'
import { Auth0PlatformProvider } from '@om1/platform-authentication'
import { platformConfigActions } from '@om1/platform-config/state'
import { NotificationState } from '@om1/platform-notifications'
import { TrackingProvider, createTrackingMiddleware, useAnalytics } from '@om1/platform-tracking'
import { createHelpDialog } from '@om1/platform-ui-kit/src/components/sidebar/HelpDialog'
import { createTimeoutDialog } from '@om1/platform-ui-kit/src/components/sidebar/TimeoutDialog'
import { platformTheme } from '@om1/platform-ui-kit/src/theme'
import { withConfig } from '@om1/platform-utils'
import { QueryClientProvider } from '@tanstack/react-query'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { en, es } from 'make-plural'
import { SnackbarProvider, useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { addMiddleware } from 'redux-dynamic-middlewares'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import packageJson from '../package.json'
import { ConfigInterface, config } from './Config'
import { messages as enMessages } from './locales/en/messages'
import { messages as esMessages } from './locales/es/messages'
import { createLandingPageComponent } from './pages/LandingPage'
import { AppStore, PlatformState, createAppStore, createReducer, createRootSaga } from './platform-state'
import { queryClient } from './query-utils'
import { LocaleEnum } from './shared/providers/internationalization/language-config'
import LanguageProvider from './shared/providers/internationalization/LanguageProvider'

function App() {
    // create top level react contexts (router history, saga middleware, redux store etc)
    const history = createBrowserHistory()
    const appReducer = createReducer(history)
    const sagaMiddleware = createSagaMiddleware()
    const store = createAppStore(appReducer, sagaMiddleware, routerMiddleware(history))

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Auth0PlatformProvider>
                    <SnackbarProvider maxSnack={3}>
                        <TrackingProvider>
                            <ConfiguredApp sagaMiddleware={sagaMiddleware} store={store} />
                        </TrackingProvider>
                    </SnackbarProvider>
                </Auth0PlatformProvider>
            </ConnectedRouter>
        </Provider>
    )
}

interface ConfiguredAppComponentProps {
    config: ConfigInterface
    sagaMiddleware: SagaMiddleware<object>
    store: AppStore
}

const ConfiguredAppComponent: React.FunctionComponent<ConfiguredAppComponentProps> = ({
    config,
    sagaMiddleware,
    store
}: ConfiguredAppComponentProps) => {
    let { falconApiUrl, writeKey, buildNumber, environmentTag } = config
    const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently, user, logout } = useAuth0()
    const [tokenLoaded, setTokenLoaded] = useState(false)

    const { enqueueSnackbar } = useSnackbar()
    const analytics = useAnalytics(writeKey)

    // create tracking middleware and add to redux
    const trackingMiddleware = createTrackingMiddleware(analytics)
    addMiddleware(trackingMiddleware)

    // start redux-sagas
    const rootsaga = createRootSaga(enqueueSnackbar, analytics, user)
    const [firstLoad, setFirstLoad] = React.useState(true)

    // Fetch and set the Auth0 access token
    useEffect(() => {
        const fetchToken = async () => {
            if (isAuthenticated && !OpenAPI.TOKEN) {
                try {
                    const accessToken = await getAccessTokenSilently()
                    OpenAPI.TOKEN = accessToken
                    setTokenLoaded(true)
                } catch (error) {
                    console.error('Error fetching token:', error)
                    logout()
                }
            }
        }

        fetchToken()
    }, [isAuthenticated, getAccessTokenSilently, logout])

    // Redirect to login if not authenticated
    if (!isAuthenticated && !isLoading) {
        loginWithRedirect()
        return null
    }

    // Show loading while Auth0 or token is not ready
    if (isLoading || !tokenLoaded) {
        return <div>Loading...</div>
    }

    OpenAPI.BASE = falconApiUrl

    if (rootsaga && firstLoad) {
        sagaMiddleware.run(rootsaga)
        setFirstLoad(false)
    }

    // establish translations
    const messages: AllMessages = {
        en: enMessages,
        es: esMessages
    }
    const plurals: AllLocaleData = {
        en: { plurals: en },
        es: { plurals: es }
    }

    // create landing page (base of the application, main routing, top nav etc)
    const LandingPage = createLandingPageComponent<PlatformState>()

    const HelpDialog = createHelpDialog<{ notification: NotificationState }>()
    const TimeoutDialog = createTimeoutDialog<{ notification: NotificationState }>()

    const Header = styled('h1')`
        color: white;
        height: 0;
        margin: 0;
    `

    // manages the auth0 authentication status, issues actions based on changes
    if (isAuthenticated && !isLoading) {
        store.dispatch(platformConfigActions.setBuildNumber(buildNumber))
        store.dispatch(platformConfigActions.setEnvironmentTag(environmentTag))
        store.dispatch(platformConfigActions.setSemanticVersion(packageJson.version))
    }

    return (
        <React.StrictMode>
            <LanguageProvider language={LocaleEnum.English} messages={messages} plurals={plurals}>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={platformTheme}>
                        <CssBaseline />
                        <Header>
                            <Trans>App Platform</Trans>
                        </Header>
                        <HelpDialog />
                        <TimeoutDialog />
                        <LandingPage />
                    </ThemeProvider>
                </QueryClientProvider>
            </LanguageProvider>
        </React.StrictMode>
    )
}

const ConfiguredApp = withConfig<ConfiguredAppComponentProps>(ConfiguredAppComponent, config)

export default App
