import { withRouter } from '@om1/platform-routing'
import { trackingActions } from '@om1/platform-tracking'
import { connectRedux } from '@om1/platform-utils'
import { cohortCommonActions, cohortCreateActions, cohortListActions, CohortOwner, CohortState } from '../../state'
import { cohortDeleteActions } from '../../state/delete'
import { CohortListComponent } from './CohortListComponent'

export function createCohortListComponent<
    TState extends {
        cohort: CohortState
    }
>() {
    return connectRedux(
        withRouter(CohortListComponent, { namespace: 'custom', dataFetchAction: 'cohortsGetUserCohorts' }),
        (state: TState) => ({
            cohorts: state.cohort.list.cohorts[CohortOwner.User],
            cohortSizeLoading: state.cohort.common.ui.cohortSizeLoading,
            paginationMeta: state.cohort.list.paginationMeta,
            createLoading: state.cohort.create.ui.loading
        }),
        {
            ...cohortListActions,
            cohortCreate: cohortCreateActions.cohortCreate,
            cohortDelete: cohortDeleteActions.cohortDelete,
            cohortSizeGet: cohortCommonActions.cohortSizeGet,
            ...trackingActions
        }
    )
}

export type CohortList = ReturnType<typeof createCohortListComponent>
