import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { BackOfficeState } from '../state'
import { dataDeliveryActions } from '../state/data-delivery'
import { CreateEditOrganizationMetadataDialogComponent } from './CreateEditOrganizationMetadataDialogComponent'

export function createCreateEditOrganizationMetadataDialogComponent<TState extends { backOffice: BackOfficeState }>() {
    return connectRedux(
        withRouter(CreateEditOrganizationMetadataDialogComponent, { namespace: 'organizationMetadata', dataFetchAction: 'getOrganizationMetadata' }),
        (state: TState) => ({
            organizationMetadata: state.backOffice.dataDelivery.organizationMetadata
        }),
        {
            getOrganizationMetadata: dataDeliveryActions.getOrganizationMetadata,
            createOrganizationMetadata: dataDeliveryActions.createOrganizationMetadata,
            updateOrganizationMetadata: dataDeliveryActions.updateOrganizationMetadata
        }
    )
}

export type CreateEditOrganizationMetadataDialog = ReturnType<typeof createCreateEditOrganizationMetadataDialogComponent>
