import { useInfiniteQuery, GetNextPageParamFunction } from '@tanstack/react-query'
import { ExplorerAnalyticsRefService, PaginatedDictDTO_str_Any_, RefTable } from '../client'

export const QUERY_KEYS = {
    refLabTable: 'refLabTable'
} as const

export function useRefTable(refTableName: RefTable, filters?: string[], limit = 100) {
    const getNextPageParam: GetNextPageParamFunction<PaginatedDictDTO_str_Any_> = (lastPage) => {
        return lastPage.meta.currentPage < lastPage.meta.totalPages ? lastPage.meta.currentPage + 1 : undefined
    }

    return useInfiniteQuery<PaginatedDictDTO_str_Any_>({
        queryKey: [QUERY_KEYS.refLabTable, refTableName, limit, filters],
        queryFn: () => ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet({ refTableName, limit, filters }),
        getNextPageParam
    })
}
