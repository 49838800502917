/* eslint-disable string-to-lingui/missing-lingui-transformation */
import React, { useRef } from 'react'

export const MessageInput = React.memo(
    (props: { message: string; setMessage: (message: string) => void; isInvoking: boolean; formRef: React.RefObject<HTMLFormElement> }) => {
        const textareaRef = useRef<HTMLTextAreaElement>(null)

        const handleMouseDown = (e: React.MouseEvent) => {
            e.preventDefault()
            const startY = e.clientY
            const startHeight = textareaRef.current?.offsetHeight || 0

            const onMouseMove = (e: MouseEvent) => {
                if (textareaRef.current) {
                    const newHeight = startHeight - (e.clientY - startY)
                    textareaRef.current.style.height = `${newHeight}px`
                }
            }

            const onMouseUp = () => {
                document.removeEventListener('mousemove', onMouseMove)
                document.removeEventListener('mouseup', onMouseUp)
            }

            document.addEventListener('mousemove', onMouseMove)
            document.addEventListener('mouseup', onMouseUp)
        }

        const handleInput = () => {
            if (textareaRef.current) {
                textareaRef.current.style.height = 'auto' // Reset height
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px` // Set to scroll height
            }
        }

        return (
            <div style={{ flex: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <textarea
                    ref={textareaRef}
                    value={props.message}
                    rows={1}
                    onChange={(e) => props.setMessage(e.target.value)}
                    onInput={handleInput}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey && !e.metaKey) {
                            e.preventDefault()
                            const formElement = props.formRef.current
                            if (formElement) {
                                const submitEvent = new Event('submit', { bubbles: true, cancelable: true })
                                formElement.dispatchEvent(submitEvent)
                            }
                        } else if (e.key === 'Enter' && e.metaKey) {
                            e.preventDefault()
                            props.setMessage(props.message + '\n')
                        }
                    }}
                    placeholder='Type your message...'
                    style={{
                        padding: '0.5rem',
                        border: '1px solid #e2e8f0',
                        borderRadius: '0.5rem',
                        outline: 'none',
                        transition: 'box-shadow 0.2s',
                        boxShadow: props.isInvoking ? 'none' : '0 0 0 2px #3182ce',
                        fontFamily: 'Metropolis, sans-serif',
                        color: '#012D72',
                        fontSize: '1rem',
                        lineHeight: '1.25',
                        resize: 'none', // Disable default resize
                        minHeight: '2.25rem'
                    }}
                    disabled={props.isInvoking}
                />
                <div
                    onMouseDown={handleMouseDown}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '7.5px',
                        height: '7.5px',
                        cursor: 'nwse-resize',
                        backgroundColor: '#3182ce',
                        borderTopRightRadius: '0.5rem',
                        borderBottomLeftRadius: '0.5rem'
                    }}
                />
            </div>
        )
    }
)

MessageInput.displayName = 'MessageInput'
