/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { FC } from 'react'
import { EditQualifierDialogContents } from '../qualifiers/EditQualifierDialogContents'
import { GenericDialogContents } from './GenericDialogContents'
import { ObservationDialogContents } from './ObservationDialogContents'
import { ObservationPeriodContents } from './ObservationPeriodContents'
import { PatientDiagnosisDialogContents } from './PatientDiagnosisDialogContents'
import { PatientExternalCohortContents } from './PatientExternalCohortContents'
import { PatientLabDialogContents } from './PatientLabDialogContents'
import { PatientMedicationDialogContents } from './PatientMedicationDialogContents'
import { ProcedureDialogContents } from './ProcedureDialogContents'

export const EditFilterDialogContents: FC<{ editBlock: FilterDTO_Output | DateAwareFilterDTO_Output; editQualifierIndex: number | undefined }> = ({
    editBlock,
    editQualifierIndex
}) => {
    if (editQualifierIndex !== undefined) {
        if (editQualifierIndex === -1) {
            return <EditQualifierDialogContents editBlock={editBlock} qualifierType='CountDistinctQualifierDTO' />
        } else {
            const qualifier = (editBlock?.qualifiers as unknown as any[])?.[editQualifierIndex]
            return <EditQualifierDialogContents editBlock={editBlock} qualifierType={qualifier?.type} />
        }
    } else {
        switch (editBlock.table) {
            case 'patient_diagnosis':
                return <PatientDiagnosisDialogContents editBlock={editBlock} />
            case 'patient_external_cohort':
                return <PatientExternalCohortContents editBlock={editBlock} />
            case 'patient_medication':
                return <PatientMedicationDialogContents editBlock={editBlock} />
            case 'observation_period':
                return <ObservationPeriodContents editBlock={editBlock} />
            case 'patient_observation':
                return <ObservationDialogContents editBlock={editBlock} />
            case 'patient_lab':
                return <PatientLabDialogContents editBlock={editBlock} />
            case 'patient_procedure':
                return <ProcedureDialogContents editBlock={editBlock} />
            default:
                return <GenericDialogContents editBlock={editBlock} />
        }
    }
}
