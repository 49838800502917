import { auth0Actions, useUserPermissions } from '@om1/platform-authentication'
import { FrameworkComponentProps, PlatformPermissions, connectRedux } from '@om1/platform-utils'
import { FunctionComponent, ReactElement } from 'react'

export type PermissionProtectedRouteComponentProps = FrameworkComponentProps<
    {},
    typeof auth0Actions,
    {
        permissions: PlatformPermissions[]
        accessDeniedComponent: ReactElement
        protectedChild: ReactElement
    }
>

export const PermissionProtectedRouteComponent: FunctionComponent<PermissionProtectedRouteComponentProps> = ({ state, props }) => {
    const { permissions } = useUserPermissions()

    const hasRole = permissions.every((permission) => permissions!.includes(permission))
    return <>{hasRole ? props.protectedChild : props.accessDeniedComponent}</>
}

export function createPermissionProtectedRouteComponent<TState extends {}>() {
    return connectRedux(PermissionProtectedRouteComponent, (state: TState) => state)
}

export type PermissionProtectedRoute = ReturnType<typeof createPermissionProtectedRouteComponent>
