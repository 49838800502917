import { Dialog, DialogContent } from '@mui/material'
import { AndDTO_Output } from '@om1/falcon-api/codegen/client/models/AndDTO_Output'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { RelativeDateFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/RelativeDateFilterDTO_Output'
import { FC } from 'react'
import { isPatientAndGroup } from '../nodes/And'
import { NodeTypes, QueryBlock } from '../QueryBlock'
import { EditDateAwareFilterDialog } from './EditDateAwareFilterDialog'
import { EditFilterDialog } from './EditFilterDialog'
import { EditRelativeDateFilterDialog } from './EditRelativeDateFilterDialog'
import { PatientAttributesContents } from './PatientAttributesContents'

export const EditBlockDialog: FC<{ editBlock: { filter: NodeTypes; index: number | undefined } | null; onClose: () => void }> = ({
    editBlock,
    onClose
}) => {
    const Content = () => {
        switch (editBlock?.filter.type) {
            case 'FilterDTO':
                return <EditFilterDialog editBlock={editBlock.filter as FilterDTO_Output} editQualifierIndex={editBlock.index} />
            case 'DateAwareFilterDTO':
                return <EditDateAwareFilterDialog editBlock={editBlock.filter as DateAwareFilterDTO_Output} editQualifierIndex={editBlock.index} />
            case 'RelativeDateFilterDTO':
                return <EditRelativeDateFilterDialog editBlock={editBlock.filter as RelativeDateFilterDTO_Output} />
            case 'AndDTO':
                if (isPatientAndGroup(editBlock.filter as AndDTO_Output)) {
                    return <PatientAttributesContents editBlock={editBlock.filter as AndDTO_Output} />
                } else {
                    return (
                        <DialogContent
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                justifyContent: 'flex-start',
                                padding: '16px',
                                overflowY: 'auto'
                            }}
                        >
                            {editBlock?.filter && (
                                <QueryBlock block={editBlock?.filter} depth={0} selectedBlock={undefined} setSelectedBlock={() => {}} />
                            )}
                        </DialogContent>
                    )
                }
            default:
                return (
                    <DialogContent
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                            justifyContent: 'flex-start',
                            padding: '16px',
                            overflowY: 'auto'
                        }}
                    >
                        {editBlock?.filter && (
                            <QueryBlock block={editBlock?.filter} depth={0} selectedBlock={undefined} setSelectedBlock={() => {}} />
                        )}
                    </DialogContent>
                )
        }
    }

    return (
        <Dialog open={editBlock !== null} onClose={onClose} maxWidth='lg' scroll='paper'>
            {editBlock && <Content />}
        </Dialog>
    )
}
