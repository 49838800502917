import { DialogContent } from '@mui/material'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FC } from 'react'
import { EditFilterDialogContents } from './EditFilterDialogContents'

export const EditDateAwareFilterDialog: FC<{ editBlock: DateAwareFilterDTO_Output; editQualifierIndex: number | undefined }> = ({
    editBlock,
    editQualifierIndex
}) => {
    return (
        <DialogContent
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                padding: '16px',
                overflowY: 'auto'
            }}
        >
            <EditFilterDialogContents editBlock={editBlock} editQualifierIndex={editQualifierIndex} />
        </DialogContent>
    )
}
