/* eslint-disable string-to-lingui/missing-lingui-transformation */

import { MessageInput } from '@chatscope/chat-ui-kit-react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem, TextField } from '@mui/material'
import { AssistantConversationDetailDTO } from '@om1/falcon-api/codegen/client/models/AssistantConversationDetailDTO'
import { FrameworkComponentProps } from '@om1/platform-utils/connect'
import { Fragment, FunctionComponent, MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { Message } from '../lib/message'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'
import { renderMessage } from './MessageComponents'

export type ActiveConversationComponentProps = FrameworkComponentProps<
    { activeConversation: (Omit<AssistantConversationDetailDTO, 'messages'> & { messages: Message[] }) | undefined; assistantIsResponding: boolean },
    typeof explorerAssistantConversationActions,
    { activeCohortId?: string }
>

export const ActiveConversationComponent: FunctionComponent<ActiveConversationComponentProps> = ({ state, actions, props }) => {
    useEffect(() => {
        if (props.activeCohortId) {
            actions.getConversations({ path: {}, query: { referenceCohortId: props.activeCohortId } })
        }
    }, [actions, props.activeCohortId])

    const activeConversation: (Omit<AssistantConversationDetailDTO, 'messages'> & { messages: Message[] }) | undefined = state.activeConversation
    const messages: Message[] = useMemo(() => activeConversation?.messages || [], [activeConversation?.messages])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
    const [newName, setNewName] = useState('')

    const messageInputRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!state.assistantIsResponding && messageInputRef.current) {
            messageInputRef.current.focus()
        }
    }, [state.assistantIsResponding])

    useEffect(() => {
        const messageContainer = document.querySelector('.message-container')
        if (messageContainer) {
            messageContainer.scrollTop = messageContainer.scrollHeight
        }
    }, [messages])

    const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleDelete = () => {
        if (activeConversation?.id) {
            actions.deleteConversation({ id: activeConversation.id })
        }
        handleMenuClose()
    }

    const handleEdit = () => {
        setNewName(activeConversation?.title || '')
        setIsEditDialogOpen(true)
        handleMenuClose()
    }

    const handleEditSubmit = () => {
        if (activeConversation?.id && newName.trim()) {
            actions.updateConversation({
                id: activeConversation.id,
                requestBody: { title: newName.trim() }
            })
        }
        setIsEditDialogOpen(false)
    }

    const MessageList = useMemo(() => {
        return (
            <div className='message-container'>
                {messages.map((message: Message, index: number) => {
                    return <Fragment key={`message-${message.type}-${index}`}>{renderMessage(message, index)}</Fragment>
                })}
            </div>
        )
    }, [messages])

    return (
        <div
            style={{
                height: '80.4vh',
                display: 'flex',
                flexDirection: 'column',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                width: '100%'
            }}
        >
            {/* Updated Header with integrated menu */}
            {activeConversation && (
                <div
                    style={{
                        padding: '12px 16px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        flexShrink: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h3 style={{ margin: 0 }}>{activeConversation.title}</h3>
                    <div>
                        <IconButton size='small' onClick={handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            <MenuItem onClick={handleEdit}>Edit Name</MenuItem>
                            <MenuItem onClick={handleDelete}>Delete</MenuItem>
                        </Menu>
                    </div>
                </div>
            )}

            {/* Scrollable Messages */}
            <div
                className='message-container'
                style={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {MessageList}
                {state.assistantIsResponding && (
                    <div
                        style={{
                            padding: '12px',
                            color: '#666',
                            fontStyle: 'italic',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}
                    >
                        <div className='typing-dots'></div>
                        Assistant is typing...
                    </div>
                )}
            </div>

            {/* Fixed Input */}
            <MessageInput
                ref={messageInputRef}
                className='custom-message-input'
                placeholder='Type message here'
                onSend={(message: string) => {
                    if (!activeConversation?.id) return

                    // Sanitize the message to remove any HTML tags
                    const sanitizedMessage = message.replace(/<\/?[^>]+(>|$)/g, '')

                    actions.invokeConversation({
                        id: activeConversation?.id,
                        model: 'gpt-4o',
                        requestBody: { messages: [{ content: sanitizedMessage, type: 'human' }] }
                    })
                }}
                disabled={state.assistantIsResponding}
                attachButton={false}
                sendButton={false}
                style={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                    padding: '16px',
                    backgroundColor: 'white',
                    flexShrink: 0 // Prevents input from shrinking
                }}
                autoFocus={!state.assistantIsResponding}
            />

            {activeConversation && (
                <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
                    <DialogTitle>Edit Conversation Name</DialogTitle>
                    <DialogContent>
                        <TextField autoFocus margin='dense' label='Name' fullWidth value={newName} onChange={(e) => setNewName(e.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsEditDialogOpen(false)}>Cancel</Button>
                        <Button onClick={handleEditSubmit}>Save</Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    )
}
