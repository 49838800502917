import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { CohortState, cohortBlocksEditActions, cohortCommonActions, cohortEditActions } from '../../state'
import { ReadOnlyCohortPageComponent } from './ReadOnlyCohortPageComponent'

export function createReadOnlyCohortPageComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        withRouter(ReadOnlyCohortPageComponent, { namespace: 'cohorts', dataFetchAction: 'cohortGet' }),
        (state: TState) => ({
            tree: state.cohort.edit.blocks.tree,
            cohort: state.cohort.edit.base.cohort,
            sizeLoading: state.cohort.common.ui.cohortSizeLoading[state.cohort.edit.base.cohort.id]
        }),
        { ...cohortEditActions, ...cohortBlocksEditActions, ...cohortCommonActions }
    )
}

export type ReadOnlyCohortPage = ReturnType<typeof createReadOnlyCohortPageComponent>
