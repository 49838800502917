/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { FC, useState } from 'react'

export const ObservationPeriodContents: FC<{ editBlock: FilterDTO_Output | DateAwareFilterDTO_Output }> = ({ editBlock }) => {
    const [selectedType, setSelectedType] = useState<string | null>(editBlock.values[0] || null)

    const handleTypeChange = (type: string) => {
        setSelectedType(type)
    }

    const hasChanged = selectedType !== editBlock.values[0]

    return (
        <div style={{ padding: '20px', position: 'relative' }}>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <h2 style={{ fontSize: '1.5em', margin: '0' }}>Select Data Types needed in the Observation Period</h2>
            </div>
            <div style={{ marginBottom: '30px' }}>
                <h3 style={{ fontSize: '1.2em', marginBottom: '10px' }}>Linked Data Types</h3>
                <div>
                    {['EHR Linked Medical and Rx Claims', 'EHR Linked Medical or Rx Claims', 'EHR Linked Medical Claims', 'EHR Linked Rx Claims'].map(
                        (type) => (
                            <label key={type} style={{ display: 'block', marginBottom: '10px', cursor: 'pointer', fontSize: '1em' }}>
                                <input
                                    type='radio'
                                    checked={selectedType === type}
                                    onChange={() => handleTypeChange(type)}
                                    style={{ marginRight: '10px' }}
                                />
                                {type} Data
                            </label>
                        )
                    )}
                </div>
            </div>
            <div style={{ marginBottom: '30px' }}>
                <h3 style={{ fontSize: '1.2em', marginBottom: '10px' }}>Data Types</h3>
                <div>
                    {['EHR', 'Medical Claims', 'Rx Claims'].map((type) => (
                        <label key={type} style={{ display: 'block', marginBottom: '10px', cursor: 'pointer', fontSize: '1em' }}>
                            <input
                                type='radio'
                                checked={selectedType === type}
                                onChange={() => handleTypeChange(type)}
                                style={{ marginRight: '10px' }}
                            />
                            {type} Data
                        </label>
                    ))}
                </div>
            </div>
            <button
                style={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: hasChanged ? '#004085' : '#ccc',
                    color: '#fff',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: hasChanged ? 'pointer' : 'not-allowed',
                    transition: 'background-color 0.3s',
                    fontSize: '1em'
                }}
                disabled={!hasChanged}
            >
                + Save Criteria
            </button>
        </div>
    )
}
