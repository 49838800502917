import { notificationActions } from '@om1/platform-notifications'
import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { BackOfficeState } from '../state'
import { dataDeliveryActions } from '../state/data-delivery'
import { DataDeliveryListComponent } from './DataDeliveryListComponent'

export function createDataDeliveryListComponent<TState extends { backOffice: BackOfficeState }>() {
    return connectRedux(
        withRouter(DataDeliveryListComponent, { namespace: 'dataDelivery', dataFetchAction: 'dataDeliveriesGet' }),
        (state: TState) => ({
            dataDeliveries: state.backOffice.dataDelivery.list.data,
            paginationMeta: state.backOffice.dataDelivery.list.paginationMeta,
            isLoading: state.backOffice.dataDelivery.list.ui.loading
        }),
        { ...dataDeliveryActions, ...notificationActions }
    )
}

export type DataDeliveryList = ReturnType<typeof createDataDeliveryListComponent>
