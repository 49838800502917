import {
    AndDTO_Output,
    DateAwareFilterDTO_Output,
    DateWindowFilterDTO_Output,
    ExceptDTO_Output,
    FilterDTO_Output,
    OrDTO_Output,
    QueryDTO_Output,
    RelativeDateFilterDTO_Output,
    RelativeFollowUpFilterDTO_Output
} from '@om1/falcon-api'
import { FC } from 'react'
import { SelectedBlockProps } from '../../views/ChatView'
import { DateAwareFilter } from './filters/DateAwareFilter'
import { DateWindowFilter } from './filters/DateWindowFilter'
import { Filter } from './filters/Filter'
import { RelativeDateFilter } from './filters/RelativeDateFilter'
import { RelativeFollowUpFilter } from './filters/RelativeFollowUpFilter'
import { And } from './nodes/And'
import { Except } from './nodes/Except'
import { Or } from './nodes/Or'
import { EditQueryBlockProps } from './Query'

export type FilterTypes = DateAwareFilterDTO_Output | FilterDTO_Output | DateWindowFilterDTO_Output
export type NodeTypes = NonNullable<QueryDTO_Output['filters']>[number]

export type BlockClickProps = {
    onBlockClick?: (blockId: number, qualifierIndex?: number) => void
}

export type BlockProps = {
    block: NodeTypes
    depth: number
    isSubject?: boolean
    isReference?: boolean
}

export type QueryBlockProps = BlockProps & Omit<SelectedBlockProps, 'setSelectedBlockId'> & BlockClickProps & EditQueryBlockProps

export const QueryBlock: FC<QueryBlockProps> = (props) => {
    switch (props.block.type) {
        case 'ExceptDTO':
            return <Except {...props} exceptDTO={props.block as ExceptDTO_Output} />
        case 'AndDTO':
            return <And {...props} andDTO={props.block as AndDTO_Output} />
        case 'OrDTO':
            return <Or {...props} orDTO={props.block as OrDTO_Output} />
        case 'FilterDTO':
            return <Filter {...props} filterDTO={props.block as FilterDTO_Output} />
        case 'DateAwareFilterDTO':
            return <DateAwareFilter {...props} dateAwareFilterDTO={props.block as DateAwareFilterDTO_Output} />
        case 'RelativeDateFilterDTO':
            return <RelativeDateFilter {...props} relativeDateFilterDTO={props.block as RelativeDateFilterDTO_Output} />
        case 'DateWindowFilterDTO':
            return <DateWindowFilter {...props} dateWindowFilterDTO={props.block as DateWindowFilterDTO_Output} />
        case 'RelativeFollowUpFilterDTO':
            return <RelativeFollowUpFilter {...props} relativeFollowUpFilterDTO={props.block as RelativeFollowUpFilterDTO_Output} />
        default:
            if ((props.block as any).table) {
                return <Filter {...props} filterDTO={props.block as FilterDTO_Output} />
            } else {
                return (
                    <pre
                        onClick={() => props.onBlockClick?.((props.block as unknown as any).blockId)}
                        style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', border: '1px dotted gray', background: 'white', padding: '1rem' }}
                    >
                        {JSON.stringify(props.block, null, 2)}
                    </pre>
                )
            }
    }
}
