import { Trans } from '@lingui/macro'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Box, Button } from '@mui/material'
import React, { Fragment, ReactNode } from 'react'
import { CohortDraggingState, CohortNode, CriteriaOperation, OperationNode, cohortBlocksEditActions } from '../../../state'
import { CohortCriteriaBlock } from './CohortCriteriaBlock'

export interface CriteriaAreaProps {
    title: ReactNode
    root?: OperationNode
    dragState: CohortDraggingState
    readOnly?: boolean
    actions: typeof cohortBlocksEditActions
    onAdd: () => void
    fixedFirstCohortNode?: CohortNode
    attritionFunnelLabels: string[]
}

/**
 * A zone to render criteria blocks, specifically used to render inclusivity and exclusivity criteria in two separate
 * areas.
 */
export const CriteriaArea: React.FunctionComponent<CriteriaAreaProps> = ({
    title,
    root,
    dragState,
    readOnly,
    actions,
    onAdd,
    attritionFunnelLabels
}) => {
    let addAction: ReactNode
    if (!readOnly) {
        addAction = (
            <Box mt={1}>
                <Button type='button' variant='text' startIcon={<AddCircleIcon />} onClick={onAdd}>
                    <Trans>Add Criteria Block</Trans>
                </Button>
            </Box>
        )
    }

    return (
        <div>
            <h3>{title}</h3>
            {root &&
                root.children.map((node, index) => {
                    const attritionFunnelLabel = attritionFunnelLabels[index]?.toString()

                    return (
                        <Fragment key={node.id}>
                            {index > 0 && (
                                <Box display='flex' flexDirection='column' alignItems='center'>
                                    <Box height={10} width='1px' bgcolor='secondary.light' />
                                    {CriteriaOperation.AND}
                                    <Box height={10} width='1px' bgcolor='secondary.light' />
                                </Box>
                            )}
                            <CohortCriteriaBlock
                                // TODO: Casting could be better here, maybe do a filter?
                                state={{ node: node as unknown as OperationNode, dragState }}
                                actions={actions}
                                props={{ readOnly, attritionFunnelLabel }}
                            />
                        </Fragment>
                    )
                })}
            {addAction}
        </div>
    )
}
