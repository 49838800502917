/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { DateWindowFilterDTO_Output } from '@om1/falcon-api'
import { FC } from 'react'
import { SelectedBlockProps } from '../../../views/ChatView'
import { QueryNode } from '../nodes/QueryNode'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps, QueryBlockProps } from '../QueryBlock'

// Consolidate type definitions for better readability
type OmittedProps = Omit<SelectedBlockProps, 'setSelectedBlockId'> & Omit<QueryBlockProps, 'block'>

export type DateWindowFilterProps = {
    dateWindowFilterDTO: DateWindowFilterDTO_Output
} & OmittedProps &
    BlockClickProps &
    EditQueryBlockProps

export const DateWindowFilter: FC<DateWindowFilterProps> = ({ dateWindowFilterDTO, ...restProps }) => (
    <QueryNode {...restProps} node={dateWindowFilterDTO} title={`DateWindowFilter ${dateWindowFilterDTO.blockId}`}>
        DateWindowFilter [{dateWindowFilterDTO.blockId}]: {JSON.stringify(dateWindowFilterDTO)}
    </QueryNode>
)
